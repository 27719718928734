import { useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
  Text,
  TextElement,
  TextKind,
  Icon,
  Indicator,
  PhosphorIcon,
} from 'design-system/components';
import { Color } from 'design-system/data';
import styles from './menu-item.module.scss';

function MenuItem({
  forceNotActive = false,
  onMouseEnter = () => {},
  onMouseLeave = () => {},
  ...props
}) {
  const location = useLocation();
  const size = props.iconSize ? props.iconSize : 10;

  const isActive = useMemo(() => {
    if (forceNotActive) return false;
    if (props.isActive) return true;
    if (props.href === '/') return location.pathname === '/';
    return (
      location.pathname === props.href ||
      location.pathname.startsWith(`${props.href}/`)
    );
  }, [location.pathname, props.href, props.isActive, forceNotActive]);

  const linkTo = useMemo(
    () => ({
      pathname: props.href,
      search: props.search,
    }),
    [props.href, props.search]
  );

  const childComponent = useMemo(
    () => (
      <>
        {props.iconName &&
          // TODO ENG-4091: Migrate all usage to phosphor icons
          (props.isPhosphorIcon ? (
            <PhosphorIcon
              iconName={props.iconName}
              size={size}
              style={{ minWidth: `${size}px` }}
              color={Color.Neutral500.value}
            />
          ) : (
            <Icon name={props.iconName} size={size} color={Color.Neutral500} />
          ))}
        <Text kind={TextKind.TextSMMedium} element={TextElement.Span}>
          {props.children}
        </Text>
        {props.showIndicator && <Indicator />}
      </>
    ),
    [
      props.iconName,
      props.showIndicator,
      props.children,
      size,
      props.isPhosphorIcon,
    ]
  );

  if (props.href) {
    return (
      <RouterLink
        to={linkTo}
        data-cy={props['data-cy']}
        className={cn([
          styles[isActive ? 'root-active' : 'root'],
          props.compact && styles.compact,
          props.className,
        ])}
        onClick={() => {
          if (props.onClick) {
            props.onClick();
          }
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {childComponent}
      </RouterLink>
    );
  }

  return (
    <button
      data-cy={props['data-cy']}
      className={cn([
        styles[!props.isActive ? 'root' : 'root-active'],
        props.compact && styles.compact,
        props.className,
      ])}
      onClick={() => props.onClick && props.onClick()}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {childComponent}
    </button>
  );
}

MenuItem.propTypes = {
  children: PropTypes.string.isRequired,
  iconName: PropTypes.string,
  iconSize: PropTypes.number,
  href: PropTypes.string,
  search: PropTypes.string,
  showIndicator: PropTypes.bool,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  [`data-cy`]: PropTypes.string,
  isPhosphorIcon: PropTypes.bool,
  compact: PropTypes.bool,
  forceNotActive: PropTypes.bool,
  className: PropTypes.string,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

export default MenuItem;
