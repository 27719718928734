import { useCallback, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router';

import { BrandPolicySubmissionStatus } from 'design-system/data';

import fetcher from 'services/api/fetcher';
import { sentry } from 'utils/sentry';

export type HubSpotConversationsWidget = {
  widget: {
    refresh: () => void;
    remove: () => void;
    load: () => void;
  };
};

declare global {
  interface Window {
    hsConversationsSettings?: {
      identificationEmail: string;
      identificationToken: string;
    };
    HubSpotConversations?: HubSpotConversationsWidget;
  }
}

interface Policy {
  percentComplete: number;
  submissionStatus: string;
}

const HUBSPOT_SURVEY_QUERY_PARAM = 'survey';
const HUBSPOT_SURVEY_PARAM_VALUE = '1';

export const useHubspotSurveyCheck = (policies: Policy[]) => {
  // if at least one policy has status of needs_information
  // and a percentComplete over 0,
  // we can assert it's started attesting
  const hasStartedAttesting = policies?.some(
    (policy) =>
      policy.submissionStatus ===
        BrandPolicySubmissionStatus.NeedsInformation &&
      policy.percentComplete > 0
  );
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    if (hasStartedAttesting) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set(HUBSPOT_SURVEY_QUERY_PARAM, HUBSPOT_SURVEY_PARAM_VALUE);
      history.push({ search: searchParams.toString() });
    }
  }, [hasStartedAttesting, history, location.search]);
};

const useHubSpot = () => {
  const loadVerifiedWidget = useCallback(
    async ({
      email,
      firstName,
      lastName,
    }: {
      email: string;
      firstName: string;
      lastName: string;
    }) => {
      try {
        const res = await fetcher('api/v4/hubspot/verify_user', {
          method: 'POST',
          body: { email, first_name: firstName, last_name: lastName },
        });
        const token = res?.token;

        if (token) {
          window.hsConversationsSettings = {
            identificationEmail: email,
            identificationToken: token,
          };
          window.HubSpotConversations?.widget.load();
        }
      } catch (e) {
        sentry.captureException(e);
      }
    },
    []
  );

  return {
    loadVerifiedWidget,
  };
};

export default useHubSpot;
