import PropTypes from 'prop-types';
import cn from 'classnames';
import { useEffect, useMemo, useRef } from 'react';
import {
  Text,
  TextElement,
  TextKind,
  PhosphorIcon,
  PhosphorIconWeight,
  ToastKind,
  Button,
  ButtonKind,
} from 'design-system/components';
import { handleKeyboardEvent, KeyboardCode } from 'design-system/utils';
import ToastV2 from '../../Toast';
import styles from './index.module.scss';

const CloseButton = ({ setToastOpen }) => {
  const buttonRef = useRef(null);
  useEffect(() => {
    if (buttonRef.current) {
      buttonRef.current?.focus();
    }
  }, []);
  return (
    <Button
      tabIndex={0}
      kind={ButtonKind.Close}
      usePhosphorIcon
      iconWeight={PhosphorIconWeight.Bold}
      iconName="X"
      iconSize={24}
      onlyIcon
      aria-label="Close Toast"
      data-cy="toast-close-button"
      id="toast-close-button"
      onClick={() => setToastOpen(false)}
      onKeyDown={(event) => {
        handleKeyboardEvent(
          event,
          [KeyboardCode.Enter, KeyboardCode.Space],
          () => setToastOpen(false)
        );
      }}
      refProp={buttonRef}
    ></Button>
  );
};

CloseButton.propTypes = {
  setToastOpen: PropTypes.func.isRequired,
};

export default function ToastV2Menu({
  open,
  duration,
  children,
  className = '',
  viewportClassName = '',
  toastData,
  onClose,
}) {
  const toastContent = useMemo(() => {
    if (!toastData) return null;

    const data = Array.isArray(toastData) ? toastData[0] : toastData;
    if (!data) return null;

    const { title, message, kind } = data;
    const isSuccess = kind === ToastKind.Success;

    return (
      <div className={styles['toast-content']}>
        <span
          className={cn([
            styles['icon-container'],
            isSuccess
              ? styles['icon-container-green']
              : styles['icon-container-red'],
          ])}
        >
          <span
            className={cn([
              styles['icon-inner'],
              isSuccess ? styles['icon-inner-green'] : styles['icon-inner-red'],
            ])}
          >
            <PhosphorIcon
              iconName={isSuccess ? 'CheckCircle' : 'WarningCircle'}
              weight={PhosphorIconWeight.Bold}
              size={24}
            />
          </span>
        </span>
        <div>
          <Text kind={TextKind.TextSMSemibold} element={TextElement.H3}>
            {title}
          </Text>
          <Text kind={TextKind.TextSM} element={TextElement.P}>
            {message}
          </Text>
        </div>
        <CloseButton setToastOpen={onClose} />
      </div>
    );
  }, [toastData, onClose]);

  const content = children ? children : toastContent;

  return (
    <ToastV2
      rootProps={{
        open,
        type: 'foreground',
        duration,
        onOpenChange: (isOpen) => {
          if (!isOpen) {
            onClose?.(false);
          }
        },
        className: cn([styles['toast-root'], className]),
      }}
      viewportProps={{
        hotkey: ['F8'],
        label: 'Notifications',
        className: cn([styles['toast-viewport'], viewportClassName]),
      }}
    >
      {content}
    </ToastV2>
  );
}

ToastV2Menu.propTypes = {
  open: PropTypes.bool,
  duration: PropTypes.number,
  children: PropTypes.node,
  className: PropTypes.string,
  viewportClassName: PropTypes.string,
  toastData: PropTypes.oneOfType([
    PropTypes.shape({
      title: PropTypes.string,
      message: PropTypes.string,
      kind: PropTypes.oneOf(Object.values(ToastKind)),
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        message: PropTypes.string,
        kind: PropTypes.oneOf(Object.values(ToastKind)),
      })
    ),
  ]),
  onClose: PropTypes.func,
};
