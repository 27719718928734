import cn from 'classnames';

import {
  Menu,
  MenuItem,
  PhosphorIcon,
  Link,
  LinkKind,
  Avatar,
  Tooltip,
  OpenAxis,
} from 'design-system/components';
import { Color } from 'design-system/data';
import { PrimaryNavItem, PrimaryNavProps } from './types';
import styles from './dual-sidenav.module.scss';
import noviBug from 'design-system/assets/novi-bug.svg';
import links from 'constants/links';
const PrimaryNav = ({
  primaryNavItems,
  userInfo,
  handleNavItemHover,
}: PrimaryNavProps) => {
  const onNavItemHover = (item: PrimaryNavItem | null) => {
    if (item?.disableHover) {
      return;
    }
    handleNavItemHover(item?.name ?? null);
  };

  // Find settings and org items
  const settingsItem = primaryNavItems.find((item) => item.id === 'settings');
  const orgItem = primaryNavItems.find((item) => item.id === 'avatar');

  return (
    <div className={styles['primary-nav']}>
      <div className={styles['primary-nav-content']}>
        <div className={styles['novi-bug-container']}>
          <img src={noviBug} alt="Novi Bug" />
        </div>
        <Menu noBorder>
          {primaryNavItems
            .filter((item) => item.position !== 'bottom')
            .map((item) => (
              <Tooltip
                key={item.id}
                openAxis={OpenAxis.X}
                disabled={!item.disableHover}
                triggerElement={
                  <MenuItem
                    key={item.id}
                    isPhosphorIcon
                    iconSize={24}
                    iconName={item.iconName}
                    href={item?.link}
                    onClick={item.onClick}
                    onMouseEnter={() => {
                      item.onHover?.();
                      onNavItemHover(item);
                    }}
                    onMouseLeave={() => {
                      item.onLeave?.();
                      onNavItemHover(null);
                    }}
                    isActive={item.isActive}
                    className={styles['icon-only']}
                  >
                    {item.name}
                  </MenuItem>
                }
              >
                {item.name}
              </Tooltip>
            ))}
        </Menu>
      </div>
      <div className={styles['primary-nav-bottom']}>
        <div className={styles['bottom-actions']}>
          {/* Support */}
          <Tooltip
            openAxis={OpenAxis.X}
            triggerElement={
              <Link
                kind={LinkKind.External}
                href={links.mailtoSupport}
                className={cn([styles['small-link'], styles['icon-only']])}
              >
                <PhosphorIcon
                  iconName="Question"
                  size={24}
                  color={Color.Neutral500.value}
                />
              </Link>
            }
          >
            Support
          </Tooltip>
          {/* Settings */}
          {settingsItem && (
            <MenuItem
              onClick={settingsItem.onClick}
              onMouseEnter={() => onNavItemHover(settingsItem)}
              onMouseLeave={() => onNavItemHover(null)}
              isActive={settingsItem.isActive}
              className={cn([styles.small, styles['icon-only']])}
            >
              <PhosphorIcon
                iconName={settingsItem.iconName}
                size={24}
                color={Color.Neutral500.value}
              />
            </MenuItem>
          )}
          {/* Organization */}
          {orgItem && (
            <MenuItem
              onMouseEnter={() => onNavItemHover(orgItem)}
              onMouseLeave={() => onNavItemHover(null)}
              className={styles.avatar}
            >
              <Avatar size={40} initials={userInfo.username} />
            </MenuItem>
          )}
        </div>
      </div>
    </div>
  );
};

export default PrimaryNav;
