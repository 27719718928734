import {
  Breadcrumbs,
  Text,
  TextKind,
  TextElement,
  Pill,
  Button,
  ButtonKind,
  IconName,
  ButtonIconPosition,
  IconDirection,
  PageHeader,
} from 'design-system/components';
import { Color } from 'design-system/data';
import { useFDASubmissionDetailContext } from './Context';
import styles from '../Confirmation/confirmation.module.scss';
import {
  SubmissionInformation,
  SubmissionSkus,
} from '../shared/SubmissionInformation';
import { useHistory } from 'react-router-dom';

const SubmissionDetail = () => {
  const { submissionData } = useFDASubmissionDetailContext();
  const history = useHistory();

  return (
    <div>
      <PageHeader
        title={`Submission ${submissionData.id}`}
        pillText={submissionData.submissionStatus}
        breadcrumbs={<Breadcrumbs items={breadcrumbsItems} />}
      />
      <div className={styles['content']}>
        <div className={styles['submission-info']}>
          <SubmissionInformation submissionData={submissionData} />
          <SubmissionSkus length={submissionData.cosmeticProducts?.length}>
            {submissionData.cosmeticProducts?.map((product, index) => {
              return (
                <div className={styles['sku-row']} key={index}>
                  <Text
                    kind={TextKind.TextSMMedium}
                    element={TextElement.P}
                    color={Color.Neutral900}
                  >
                    {product.productName}
                  </Text>
                  <Pill>{product.status}</Pill>
                  <Button
                    kind={ButtonKind.Tertiary}
                    iconName={IconName.ArrowV2}
                    iconPosition={ButtonIconPosition.Right}
                    iconDirection={IconDirection.Right}
                    onClick={() =>
                      history.push(
                        `/fda-registration/product-registration/${product.id}`
                      )
                    }
                  >
                    View SKU
                  </Button>
                </div>
              );
            })}
          </SubmissionSkus>
        </div>
        <div className={styles['payment-info']}>
          <Pill>{submissionData.paymentState}</Pill>
        </div>
      </div>
    </div>
  );
};

export default SubmissionDetail;

const baseLink = `/fda-registration/submissions`;

const breadcrumbsItems = [
  {
    text: 'Submissions',
    link: baseLink,
  },
  {
    text: 'View submission',
  },
];
