import React, { createContext, useContext, ReactNode } from 'react';
import { useApp } from 'context/AppContext';
import { hasChargebeePaywall } from 'utils/hardcodedNonsense';
import { ROLES } from 'constants/index';

interface RetailerBrandPaywallContextType {
  cannotReviewRequirements: boolean;
}

const RetailerBrandPaywallContext = createContext<
  RetailerBrandPaywallContextType | undefined
>(undefined);

export const useRetailerBrandPaywall = (): RetailerBrandPaywallContextType => {
  const context = useContext(RetailerBrandPaywallContext);
  if (!context) {
    throw new Error(
      'useRetailerBrandPaywall must be used within a RetailerBrandPaywallProvider'
    );
  }
  return context;
};

interface RetailerBrandPaywallProviderProps {
  children: ReactNode;
}

export const RetailerBrandPaywallProvider: React.FC<
  RetailerBrandPaywallProviderProps
> = ({ children }) => {
  const { retailerBrand, user } = useApp();

  const hasUltaPaywallFlag = user?.hasFF('tmp_ulta_paywall');
  const computedHasChargebeePaywall = hasChargebeePaywall(
    retailerBrand?.retailer?.id,
    hasUltaPaywallFlag
  );
  const cannotReviewRequirements =
    user?.hasRole(ROLES.brand) &&
    Boolean(retailerBrand?.unlocked) === false &&
    computedHasChargebeePaywall;

  const value = {
    cannotReviewRequirements,
  };

  return (
    <RetailerBrandPaywallContext.Provider value={value}>
      {children}
    </RetailerBrandPaywallContext.Provider>
  );
};
