import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router';
import { useApp } from 'context/AppContext';
import { useChargebeeResync } from 'utils/chargebee';

export const REFETCH_RETAILER_BRAND_PARAM = 'refetch_retailer_brand';

export const useChargebeeParamCheck = () => {
  const { retailerBrand, refetchRetailerBrand, selectedRetailerBrandId } =
    useApp();
  const history = useHistory();
  const location = useLocation();
  const chargebeeResync = useChargebeeResync;
  useEffect(() => {
    if (retailerBrand) {
      const params = new URLSearchParams(location.search);
      const isRefetchParamPresent = params.has(REFETCH_RETAILER_BRAND_PARAM);
      if (isRefetchParamPresent && Boolean(selectedRetailerBrandId)) {
        chargebeeResync(selectedRetailerBrandId)
          .then(() => {
            refetchRetailerBrand(selectedRetailerBrandId);
            params.delete(REFETCH_RETAILER_BRAND_PARAM);
            history?.replace(`${location?.pathname}?${params.toString()}`);
          })
          .catch((error) => {
            console.error('Error during Chargebee resync:', error);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    history,
    location?.pathname,
    retailerBrand,
    refetchRetailerBrand,
    selectedRetailerBrandId,
    chargebeeResync,
  ]);
};
