export const normalizeSkusCount = (
  minCount: number | null,
  maxCount: number | null
) => {
  if (!minCount && !maxCount) {
    return `Unlimited SKUs`;
  }

  if (!minCount) {
    return `Up to ${maxCount} SKUs`;
  }

  if (!maxCount) {
    return `${minCount}+ SKUs`;
  }

  return `${minCount}-${maxCount} SKUs`;
};
