import {
  Breadcrumbs,
  CloudinaryImg,
  Link,
  LinkKind,
  LinkVariant,
  NoContent,
  NoData,
  PageHeader,
  PhosphorIcon,
  Text,
  TextElement,
  TextKind,
} from 'design-system/components';
import { useFetchPrograms } from '../hooks/useFetchPrograms';
import styles from './program-list-container.module.scss';
import { PATHS } from 'constants/index';

const breadcrumbItems = [
  {
    text: 'Programs',
    link: '',
  },
];

const ProgramListContainer = () => {
  const { data: programs, loading } = useFetchPrograms();

  if (loading) return <NoContent loading />;
  if (!programs)
    return (
      <NoData
        hasErrorOccurred
        noContentMessage="Something went wrong. Please try again or contact support."
      />
    );

  return (
    <>
      <PageHeader
        title="Programs"
        breadcrumbs={<Breadcrumbs items={breadcrumbItems} />}
      />
      <div className={styles.container}>
        {programs.map((program) => {
          return (
            <div className={styles['program-card']}>
              <CloudinaryImg
                width={64}
                height={64}
                src={program.imagePath}
                alt="Program icon"
                fallbackElement={
                  <PhosphorIcon iconName="ShieldCheck" size={48} />
                }
              />
              <div className={styles.content}>
                <Text kind={TextKind.Display2XSMedium} element={TextElement.H2}>
                  {program.name}
                </Text>
                {program.description && (
                  <Text kind={TextKind.TextSM} element={TextElement.P}>
                    {program.description}
                  </Text>
                )}
              </div>
              <div className={styles['cta-container']}>
                <Link
                  kind={LinkKind.Internal}
                  variant={LinkVariant.Primary}
                  to={PATHS.retailerBrandProgramDetail.replace(
                    ':program_id',
                    `${program.id}`
                  )}
                >
                  <span className={styles.cta}>
                    View
                    <span className={styles['icon-container']}>
                      <PhosphorIcon iconName="ArrowRight" size={20} />
                    </span>
                  </span>
                </Link>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ProgramListContainer;
