import { array, bool } from 'prop-types';

import {
  Link,
  LinkKind,
  LinkSize,
  LinkVariant,
  NoData,
  PhosphorIcon,
  PhosphorIconWeight,
  Pill,
  Text,
  TextElement,
  TextKind,
} from 'design-system/components';
import { SubmissionStatus } from 'design-system/data';

import { formatDate, formatToShortUTC12hr } from 'services/api/date';
import { PATHS } from 'constants/index';
import RequirementStatusPill from 'components/ScreeningStatusPill';
import { useApp } from 'context/AppContext';
import styles from './brand-submissions.module.scss';

const policyIsSubmitted = (policyId, submittedPolicyIds) =>
  submittedPolicyIds.includes(Number(policyId));

const SubmissionCard = ({
  brand,
  policyIdsToShow,
  showSKUCta,
  retailerPolicyBrandScreeningStatus,
  individualPolicy,
}) => {
  const ListItem = ({ name, submittedAt, screeningResult }) => (
    <li>
      <div>
        <Text kind={TextKind.TextSMMedium} element={TextElement.Span}>
          {name}
        </Text>{' '}
        {submittedAt && (
          <Text
            kind={TextKind.TextSM}
            element={TextElement.Span}
            className={styles['submitted-text']}
          >
            submitted on {formatDate(submittedAt)} at{' '}
            {formatToShortUTC12hr(submittedAt)}
          </Text>
        )}
      </div>
      <RequirementStatusPill status={screeningResult} />
    </li>
  );
  return (
    <div className={styles['brand-card']} key={brand.id}>
      <div>
        <div className={styles.title}>
          <Text kind={TextKind.Display2XSMedium} element={TextElement.H2}>
            {brand.name}
          </Text>
          {brand.productCount && <Pill>{brand.statusCounts.total} SKUs</Pill>}
        </div>
        <ul>
          {individualPolicy && (
            <ListItem
              name={individualPolicy.policyName}
              submittedAt={individualPolicy.submittedAt}
              screeningResult={
                retailerPolicyBrandScreeningStatus?.[
                  brand.retailerConsumerBrandId
                ][individualPolicy.policyId]?.screeningResult
              }
            />
          )}
          {!individualPolicy &&
            policyIdsToShow?.map((policyId) => {
              const policy =
                retailerPolicyBrandScreeningStatus?.[
                  brand.retailerConsumerBrandId
                ][policyId];
              const policySubmission = brand.policySubmissions.find(
                (submission) => Number(submission.policyId) === Number(policyId)
              );

              return (
                <ListItem
                  key={policyId}
                  name={policy.name}
                  submittedAt={policySubmission?.submittedAt}
                  screeningResult={policy.screeningResult}
                />
              );
            })}
        </ul>
      </div>
      <div className={styles['cta-container']}>
        {showSKUCta && individualPolicy && (
          <Link
            variant={LinkVariant.Secondary}
            kind={LinkKind.Internal}
            size={LinkSize.Small}
            to={`${PATHS.retailerBrandSubmissionSKUs
              .replace(':brand_id', brand.id)
              .replace(':policy_id', individualPolicy.policyId)}`}
          >
            Review By SKU
          </Link>
        )}
        <Link
          variant={LinkVariant.Primary}
          kind={LinkKind.Internal}
          size={LinkSize.Small}
          to={`${PATHS.retailerBrandSubmissionPillar.replace(
            ':brand_id',
            brand.id
          )}`}
        >
          Review By Pillar
          {!showSKUCta && (
            <PhosphorIcon
              weight={PhosphorIconWeight.Bold}
              iconName="ArrowRight"
              size={18}
            />
          )}
        </Link>
      </div>
    </div>
  );
};

const SubmissionContent = ({
  loading,
  brands,
  retailerPolicyBrandScreeningStatus,
  activeTab,
  error,
}) => {
  const { user } = useApp();
  const hasRetailerSKUReview = user?.hasFF('tmp_retailer_sku_review');
  if (!brands?.length || loading || error) {
    return (
      <NoData
        isLoading={loading}
        hasErrorOccurred={error}
        errorMessage="Something went wrong while loading your submissions. Please check back later"
        noContentMessage="There are currently no submissions to review!"
      />
    );
  }
  return brands?.map((brand) => {
    // const hasBeenViewed = !!brand.viewedAt;
    const submittedPolicyIds = brand.policySubmissions.map((policy) =>
      Number(policy.policyId)
    );

    let policyIdsToShow = [];
    let showSKUCta = false;
    let cardPerPolicy = false;
    switch (activeTab) {
      case SubmissionStatus.InProgress:
        policyIdsToShow = Object.keys(
          retailerPolicyBrandScreeningStatus?.[brand.retailerConsumerBrandId]
        ).filter(
          (policyId) => !policyIsSubmitted(policyId, submittedPolicyIds)
        );
        break;
      case SubmissionStatus.All:
        policyIdsToShow = Object.keys(
          retailerPolicyBrandScreeningStatus?.[brand.retailerConsumerBrandId]
        );
        break;
      case SubmissionStatus.Pending:
        cardPerPolicy = true;
        if (hasRetailerSKUReview) {
          showSKUCta = true;
        }
        brand.policySubmissions?.forEach((policySubmission) => {
          if (policySubmission.status === SubmissionStatus.Pending) {
            policyIdsToShow.push(policySubmission.policyId);
          }
        });
        break;
      case SubmissionStatus.Approved:
        cardPerPolicy = true;
        if (hasRetailerSKUReview) {
          showSKUCta = true;
        }
        brand.policySubmissions?.forEach((policySubmission) => {
          if (policySubmission.status === SubmissionStatus.Approved) {
            policyIdsToShow.push(policySubmission.policyId);
          }
        });
        break;
      case SubmissionStatus.Rejected:
        cardPerPolicy = true;
        if (hasRetailerSKUReview) {
          showSKUCta = true;
        }
        brand.policySubmissions?.forEach((policySubmission) => {
          if (policySubmission.status === SubmissionStatus.Rejected) {
            policyIdsToShow.push(policySubmission.policyId);
          }
        });
        break;
      default:
        break;
    }
    // All tab return brand card with all policies
    // In Progress tab return brand card with all policies
    // Pending tab return brand policy submission card for each pending policy
    // Approved tab return brand policy submission card for each approved policy
    // Rejected tab return brand policy submission card for each rejected policy

    const cardProps = {
      brand,
      policyIdsToShow,
      showSKUCta,
      retailerPolicyBrandScreeningStatus,
      activeTab,
    };
    if (cardPerPolicy) {
      return (
        <>
          {brand.policySubmissions?.map((policySubmission) => (
            <SubmissionCard
              key={policySubmission.id}
              individualPolicy={policySubmission}
              {...cardProps}
            />
          ))}
        </>
      );
    }
    return <SubmissionCard {...cardProps} key={brand.id} />;
  });
};

SubmissionContent.propTypes = {
  loading: bool,
  brandSubmissions: array,
  error: bool,
};

export default SubmissionContent;
