import * as RUITooltip from '@radix-ui/react-tooltip';
import { node, oneOf, bool } from 'prop-types';
import styles from './tooltip.module.scss';
import { OpenAxis } from './constants';

function Tooltip({
  triggerElement,
  children,
  openAxis = OpenAxis.Y,
  disabled = false,
}) {
  if (disabled) return triggerElement;
  return (
    <RUITooltip.Root>
      <RUITooltip.Trigger className={styles.trigger} tabIndex={0}>
        {triggerElement}
      </RUITooltip.Trigger>
      <RUITooltip.Portal container={document.getElementById('root')}>
        <RUITooltip.Content
          className={styles.content}
          sideOffset={8}
          side={openAxis === OpenAxis.X ? 'right' : 'top'}
        >
          {children}
          <RUITooltip.Arrow className={styles.arrow} />
        </RUITooltip.Content>
      </RUITooltip.Portal>
    </RUITooltip.Root>
  );
}

Tooltip.propTypes = {
  triggerElement: node.isRequired,
  children: node.isRequired,
  openAxis: oneOf(Object.values(OpenAxis)),
  disabled: bool,
};

export default Tooltip;
