import { Button, ButtonKind, ButtonSize } from 'design-system/components';
import styles from '../connected-dual-sidenav.module.scss';
const ProgramsBackButton = ({
  onBackToPrograms,
}: {
  onBackToPrograms: () => void;
}) => {
  return (
    <Button
      kind={ButtonKind.Link}
      size={ButtonSize.Small}
      onClick={onBackToPrograms}
      usePhosphorIcon
      iconName="ArrowLeft"
      className={styles['back-button']}
    >
      Programs
    </Button>
  );
};

export default ProgramsBackButton;
