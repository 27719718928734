import { useEffect, useMemo } from 'react';
import {
  SecondaryNavDataType,
  SecondaryNavSection,
  SecondaryNavUserInfo,
} from 'design-system/components';
import useRetailerSelection from 'views/Brands/PortalSelection/useRetailerSelection';
import { RetailerData } from 'views/Brands/PortalSelectionV2';
import { useEnterPortalHandler } from 'views/Brands/PortalSelectionV2/useEnterPortalHandler';

interface UseSecondaryNavProgramsProps {
  clickWrapper: (params: { action: string }) => void;
  userInfo: SecondaryNavUserInfo;
  inviteModalData: SecondaryNavSection;
  shouldRefreshRetailers: boolean;
  setShouldRefreshRetailers: (value: boolean) => void;
}

export const useSecondaryNavPrograms = ({
  clickWrapper,
  userInfo,
  inviteModalData,
  shouldRefreshRetailers,
  setShouldRefreshRetailers,
}: UseSecondaryNavProgramsProps): SecondaryNavDataType => {
  const { data, loading, refetch: refetchRetailers } = useRetailerSelection();
  const { enterPortalHandler } = useEnterPortalHandler();
  const retailers = useMemo(
    () => (data as RetailerData)?.retailers || [],
    [data]
  );

  useEffect(() => {
    if (shouldRefreshRetailers) {
      refetchRetailers();
      setShouldRefreshRetailers(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefreshRetailers]);

  const enterableRetailers = retailers.filter(
    (retailer) =>
      !!retailer.retailer_consumer_brand_id && !!retailer.is_accessible
  );

  return useMemo(
    () => ({
      main: {
        sections: [
          {
            id: 'enterable-retailers',
            title: 'YOUR PROGRAMS',
            items: enterableRetailers.map((retailer) => ({
              id: retailer.id,
              content: retailer.name,
              onClick: () => {
                enterPortalHandler(retailer);
                clickWrapper({
                  action: `click_retailer_portal_${retailer.name}`,
                });
              },
            })),
            loading,
          },
        ],
      },
      footer: {
        sections: [inviteModalData],
        userInfo,
      },
    }),
    [
      enterableRetailers,
      userInfo,
      loading,
      enterPortalHandler,
      clickWrapper,
      inviteModalData,
    ]
  );
};
