import {
  Button,
  ButtonKind,
  CloudinaryImg,
  Link,
  LinkKind,
  LinkSize,
  LinkVariant,
  PhosphorIcon,
  Text,
  TextElement,
  TextKind,
  UserList,
} from 'design-system/components';
import { Color } from 'design-system/data';
import { convertSnakeToCamelCase } from 'design-system/utils';
import { useApp } from 'context/AppContext';
import links from 'constants/links';
import styles from './portal-selection.module.scss';
import { Retailer } from './index';

interface PortalCardProps {
  retailer: Retailer;
  isEnterable: boolean;
  onEnter: (retailer: Retailer) => void;
}

const PortalCard = ({ retailer, isEnterable, onEnter }: PortalCardProps) => {
  const { user } = useApp();
  const hasBadgesFF = user?.hasFF('tmp_brand_187');
  const noPrograms = retailer.programs?.length === 0;
  const showRetailerName = noPrograms || !hasBadgesFF;
  return (
    <div className={styles['portal-card']}>
      <div className={styles.logo}>
        <CloudinaryImg
          src={retailer.image_path || ''}
          alt={retailer.name}
          fallbackElement={<></>}
        />
      </div>
      <div className={styles['programs-container']}>
        {showRetailerName && (
          <div>
            <Text
              kind={TextKind.TextMDMedium}
              element={TextElement.P}
              color={Color.Neutral700}
            >
              {retailer.name}
            </Text>
          </div>
        )}
        {hasBadgesFF
          ? retailer.programs?.map((program) => {
              return (
                <div key={program.name}>
                  <Text
                    kind={TextKind.TextMDMedium}
                    element={TextElement.P}
                    color={Color.Neutral700}
                  >
                    {program.name}
                  </Text>
                  {program.program_badges.length > 0 && (
                    <div className={styles['badges-container']}>
                      <Text
                        kind={TextKind.TextMD}
                        element={TextElement.P}
                        color={Color.Neutral500}
                      >
                        Available badge
                        {program.program_badges.length > 1 && 's'}
                      </Text>
                      <UserList
                        title="Badges"
                        users={convertSnakeToCamelCase(program.program_badges)}
                      />
                      <div className={styles['badges-wrapper']}></div>
                    </div>
                  )}
                </div>
              );
            })
          : null}
      </div>
      <hr />
      <div className={styles.cta}>
        {isEnterable ? (
          <Button
            key={retailer.id}
            kind={ButtonKind.Primary}
            onClick={() => onEnter(retailer)}
            usePhosphorIcon
            iconName="ArrowRight"
            iconSize={20}
            iconPosition="right"
            disabled={!retailer?.retailer_consumer_brand_id}
          >
            Enter
          </Button>
        ) : (
          <Link
            key={retailer.id}
            variant={LinkVariant.Secondary}
            kind={LinkKind.External}
            size={LinkSize.Medium}
            usePhosphorIcon
            iconName="ArrowRight"
            iconSize={20}
            iconPosition="right"
            href={`${links.mailtoSupport}?subject=Request to join ${
              retailer.name ? `${retailer.name} ` : ''
            }retailer program!`}
          >
            <div>
              Learn more
              <span className={styles['icon-container']}>
                <PhosphorIcon iconName="ArrowSquareOut" size={20} />
              </span>
            </div>
          </Link>
        )}
      </div>
    </div>
  );
};

export default PortalCard;
