import React, { createContext, useContext, useEffect, useState } from 'react';

interface DualNavContextType {
  hideNav: boolean;
  setHideNav: (value: boolean) => void;
  hideMobileNav: boolean;
  setHideMobileNav: (value: boolean) => void;
}

const DualNavContext = createContext<DualNavContextType | null>(null);

export const useDualNavContext = () => {
  const context = useContext(DualNavContext);
  if (!context) {
    throw new Error('Must be used with a DualNavProvider');
  }
  return context;
};

export const DualNavProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [hideNav, setHideNav] = useState(false);
  const [hideMobileNav, setHideMobileNav] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      const isMobileView = window.innerWidth < 744; // Match your tablet breakpoint
      if (!isMobileView) {
        setHideMobileNav(true); // Hide mobile nav when viewport becomes desktop
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const value = {
    hideNav,
    setHideNav,
    hideMobileNav,
    setHideMobileNav,
  };

  return (
    <DualNavContext.Provider value={value}>{children}</DualNavContext.Provider>
  );
};
