import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { PATHS } from 'constants/index';

interface UseChargebeeDialogTriggerProps {
  /**
   * The current retailer brand ID
   */
  retailerBrandId?: number;
  /**
   * Callback to handle opening the Chargebee checkout dialog
   */
  onOpenDialog: () => void;
}

/**
 * Hook to trigger the Chargebee dialog when redirected from a Chargebee checkout
 * and clean up URL parameters
 */
const useChargebeeDialogTrigger = ({
  retailerBrandId,
  onOpenDialog,
}: UseChargebeeDialogTriggerProps): void => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (!retailerBrandId) return;

    const searchParams = new URLSearchParams(location.search);
    const chargebeeCheckoutRetailerBrandId = searchParams.get(
      'chargebee_checkout_retailer_brand_id'
    );

    if (chargebeeCheckoutRetailerBrandId === retailerBrandId.toString()) {
      // Trigger the dialog
      onOpenDialog();

      // Clean up URL by removing the retailer brand ID parameter
      searchParams.delete('chargebee_checkout_retailer_brand_id');

      // Update the URL without adding to browser history
      history.replace({
        pathname: PATHS.retailerBrandDash,
        search: searchParams.toString(),
      });
    }
  }, [retailerBrandId, location.search, history, onOpenDialog]);
};

export default useChargebeeDialogTrigger;
