import { CSSProperties, useState, useRef, useEffect, useMemo } from 'react';
import {
  Avatar,
  AvatarColor,
  Modal,
  ModalType,
  Text,
  TextKind,
  TextElement,
} from 'design-system/components';
import styles from './user-list.module.scss';

interface UserListProps {
  users: any[];
  title: string;
  containerWidth?: number; // Optional: pass in container width if known
}

const UserList = ({
  users = [],
  title = 'Users',
  containerWidth,
}: UserListProps) => {
  const [showModal, setShowModal] = useState(false);
  const [visibleCount, setVisibleCount] = useState(users.length);
  const listRef = useRef<HTMLDivElement>(null);
  const avatarWidth = 42; // size(42)
  const avatarOffset = 9.12; // size(9.12) from margin-left negative

  const colorCount = Object.keys(AvatarColor).length;
  // cycle through available colors
  const getColor = (i: number) => Object.values(AvatarColor)[i % colorCount];

  useEffect(() => {
    const effectiveAvatarWidth = avatarWidth - avatarOffset;
    const calculateVisibleCount = () => {
      if (!listRef.current) return;

      const listWidth =
        containerWidth || listRef.current.parentElement?.offsetWidth || 0;
      if (listWidth === 0) return;

      // Calculate how many avatars can fit
      const maxVisible = Math.floor(
        (listWidth - avatarWidth) / effectiveAvatarWidth
      );
      setVisibleCount(maxVisible);
    };

    calculateVisibleCount();
    window.addEventListener('resize', calculateVisibleCount);
    return () => window.removeEventListener('resize', calculateVisibleCount);
  }, [users.length, containerWidth]);

  const remainingCount = users.length - visibleCount;
  const showOverflow = remainingCount > 0;

  // If we can show all badges plus overflow, just show all badges
  const visibleUsers = showOverflow ? users.slice(0, visibleCount - 1) : users;

  const computedVisibleOverflowCount = useMemo(() => {
    const visibleCount = remainingCount + 1;
    // single digits: +2, +3, etc.
    // double digits: 10, 11, etc.
    // triple digits and above: ++
    if (visibleCount < 10) {
      return `+${visibleCount}`;
    } else if (visibleCount < 100) {
      return `${visibleCount}`;
    }
    return '++';
  }, [remainingCount]);

  return (
    <>
      <div
        className={styles['user-list']}
        onClick={() => setShowModal(true)}
        ref={listRef}
        data-testid="user-list"
      >
        {visibleUsers.map((user, i) => (
          <div
            key={`${user?.name ? user.name : user || ''}-${i}`}
            className={styles['avatar-wrapper']}
            style={{ '--z-index': users.length + i } as CSSProperties}
            tabIndex={0}
          >
            <Avatar
              initials={user?.name ? user.name : user || ''}
              color={getColor(i)}
              src={user?.imagePath ? user.imagePath : ''}
            />
          </div>
        ))}
        {showOverflow && (
          <div
            className={`${styles['avatar-wrapper']} ${styles['overflow-avatar']}`}
            style={{ '--z-index': users.length } as CSSProperties}
            tabIndex={0}
            data-testid="overflow-count"
          >
            <Avatar
              initials={computedVisibleOverflowCount}
              color={AvatarColor.Default}
            />
          </div>
        )}
      </div>
      <Modal
        title={title}
        type={ModalType.Large}
        show={showModal}
        onClick={() => setShowModal(false)}
      >
        <div
          className={styles['modal-user-list']}
          onClick={() => setShowModal(true)}
        >
          {users.map((user, i) => (
            <div
              key={`${user?.name ? user.name : user || ''}-${i}`}
              className={styles['modal-user-wrapper']}
              style={{ zIndex: users.length - i }}
            >
              <Avatar
                initials={user?.name ? user.name : user || ''}
                color={getColor(i)}
                src={user?.imagePath ? user.imagePath : ''}
              />
              <Text kind={TextKind.TextSM} element={TextElement.P}>
                {user?.name ? user.name : user || ''}
              </Text>
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
};

export default UserList;
