import { useCallback, useRef, useState } from 'react';
import noop from 'utils/noop';
import handleErrors from './errors/handleErrors';
import RequestRetry from './errors/RequestRetry';
import useErrorHandlers from './errors/useErrorHandlers';

// @ts-ignore
export default function useAsyncCall(handler, options = {}) {
  handler = handler || noop;
  const { afterDone = noop, errorHandlers } = options;
  const [loading, setLoading] = useState(false);
  const { defaultErrorHandlers } = useErrorHandlers();
  const errorHandlersRef = useRef(errorHandlers || defaultErrorHandlers);
  const retries = useRef(0);

  const call = useCallback(
    async (...args) => {
      let result;

      try {
        setLoading(true);
        result = await handler(...args);
      } catch (e) {
        e.retryTimes = retries.current;

        const errResponse = await handleErrors(errorHandlersRef.current, e);

        if (errResponse instanceof RequestRetry) {
          retries.current = errResponse.retryTimes;
          return await call(...args);
        }
      }
      setLoading(false);
      afterDone(result);
    },
    [handler, afterDone, errorHandlersRef, retries]
  );

  return { call, loading };
}
