import PropTypes from 'prop-types';
import {
  Button,
  ButtonIconPosition,
  ButtonKind,
  PhosphorIconWeight,
  Text,
  TextElement,
  TextKind,
} from 'design-system/components';
import { Color, ELLIPSIS } from 'design-system/data';
import { determinePagesToDisplay, shouldPageBeDisplayed } from './utils';
import styles from './pagination-v2.module.scss';

const PaginationV2 = ({
  itemsPerPage,
  totalItemCount = 0,
  currentPage = 1,
  onNextPage,
  onPreviousPage,
  onPageSelect,
  showItemCount = false,
}) => {
  const numberOfPages = Math.ceil(totalItemCount / itemsPerPage);
  const showEllipsis = numberOfPages > 6;
  const displayedPages = [];
  const commonArrowButtonProps = {
    usePhosphorIcon: true,
    kind: ButtonKind.Tertiary,
    iconWeight: PhosphorIconWeight.Bold,
    iconColor: Color.Neutral700,
  };

  const startItem = (currentPage - 1) * itemsPerPage + 1;
  const endItem = Math.min(currentPage * itemsPerPage, totalItemCount);

  const renderPaginationButtons = () => (
    <div className={styles.pages}>
      {determinePagesToDisplay(currentPage, numberOfPages).map(
        (pageNumber, i) => {
          if (pageNumber === ELLIPSIS) {
            return showEllipsis ? (
              <li className={styles.ellipsis} key={i}>
                <Text
                  aria-hidden
                  kind={TextKind.TextSMMedium}
                  element={TextElement.Span}
                >
                  {ELLIPSIS}
                </Text>
                <span className="sr-only">Ellipsis</span>
              </li>
            ) : null;
          }
          return shouldPageBeDisplayed(
            pageNumber,
            numberOfPages,
            displayedPages
          ) ? (
            <li key={i}>
              <Button
                disabled={pageNumber === currentPage}
                aria-current={pageNumber === currentPage ? 'page' : null}
                kind={ButtonKind.Tertiary}
                onClick={() => onPageSelect(pageNumber)}
              >
                <span className="sr-only">Page</span> {pageNumber}
                {pageNumber === 1 && (
                  <span className="sr-only"> (first page)</span>
                )}
                {pageNumber === numberOfPages && (
                  <span className="sr-only"> (last page)</span>
                )}
              </Button>
            </li>
          ) : null;
        }
      )}
    </div>
  );

  const NextPageButton = () => (
    <Button
      {...commonArrowButtonProps}
      disabled={currentPage === numberOfPages}
      iconName="ArrowRight"
      iconPosition={ButtonIconPosition.Right}
      onClick={() => onNextPage()}
    >
      Next <span className="sr-only">page</span>
    </Button>
  );

  return (
    <nav aria-label="pagination">
      <ul className={styles.container}>
        <li>
          <Button
            {...commonArrowButtonProps}
            disabled={currentPage === 1}
            iconName="ArrowLeft"
            onClick={() => onPreviousPage()}
          >
            Previous <span className="sr-only">page</span>
          </Button>
        </li>

        {showItemCount ? (
          <li className={styles['show-item-count-container']}>
            <Text
              kind={TextKind.TextSMMedium}
              element={TextElement.Span}
              color={Color.Neutral700}
            >
              Showing {startItem} to {endItem} of {totalItemCount} items
            </Text>
            {renderPaginationButtons()}
            <NextPageButton />
          </li>
        ) : (
          <>
            {renderPaginationButtons()}
            <li>
              <NextPageButton />
            </li>
          </>
        )}
      </ul>
    </nav>
  );
};

PaginationV2.propTypes = {
  totalItemCount: PropTypes.number,
  currentPage: PropTypes.number,
  onNextPage: PropTypes.func,
  onPreviousPage: PropTypes.func,
  onPageSelect: PropTypes.func,
  itemsPerPage: PropTypes.number,
  showItemCount: PropTypes.bool,
};

export default PaginationV2;
