import {
  Text,
  TextElement,
  TextKind,
  Pill,
  Link,
  LinkKind,
  LinkVariant,
  OpenAxis,
  Tooltip,
} from 'design-system/components';

import styles from '../connected-dual-sidenav.module.scss';

export interface SubscriptionSectionProps {
  statusData: {
    Color: string;
    Text: string;
  };
  description: string;
  unpaidSubscriptions: boolean;
  handleCheckout: {
    call: () => void;
    loading: boolean;
  };
  invoiceLink: string;
  displayRequestToPay: boolean;
  supportEmail: string;
}

const SubscriptionSection = ({
  statusData,
  description,
  unpaidSubscriptions,
  handleCheckout,
  invoiceLink,
  displayRequestToPay,
  supportEmail,
}: SubscriptionSectionProps) => {
  return (
    <div className={styles['subscription-section']}>
      <Text kind={TextKind.TextSMSemibold} element={TextElement.Span}>
        Subscription Status
      </Text>
      <Pill color={statusData.Color}>{statusData.Text}</Pill>
      {description && (
        <Text kind={TextKind.TextSM} element={TextElement.Span}>
          {description}
        </Text>
      )}
      {unpaidSubscriptions && (
        <div>
          {displayRequestToPay && (
            <Text
              className={styles['request-to-pay']}
              kind={TextKind.TextSM}
              element={TextElement.P}
            >
              Your invoice is due. Please{' '}
              {invoiceLink ? 'complete payment below' : 'pay'} to avoid losing
              any account capabilities. If you need support, please email{' '}
              <Link kind={LinkKind.External} href={`mailto:${supportEmail}`}>
                {supportEmail}
              </Link>
            </Text>
          )}
          {handleCheckout && (
            <Link
              kind={LinkKind.Internal}
              to="#"
              variant={LinkVariant.NewDefault}
              onClick={() => handleCheckout.call()}
              loading={handleCheckout.loading}
            >
              Complete Payment
            </Link>
          )}
          {/* TODO: Remove when fully migrated to chargebee (Ulta portal exception). */}
          {!handleCheckout &&
            (invoiceLink ? (
              <Link
                kind={LinkKind.External}
                href={invoiceLink}
                target="_blank"
                variant={LinkVariant.NewDefault}
              >
                Complete Payment
              </Link>
            ) : (
              <Tooltip
                openAxis={OpenAxis.Y}
                triggerElement={
                  <Link
                    kind={LinkKind.External}
                    href={`mailto:${supportEmail}`}
                    target="_blank"
                    variant={LinkVariant.NewDefault}
                  >
                    Complete Payment
                  </Link>
                }
              >
                <Text kind={TextKind.TextSM} element={TextElement.Span}>
                  Please contact support to access your invoice
                </Text>
              </Tooltip>
            ))}
        </div>
      )}
    </div>
  );
};

export default SubscriptionSection;
