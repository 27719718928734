import React, { useCallback } from 'react';
import {
  Button,
  ButtonKind,
  LinkKind,
  OpenAxis,
  Pill,
  PillColor,
  Progress,
  ProgressSize,
  Text,
  TextElement,
  TextKind,
  Tooltip,
} from 'design-system/components';
import {
  BrandColors,
  BrandPolicySubmissionStatus,
  Color,
} from 'design-system/data';
import { useHistory } from 'react-router-dom';

import styles from '../retailerBrandDashboard.module.scss';
import NextStepsCTA from '../NextStepsCTA/NextStepsCTA';
import {
  hydrateSKUsFlow,
  hydratePolicySubmissionFlow,
  SKUPolicyStateType,
} from './constants';
import { useApp } from 'context/AppContext';
import useApi from 'services/api/useApi';
import { TableDataResponseRaw } from 'views/Brands/RetailerBrandEligibility/SKULevelStatusPage/data/types';

interface SubmissionCardProps {
  retailerName: string;
  policyName: string;
  policyId: string;
  policyCustomDescriptions?: Record<string, string>;
  submissionFlow: string;
  submissionStatus: string;
  loading: boolean;
  percentComplete: number;
  canSubmit: boolean;
  setModalOpen: (value: boolean) => void;
  someSkusQualify: boolean;
  noSkuData: boolean;
  handleDeleteSubmission: () => void;
}

const SubmissionCard = ({
  retailerName,
  policyName,
  policyId,
  submissionFlow,
  submissionStatus,
  loading,
  percentComplete,
  canSubmit,
  setModalOpen,
  someSkusQualify,
  noSkuData,
  handleDeleteSubmission,
  policyCustomDescriptions = {},
}: SubmissionCardProps) => {
  const history = useHistory();
  const { retailerBrand, user } = useApp();
  const brandId = user?.organization?.in_orgable_id;
  const retailerId = retailerBrand?.retailer?.id;
  const imageFallbackCallback = useCallback(
    () => <span className={styles.fallback} />,
    []
  );
  const isCompleteSubmissionFlow =
    !submissionFlow || submissionFlow === 'complete';

  const pendingSKUsResponse = useApi(
    `/api/v4/retailers/${retailerId}/consumer_brands/${brandId}/policies/${policyId}/sku_eligibility_results`,
    {
      fetcherOptions: {
        method: 'POST',
        body: {
          program_status: ['pending'],
        },
      },
    }
  );

  const {
    loading: pendingSKUsIsLoading,
    error: pendingSKUsError,
    data,
  } = pendingSKUsResponse;
  const pendingSKUsData = data as TableDataResponseRaw;
  const submitButtonBase = (
    <Button
      kind={ButtonKind.Primary}
      onClick={() => setModalOpen(true)}
      className={styles.button}
      disabled={!canSubmit}
      {...(isCompleteSubmissionFlow && {
        usePhosphorIcon: true,
        iconSize: 20,
        iconPosition: 'right',
        iconName: 'ArrowRight',
      })}
    >
      Submit
    </Button>
  );

  const submitButton = canSubmit ? (
    submitButtonBase
  ) : (
    <Tooltip openAxis={OpenAxis.Y} triggerElement={submitButtonBase}>
      <Text kind={TextKind.TextSM} element={TextElement.P}>
        In order to submit your attestations, please see the Complete Payment
        prompt above.
      </Text>
    </Tooltip>
  );

  const unlockButton = (
    <Button
      kind={ButtonKind.Primary}
      onClick={handleDeleteSubmission}
      className={styles.button}
      usePhosphorIcon
      iconSize={20}
      iconPosition="right"
      iconName="ArrowRight"
    >
      Attest
    </Button>
  );

  const viewDetailsButton = (
    <Button
      kind={ButtonKind.Tertiary}
      onClick={() =>
        history.push(
          `/retailer-brand/eligibility/${policyId}/sku_level_status?program_status=pending`
        )
      }
      className={styles.button}
    >
      View Details
    </Button>
  );

  let footerElement: React.JSX.Element | null = null;
  if (!pendingSKUsIsLoading && !pendingSKUsError) {
    footerElement = (
      <div className={styles['footer-stat']}>
        <Text
          kind={TextKind.TextSMSemibold}
          element={TextElement.Span}
          color={BrandColors.Gray500}
        >
          Pending SKUs
        </Text>

        <Pill color={PillColor.BrandSecondary}>
          {pendingSKUsData.results.length}
        </Pill>
      </div>
    );
  }

  const OneOrMoreSkusCard = ({
    policyCustomDescriptions,
  }: {
    policyCustomDescriptions?: Record<string, string>;
  }) => {
    const policyStates = hydrateSKUsFlow(
      submissionStatus,
      someSkusQualify,
      policyName,
      retailerName,
      submitButton,
      unlockButton,
      noSkuData,
      viewDetailsButton,
      footerElement,
      policyCustomDescriptions || {}
    ) as SKUPolicyStateType[];
    return policyStates.map((policyState, i) => (
      <NextStepsCTA
        key={`${policyState.title}-${i}`}
        cardHeading={policyState.title}
        phosphorIconName={policyState.phosphorIconName || ''}
        descriptiveText={policyState.subtitle || ''}
        ctaButtonText={policyState.link?.text}
        redirectPath={policyState.link?.to}
        linkKind={policyState.link?.linkKind || LinkKind.Internal}
        buttonAction={policyState.actionButton}
        secondaryAction={policyState.secondaryActionButton}
        footerElement={policyState.footerElement}
      />
    ));
  };

  const CompletePolicyCard = () => {
    const policyState = hydratePolicySubmissionFlow(
      submissionStatus,
      policyName,
      retailerName,
      percentComplete,
      policyCustomDescriptions.completeDescription,
      retailerBrand?.retailer?.support_url,
      submitButton,
      noSkuData
    );
    if (!policyState) {
      return null;
    }
    let progressBarColor = Color.Purple600.value;
    if (submissionStatus === BrandPolicySubmissionStatus.Fail) {
      progressBarColor = Color.Red600.value;
    } else if (percentComplete === 100) {
      progressBarColor = Color.Green600.value;
    }
    return (
      <NextStepsCTA
        cardHeading={policyState.title}
        phosphorIconName={policyState.phosphorIconName || ''}
        descriptiveText={policyState.description}
        ctaButtonText={policyState.link?.text}
        redirectPath={policyState.link?.to}
        linkKind={policyState.link?.linkKind || LinkKind.Internal}
        buttonAction={policyState.actionButton}
        assetElement={
          policyState.svg ? (
            <img
              src={policyState.svg}
              alt={policyState.alt}
              onError={imageFallbackCallback}
              className={styles['image-asset']}
            />
          ) : (
            <Progress
              percentage={percentComplete || 0}
              loading={loading}
              size={ProgressSize.Sm}
              color={progressBarColor}
            />
          )
        }
      />
    );
  };

  // Only evaluate submission flow in one place.
  if (isCompleteSubmissionFlow) {
    return <CompletePolicyCard />;
  }
  if (submissionFlow === 'one_or_more_skus') {
    return (
      <OneOrMoreSkusCard policyCustomDescriptions={policyCustomDescriptions} />
    );
  }
  return null;
};

export default SubmissionCard;
