import cn from 'classnames';
import styles from './no-content.module.scss';
import propTypes from 'prop-types';
import {
  Icon,
  IconName,
  LoadingSpinner,
  Text,
  TextElement,
  TextKind,
} from 'design-system/components';
import { NoDataVariants } from './constants';

function NoContent(props) {
  return (
    <div
      className={cn([styles.container, props.noBorder && styles['no-border']])}
    >
      <div className={styles['container-inner']}>
        {props.loading ? (
          <div className={styles.loader} aria-live="polite" aria-busy="true">
            <LoadingSpinner darkMode />
            <Text kind={TextKind.TextMD} element={TextElement.P}>
              Loading
            </Text>
            {props.children && (
              <Text kind={TextKind.TextMD} element={TextElement.P}>
                {props.children}
              </Text>
            )}
          </div>
        ) : (
          <>
            <Icon
              name={
                props.variant === NoDataVariants.Success
                  ? IconName.Success
                  : IconName.NoContent
              }
              size={48}
            />
            <div className={styles.message}>{props.children}</div>
          </>
        )}
      </div>
    </div>
  );
}

NoContent.propTypes = {
  children: propTypes.node,
  loading: propTypes.bool,
  variant: propTypes.oneOf(Object.values(NoDataVariants)),
  noBorder: propTypes.bool,
};

export default NoContent;
