import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0 from 'classes/Auth0';
import * as Sentry from '@sentry/browser';

import { jwtDecode } from 'jwt-decode';

export function isTokenExpired(token) {
  if (!token) return true;

  try {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000;

    // Add a 30 second buffer to handle network latency
    return decoded.exp < currentTime + 30;
  } catch (error) {
    // If we can't decode the token, consider it expired
    return true;
  }
}

export async function ensureFreshToken(auth0) {
  if (!auth0 || !auth0.token) return false;
  if (!isTokenExpired(auth0.token)) return true;

  // Remove the token from the local storage to force a refresh
  await auth0.client.logout({ localOnly: true });
  await auth0.refreshToken();

  // If still not authenticated after refresh, redirect to login
  if (!auth0.isAuthenticated) {
    auth0.redirectToLogin();
    return false;
  }

  return true;
}

export async function initializeAuth0() {
  let client;
  const auth0 = new Auth0();

  try {
    client = await createAuth0Client({
      domain: process.env.REACT_APP_AUTH0_DOMAIN,
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      redirect_uri: `${window.location.origin}/`,
      useRefreshTokens: true,
      cacheLocation: 'localstorage',
    });

    auth0.client = client;
    await auth0.refreshToken();
  } catch (e) {
    // this may cause alert noise from expired sessions, need to eventually
    // filter those out.
    if (!/login required/i.test(e.message)) {
      Sentry.captureException(e);
    }
    auth0.isAuthenticated = false;
  }

  auth0.consumeLoginState();

  return auth0;
}
