import { useEffect, useCallback } from 'react';
import { Modal, ModalType } from 'design-system/components';

import RequirementActionModalContentsContainer from '../../shared/RequirementActionModalContents';
import SlideoutFooter from '../../shared/RequirementActionModalContents/SlideoutFooter';
import { useRequirementActions } from '../../shared/RequirementActionModalContents/context';

const SlideoutModal = ({
  modalNavPrevLogic,
  modalNavNextLogic,
  previousActive,
  nextActive,
  modalOpen,
  setModalOpen,
  currentRow,
  setCurrentRow,
  currentFilteredData,
  totalItemCount = 0,
  expectedRequirementType,
  hideSubmit = false,
  hideSubmitNote,
  isRetailer,
}) => {
  const {
    setToastOpen,
    conditions,
    conditionsLoading,
    conditionsError,
    handleChange,
    triggerValidation,
    handleConditionValidation,
    handleSubmit,
    selectedRequirement,
    actionSubmitLoading,
    canAttest,
    confirmClose,
    setSelectedRequirement,
    submitRetailerResponse,
  } = useRequirementActions();

  const modalNavLogic = useCallback(
    (i) => {
      modalNavNextLogic(i);
      modalNavPrevLogic(i);
    },
    [modalNavNextLogic, modalNavPrevLogic]
  );

  useEffect(() => {
    modalNavLogic(currentRow - 1);
  }, [currentRow, modalNavLogic]);

  const onPrev = () => {
    const isPrevRowWrongType =
      expectedRequirementType &&
      currentFilteredData[currentRow - 2]?.requirementType !==
        expectedRequirementType;
    if (isPrevRowWrongType) {
      setModalOpen(false);
      setToastOpen(false);
      return;
    }
    setModalOpen(true);
    setSelectedRequirement(currentFilteredData[currentRow - 2]);
    setCurrentRow(currentRow - 1);
    modalNavLogic(currentRow - 2);
  };

  const onNext = () => {
    const isNextRowWrongType =
      expectedRequirementType &&
      currentFilteredData[currentRow]?.requirementType !==
        expectedRequirementType;
    if (isNextRowWrongType) {
      setModalOpen(false);
      setToastOpen(false);
      return;
    }
    setModalOpen(true);
    setToastOpen(false);
    setSelectedRequirement(currentFilteredData[currentRow]);
    setSelectedRequirement(currentFilteredData[currentRow]);
    setCurrentRow(currentRow + 1);
    modalNavLogic(currentRow);
  };

  return (
    <Modal
      show={modalOpen}
      title="Attest to conditions"
      type={ModalType.SlideOut}
      onClick={() => {
        setModalOpen(false);
        setToastOpen(false);
      }}
      dataCyRoot="attestation-modal"
      confirmClose={confirmClose && canAttest}
      confirmCloseLabel="You have unsaved changes"
      confirmCloseDescription="Are you sure you want to exit? You will lose all unsaved changes if you do."
      confirmCloseIcon="FloppyDisk"
      showActionButtons
      footerSlot={
        <SlideoutFooter
          onPrev={onPrev}
          onNext={onNext}
          prevActive={previousActive}
          nextActive={nextActive}
          disabled={(confirmClose && canAttest) || actionSubmitLoading}
          totalItemCount={totalItemCount}
          currentRequirementNum={currentRow}
        />
      }
    >
      {modalOpen && (
        <RequirementActionModalContentsContainer
          conditions={conditions || []}
          loading={conditionsLoading}
          error={conditionsError}
          onChange={handleChange}
          triggerValidation={triggerValidation}
          handleConditionValidation={handleConditionValidation}
          canAttest={canAttest && !hideSubmit}
          doneCtaLabel={actionSubmitLoading ? 'Saving...' : 'Attest'}
          onDone={handleSubmit}
          disableDone={
            hideSubmit ||
            conditionsLoading ||
            !conditions?.length ||
            actionSubmitLoading ||
            !canAttest
          }
          hideDone={hideSubmit}
          hideDoneNote={hideSubmitNote}
          screeningResult={selectedRequirement}
          isRetailer={isRetailer}
          submitRetailerResponse={submitRetailerResponse}
        />
      )}
    </Modal>
  );
};

export default SlideoutModal;
