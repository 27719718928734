import { useMemo } from 'react';
import { PATHS } from 'constants/index';
import { NavigationTabs } from 'layouts/ConnectedDualSidenav/config/navigationConfig';

interface UsePrimaryNavBrandProps {
  clickWrapper: (params: { action: string }) => void;
  isPathActive: (path: string[] | string) => boolean;
  setExplicitNavOverride: (override: string | null) => void;
}

export const usePrimaryNavBrand = ({
  clickWrapper,
  isPathActive,
  setExplicitNavOverride,
}: UsePrimaryNavBrandProps) => {
  const isHomeActive = window.location.pathname === PATHS.portalSelection;
  return useMemo(() => {
    return [
      {
        name: NavigationTabs.HOME,
        id: 'novi-home',
        iconName: 'HouseSimple',
        link: PATHS.base,
        isActive: isHomeActive,
        onClick: () => clickWrapper({ action: `click_menu_novi_home` }),
        disableHover: true,
      },
      {
        name: NavigationTabs.PROGRAMS,
        id: 'programs',
        iconName: 'CardsThree',
        link: PATHS.retailerBrandDash,
        isActive: isPathActive(['/retailer-brand']),
        onClick: () => {
          clickWrapper({ action: 'click_menu_programs' });
          setExplicitNavOverride(null);
        },
      },
      {
        name: NavigationTabs.WORKBENCH,
        id: 'workbench',
        iconName: 'Flask',
        isActive: isPathActive([
          PATHS.projectDashboard,
          PATHS.product_marketplace,
        ]),
      },
      {
        name: NavigationTabs.SETTINGS,
        id: 'settings',
        iconName: 'GearSix',
        link: PATHS.userSettings,
        isActive: isPathActive([
          PATHS.userSettings,
          PATHS.brandProfileSettings,
        ]),
        position: 'bottom',
      },
      {
        name: NavigationTabs.AVATAR,
        id: 'avatar',
        iconName: 'Avatar',
        isActive: false,
        position: 'bottom',
      },
    ];
  }, [isHomeActive, isPathActive, clickWrapper, setExplicitNavOverride]);
};
