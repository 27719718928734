import {
  Text,
  TextKind,
  TextElement,
  PhosphorIcon,
} from 'design-system/components';
import styles from '../connected-dual-sidenav.module.scss';
import { Color } from 'design-system/data';

const IconMenuContent = ({ text, icon }: { text: string; icon: string }) => {
  return (
    <div className={styles['item-content']}>
      <PhosphorIcon iconName={icon} size={20} color={Color.Neutral500.value} />
      <Text element={TextElement.P} kind={TextKind.TextMDSemibold}>
        {text}
      </Text>
    </div>
  );
};

export default IconMenuContent;
