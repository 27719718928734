import {
  Grid,
  GridItem,
  Link,
  LinkKind,
  NoContent,
  Text,
  TextKind,
  TextElement,
} from 'design-system/components';

import links from 'constants/links';
import { useApp } from 'context/AppContext';

import useRetailerSelection from '../PortalSelection/useRetailerSelection';
import PortalCard from './PortalCard';
import RecommendedSection from './RecommendedSection';
import WorkbenchSection from './WorkbenchSection';
import styles from './portal-selection.module.scss';
import { Color } from 'design-system/data';
import { useEnterPortalHandler } from './useEnterPortalHandler';
import useChargebeeBillingEntrypoint from '../PortalSelection/useChargebeeBillingEntrypoint';

interface Program {
  name: string;
  program_badges: { image_path: string }[];
}

export interface Retailer {
  id: string;
  name: string;
  retailer_consumer_brand_id: number;
  is_accessible: boolean;
  image_path?: string;
  programs: Program[];
}

export interface RetailerData {
  retailers: Retailer[];
}

const PortalSelectionV2 = () => {
  const { user } = useApp();

  const { data, loading } = useRetailerSelection();
  const retailers = (data as RetailerData)?.retailers || [];
  const { enterPortalHandler } = useEnterPortalHandler();

  const onEnter = (retailer: Retailer) => {
    if (!retailer?.retailer_consumer_brand_id) {
      console.error(
        'Missing retailer_consumer_brand_id for retailer:',
        retailer
      );
      return;
    }
    enterPortalHandler(retailer);
  };

  const enterableRetailers = retailers.filter(
    (retailer) =>
      !!retailer.retailer_consumer_brand_id && !!retailer.is_accessible
  );

  const nonEnterableRetailers = retailers.filter(
    (retailer) =>
      !retailer.retailer_consumer_brand_id || !retailer.is_accessible
  );

  const userOrganizationId = user?.organization.id;
  const accessibleRetailerConsumerBrandIds = retailers
    ?.filter((retailer) => retailer.is_accessible)
    .map((retailer) => retailer.retailer_consumer_brand_id);

  useChargebeeBillingEntrypoint({
    userOrganizationId,
    accessibleRetailerConsumerBrandIds,
  });

  return (
    <div className={styles.container}>
      <Grid>
        <GridItem span={12} responsiveSpan={{ mobile: 8, tablet: 12 }}>
          <div className={styles.welcome}>
            <Text kind={TextKind.DisplayMDMedium} element={TextElement.H1}>
              Welcome to Novi
              {user?.organization?.name ? `, ${user?.organization?.name}` : ''}.
            </Text>
          </div>
        </GridItem>
      </Grid>
      {loading && (
        <Grid>
          <GridItem
            span={4}
            responsiveSpan={{ mobile: 4, tablet: 8, desktop: 12 }}
          >
            <div className={styles.cards}>
              <div className={styles.section}>
                <div className={styles.loading}>
                  <NoContent loading />
                </div>
              </div>
            </div>
          </GridItem>
        </Grid>
      )}
      {!loading && (
        <>
          {enterableRetailers.length > 0 && (
            <div className={styles.cards}>
              <div className={styles.section}>
                <Grid>
                  <GridItem
                    span={4}
                    responsiveSpan={{ mobile: 4, tablet: 8, desktop: 12 }}
                  >
                    <div className={styles.header}>
                      <Text
                        kind={TextKind.DisplayXSMedium}
                        element={TextElement.H2}
                      >
                        Your Programs
                      </Text>
                      <Text
                        kind={TextKind.TextMD}
                        color={Color.Neutral600}
                        element={TextElement.P}
                      >
                        These are the programs for retailers and certifying
                        bodies that you currently have access to on Novi. If you
                        believe that you should have access to a program that is
                        not shown below, please contact{' '}
                        <Link
                          kind={LinkKind.External}
                          href={links.mailtoSupport}
                        >
                          Support
                        </Link>
                        .
                      </Text>
                    </div>
                  </GridItem>
                </Grid>
              </div>
              <div className={styles.section}>
                <Grid>
                  {enterableRetailers.map((retailer) => (
                    <GridItem
                      span={4}
                      responsiveSpan={{ mobile: 4, tablet: 4, desktop: 4 }}
                      key={retailer.id}
                    >
                      <PortalCard
                        retailer={retailer}
                        isEnterable
                        onEnter={onEnter}
                      />
                    </GridItem>
                  ))}
                </Grid>
              </div>
            </div>
          )}
          <Grid>
            <GridItem span={12} responsiveSpan={{ tablet: 12, desktop: 12 }}>
              <RecommendedSection />
            </GridItem>
          </Grid>
          <div className={styles.cards}>
            {nonEnterableRetailers.length > 0 && (
              <div className={styles.section}>
                <Grid>
                  <GridItem
                    span={12}
                    responsiveSpan={{ tablet: 12, desktop: 12 }}
                  >
                    <div className={styles.header}>
                      <Text
                        kind={TextKind.DisplayXSMedium}
                        element={TextElement.H2}
                      >
                        Available programs on Novi
                      </Text>
                      <Text
                        kind={TextKind.TextMD}
                        element={TextElement.P}
                        color={Color.Neutral600}
                      >
                        These are other available programs for retailers and
                        certifying bodies currently on Novi. Click “Learn more”
                        in any of the cards below to register your interest in
                        enrolling in these programs.
                      </Text>
                    </div>
                  </GridItem>
                </Grid>
                <Grid>
                  {nonEnterableRetailers.map((retailer) => (
                    <GridItem
                      span={2}
                      responsiveSpan={{ mobile: 2, tablet: 2, desktop: 3 }}
                      key={retailer.id}
                    >
                      <PortalCard
                        retailer={retailer}
                        isEnterable={false}
                        onEnter={onEnter}
                      />
                    </GridItem>
                  ))}
                </Grid>
              </div>
            )}
          </div>
          <WorkbenchSection />
        </>
      )}
    </div>
  );
};

export default PortalSelectionV2;
