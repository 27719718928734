import React from 'react';
import cn from 'classnames';

import {
  CloudinaryImg,
  Link,
  LinkKind,
  MenuItem,
  NoContent,
  OpenAxis,
  PhosphorIcon,
  Text,
  TextElement,
  TextKind,
  Tooltip,
} from 'design-system/components';
import { Color } from 'design-system/data';

import { SecondaryNavProps, SecondaryNavSection } from './types';
import styles from './dual-sidenav.module.scss';

const Section = ({
  section,
}: {
  section:
    | SecondaryNavSection
    | { id: string; node: React.ReactNode; loading?: boolean };
}) => {
  if (section.loading) {
    return <NoContent noBorder loading />;
  }

  if (typeof section === 'object' && 'node' in section) {
    return section.node as React.ReactNode;
  }

  return (
    <div key={section.id} className={styles.section}>
      {section.title && (
        <Text
          element={TextElement.Span}
          kind={TextKind.TextSMSemibold}
          className={styles.title}
        >
          {section.title}
        </Text>
      )}
      {section.items.map((item) => (
        <MenuItem
          className={cn({ [styles['disabled']]: item.disabled })}
          key={item.id}
          compact
          href={item.href}
          onClick={item.onClick}
          loading={item.loading}
        >
          {item.content}
        </MenuItem>
      ))}
    </div>
  );
};

const SecondaryNav = ({
  secondaryNavData,
  className,
  onMouseEnter,
  onMouseLeave,
}: SecondaryNavProps) => {
  return (
    <div
      className={cn(styles['responsive-sidenav'], className)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className={styles.content}>
        <div className={styles.upper}>
          <div className={styles.header}>
            {/* Header - Always render the container with minimum dimensions */}
            {secondaryNavData?.header?.eyebrow && (
              <div className={styles['eyebrow-container']}>
                {secondaryNavData.header.eyebrow.node}
              </div>
            )}
            {/* Logo container - always present with minimum dimensions */}
            {secondaryNavData?.header?.logo && (
              <div
                className={cn(styles['logo-container'], {
                  // [styles['logo-container--loading']]: !secondaryNavData?.header?.logo.src && !secondaryNavData?.header?.logo.fallback
                })}
              >
                <Link kind={LinkKind.Internal} to="/">
                  <CloudinaryImg
                    src={secondaryNavData.header.logo.src}
                    alt={secondaryNavData.header.logo.alt}
                    height={40}
                    fallbackElement={
                      <Text
                        element={TextElement.H3}
                        kind={TextKind.DisplayXSBold}
                      >
                        {secondaryNavData.header.logo.fallback}
                      </Text>
                    }
                  />
                </Link>
              </div>
            )}

            {/* Rest of header content */}
            {secondaryNavData?.header?.title && (
              <Text
                element={TextElement.Span}
                kind={TextKind.TextSMSemibold}
                className={styles.title}
              >
                {secondaryNavData.header.title}
              </Text>
            )}
            {secondaryNavData.header?.items?.map((item) => (
              <MenuItem
                key={item.id}
                compact
                href={item.href}
                onClick={item.onClick}
                disabled={item.disabled}
              >
                {item.content}
              </MenuItem>
            ))}
            {secondaryNavData.header?.sections?.map((section) => (
              <Section key={section.id} section={section} />
            ))}
          </div>

          {/* Main */}
          {secondaryNavData.main && (
            <div className={styles.main}>
              {secondaryNavData.main.title && (
                <Text
                  element={TextElement.Span}
                  kind={TextKind.TextSMSemibold}
                  className={styles.title}
                >
                  {secondaryNavData.main.title}
                </Text>
              )}
              {secondaryNavData.main.items?.map((item) => (
                <MenuItem
                  key={item.id}
                  compact
                  href={item.href}
                  onClick={item.onClick}
                  disabled={item.disabled}
                >
                  {item.content}
                </MenuItem>
              ))}
              {secondaryNavData.main.sections?.map((section) => (
                <Section key={section.id} section={section} />
              ))}
            </div>
          )}
        </div>

        {/* Footer */}
        {secondaryNavData.footer && (
          <div className={styles.lower}>
            {secondaryNavData.footer.sections.map((section) => (
              <Section key={section.id} section={section} />
            ))}

            <div className={styles['lower-bottom']}>
              <div className={styles['lower-bottom-text']}>
                <Text element={TextElement.Span} kind={TextKind.TextSMSemibold}>
                  {secondaryNavData.footer.userInfo.username}
                </Text>
                <Text
                  element={TextElement.Span}
                  kind={TextKind.TextXS}
                  className={styles.email}
                  color={Color.Neutral600}
                >
                  {secondaryNavData.footer.userInfo.email}
                </Text>
              </div>
              <div className={styles.logout}>
                <Tooltip
                  openAxis={OpenAxis.Y}
                  triggerElement={
                    <div
                      role="button"
                      onClick={secondaryNavData.footer.userInfo.onLogout}
                    >
                      <PhosphorIcon
                        iconName="SignOut"
                        size={24}
                        color={Color.Neutral500.value}
                      />
                    </div>
                  }
                >
                  <Text element={TextElement.Span} kind={TextKind.TextSM}>
                    Log out
                  </Text>
                </Tooltip>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SecondaryNav;
