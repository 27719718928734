import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';
import {
  Grid,
  GridItem,
  GridBreakpoints,
  DualSidenav,
  Hamburger,
  ToastV2Menu,
} from 'design-system/components';
import cn from 'classnames';

import { useDualNavContext } from 'layouts/ConnectedDualSidenav/context';
import { ADMIN_PATH, PATHS } from 'constants/index';
import { NavigationTab } from './config/navigationConfig';
import useNavData from './hooks/Brands/useBrandNav';
import styles from './connected-dual-sidenav.module.scss';

const ConnectedDualSidenav = ({ children }: { children: React.ReactNode }) => {
  const collapsedRoutes = useMemo(
    () =>
      [
        PATHS.portalSelection,
        ADMIN_PATH,
        `${PATHS.projectDashboard}/:productId`,
        `${PATHS.projectDashboard}/:productId/actions`,
      ].filter((route) => route !== PATHS.projectDashboard),
    []
  );

  // Handle nav collapse on specific routes
  const overlayRoutes = collapsedRoutes;
  const [isSecondaryNavCollapsed, setIsSecondaryNavCollapsed] = useState(false);
  const [isOverlayMode, setIsOverlayMode] = useState(false);
  const [hoverTab, setHoverTab] = useState<NavigationTab | null>(null);

  const location = useLocation();
  const { hideNav, hideMobileNav, setHideMobileNav } = useDualNavContext();

  const navData = useNavData({
    setHideMobileNav,
    hoverTab,
    setHoverTab,
  });

  const { toastContextData } = navData;

  useEffect(() => {
    const shouldBeCollapsed = collapsedRoutes.some((route) =>
      matchPath(location.pathname, { path: route })
    );

    setIsSecondaryNavCollapsed(shouldBeCollapsed);
    setIsOverlayMode(shouldBeCollapsed);
  }, [location.pathname, overlayRoutes, collapsedRoutes]);

  useEffect(() => {
    if (!hideMobileNav) {
      // When opening mobile nav
      setIsSecondaryNavCollapsed(false);
    } else if (!navData.activeTab) {
      // When closing mobile nav (returning to desktop), collapse if no active tab
      setIsSecondaryNavCollapsed(true);
    }
  }, [hideMobileNav, navData.activeTab]);

  const contentSpans = {
    Mobile: 4,
    Tablet: 7,
    Desktop: 11,
  };

  return (
    <>
      <div className={styles['mobile-nav-trigger']}>
        <Hamburger
          isOpen={!hideMobileNav}
          onClick={() => setHideMobileNav(!hideMobileNav)}
        />
      </div>

      <Grid className={!hideMobileNav ? styles.fixed : undefined}>
        {!hideNav && (
          <GridItem
            span={4}
            responsiveSpan={{
              [GridBreakpoints.Tablet]: 1,
              [GridBreakpoints.Desktop]: 1,
            }}
            className={cn({
              [styles['hidden-mobile']]: hideMobileNav,
              [styles['dual-nav-mobile']]: !hideMobileNav,
            })}
          >
            <DualSidenav
              navData={navData}
              setHideMobileNav={setHideMobileNav}
              setIsSecondaryNavCollapsed={setIsSecondaryNavCollapsed}
              isSecondaryNavCollapsed={isSecondaryNavCollapsed}
              isOverlay={isOverlayMode}
              setPrimaryNavItem={setHoverTab}
              isMobile={!hideMobileNav}
            />
          </GridItem>
        )}
        <GridItem
          span={contentSpans.Mobile}
          responsiveSpan={{
            [GridBreakpoints.Tablet]: contentSpans.Tablet,
            [GridBreakpoints.Desktop]: contentSpans.Desktop,
          }}
          className={
            isSecondaryNavCollapsed || isOverlayMode
              ? styles['content-expanded']
              : styles['context-collapsed']
          }
        >
          <div className={styles['content-container']}>{children}</div>
        </GridItem>
      </Grid>
      <ToastV2Menu
        open={toastContextData.toastOpen}
        toastData={toastContextData.toastData}
        onClose={toastContextData.setToastOpen}
        duration={3000}
      />
    </>
  );
};

export default ConnectedDualSidenav;
