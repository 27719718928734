import { useCallback, useEffect, useState, useMemo } from 'react';
import { convertSnakeToCamelCase } from 'design-system/utils/case';
import useFetcher from 'services/api/useFetcher';
import { useApp } from 'context/AppContext';
import { ToastData } from 'design-system/components/molecules/toast/types';
import { ToastKind } from 'design-system/components';
import { ProgramBadgeType, ProgramCost, SubmissionDate } from '../types';

interface ProgramData {
  id: number;
  name: string;
  description: string;
  programBenefits: string;
  imagePath: string;
  programBadges: ProgramBadgeType[];
  programDates: SubmissionDate[];
  programPrices: ProgramCost[];
}

export const useFetchProgram = (programId: string) => {
  const { selectedRetailerId } = useApp();
  const fetcher = useFetcher();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ProgramData>();
  const [toastData, setToastData] = useState<ToastData[]>([]);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      if (!fetcher || !selectedRetailerId || !programId) return;

      const data = await fetcher(
        `/api/v4/retailers/${selectedRetailerId}/retailer_programs/${programId}`,
        { method: 'get' }
      );
      const convertedData = convertSnakeToCamelCase(data);
      setData(convertedData);
      setLoading(false);
    } catch (e: any) {
      setToastData((prevToastData) => [
        ...prevToastData,
        { title: 'Error', message: e.message, kind: ToastKind.Error },
      ]);
    }
  }, [selectedRetailerId, programId, fetcher]);

  useEffect(() => void fetchData(), [fetchData]);

  return useMemo(
    () => ({ data, loading, toastData }),
    [data, loading, toastData]
  );
};
