import { useState } from 'react';
import {
  Button,
  ButtonIconPosition,
  ButtonKind,
  Grid,
  GridItem,
  Text,
  TextElement,
  TextKind,
} from 'design-system/components';
import ProgramBadgeCard from './ProgramBadgeCard';
import styles from './program-badges.module.scss';
import { Color } from 'design-system/data';
import { ProgramBadgeType } from '../types';

const buttonProps = {
  kind: ButtonKind.Tertiary,
  usePhosphorIcon: true,
  iconPosition: ButtonIconPosition.Right,
};

const ProgramBadges = ({
  programBadges,
}: {
  programBadges: ProgramBadgeType[];
}) => {
  const [openAll, setOpenAll] = useState(false);

  const isSingleBadge = programBadges.length === 1;
  const actionButton = openAll ? (
    <Button
      {...buttonProps}
      iconName="Minus"
      onClick={() => setOpenAll(!openAll)}
    >
      Collapse all
    </Button>
  ) : (
    <Button
      {...buttonProps}
      iconName="Plus"
      onClick={() => setOpenAll(!openAll)}
    >
      Expand all
    </Button>
  );

  return (
    <>
      <div className={styles['badges-heading-row']}>
        <Text
          className={styles['section-heading']}
          element={TextElement.H2}
          kind={TextKind.Display2XSMedium}
          color={Color.Neutral900}
        >
          Available Badges
        </Text>
        {!isSingleBadge && actionButton}
      </div>
      {programBadges.length === 1 && (
        <div className={styles['single-program-container']}>
          <ProgramBadgeCard {...programBadges[0]} key={programBadges[0].id} />
        </div>
      )}
      {programBadges.length > 1 && (
        <Grid
          className={styles.grid}
          nestedColumns={{ mobile: 1, tablet: 2, desktop: 4 }}
        >
          {programBadges.map((badge) => {
            return (
              <GridItem
                key={badge.id}
                responsiveSpan={{ tablet: 1, desktop: 1 }}
              >
                <ProgramBadgeCard
                  {...badge}
                  key={badge.id}
                  openOverride={openAll}
                />
              </GridItem>
            );
          })}
        </Grid>
      )}
    </>
  );
};

export default ProgramBadges;
