import { useHistory } from 'react-router-dom';

import { useApp } from 'context/AppContext';
import fetcher from 'services/api/fetcher';
import useAsyncCall from 'services/api/useAsyncCall';
import { Retailer } from './index';
import { PATHS } from 'constants/index';
import { useAnalyticsEvent } from 'services/analytics/AnalyticsContext';

export const useEnterPortalHandler = () => {
  const history = useHistory();
  const { user, setSelectedRetailerBrandId, refetchRetailerBrand } = useApp();
  const { analyticsEvent } = useAnalyticsEvent();
  const handleNewRetailerBrandId = (
    retailerConsumerBrandId: string | number
  ) => {
    setSelectedRetailerBrandId(retailerConsumerBrandId);
  };

  const trackPortalSelection = (
    selectedPortal: string,
    retailerId: string | null
  ) => {
    analyticsEvent({
      label: 'portal_selection_page',
      category: 'select_portal_button',
      action: `click_enter_portal_button_${selectedPortal}`,
      entityId: { retailer_id: { value: retailerId } },
      selectedValue: selectedPortal,
    });
  };
  const { call: enterPortalHandler, loading } = useAsyncCall(
    async (retailer: Retailer) => {
      trackPortalSelection(retailer.name, retailer.id);
      const isNoviUser = user?.is_novi_email;
      const retailerConsumerBrandId = retailer.retailer_consumer_brand_id;
      const visit = await fetcher(
        `/api/v4/retailer_consumer_brands/${retailerConsumerBrandId}`
      );
      await fetcher(
        `/api/v4/retailer_consumer_brands/${retailerConsumerBrandId}/set_last_selected`,
        { method: 'post' }
      );
      if (retailerConsumerBrandId) {
        handleNewRetailerBrandId(retailerConsumerBrandId);
        await refetchRetailerBrand(retailerConsumerBrandId);
      }
      if (isNoviUser || !visit.previewed_at) {
        await fetcher(
          `/api/v4/retailer_consumer_brands/${retailerConsumerBrandId}/set_preview`,
          { method: 'post' }
        );
        return history.push(PATHS.retailerBrandWelcome);
      }
      return history.push(PATHS.retailerBrandDash);
    }
  );

  return { enterPortalHandler, loading };
};
