import { useState, useRef, useEffect } from 'react';
import propTypes from 'prop-types';
import {
  Text,
  TextElement,
  TextKind,
  FilterModal,
  TabGroup,
  Tab,
} from 'design-system/components';
import styles from './tabs-and-filter-header.module.scss';
import { FilterModalTypes } from '../filter-modal/utils';

export default function TabsAndFilterHeader({
  title,
  isMainHeader,
  subtitle,
  tabData,
  onTabClick,
  filters,
  filterState,
  onFilterModalClose,
  hideTabsAndFilter,
  hideFilter,
  dataCyRoot,
  children,
  actions,
  activeTabIndex: tabIndex = 0,
}) {
  const [activeTabIndex, setActiveTabIndex] = useState(tabIndex);
  const panelRef = useRef(null);
  const subtitleID = 'tabs-and-filter-header-subtitle';

  useEffect(() => {
    const checkScroll = () => {
      if (!panelRef.current) return;

      const { scrollLeft, scrollWidth, clientWidth } = panelRef.current;
      const isEnd = Math.abs(scrollWidth - clientWidth - scrollLeft) < 1;

      panelRef.current.setAttribute('data-scroll-end', isEnd);
    };

    const panel = panelRef.current;
    if (panel) {
      panel.addEventListener('scroll', checkScroll);
      // Check initial state
      checkScroll();

      // Check after content changes that might affect scroll width
      const resizeObserver = new ResizeObserver(checkScroll);
      resizeObserver.observe(panel);

      return () => {
        if (panel) {
          panel.removeEventListener('scroll', checkScroll);
          resizeObserver.disconnect();
        }
      };
    }
  }, []);

  return (
    <div className={styles.container}>
      {title && (
        <Text
          kind={isMainHeader ? TextKind.DisplaySMBold : TextKind.Display2XSBold}
          element={isMainHeader ? TextElement.H1 : TextElement.H2}
          data-cy={`${dataCyRoot}-page-title`}
        >
          {title}
        </Text>
      )}
      {subtitle && (
        <Text
          kind={TextKind.TextSM}
          element={isMainHeader ? TextElement.H2 : TextElement.H3}
          id={subtitleID}
        >
          {subtitle}
        </Text>
      )}
      {!hideTabsAndFilter && (
        <div className={styles['panel-wrapper']}>
          <div className={styles.panel} ref={panelRef}>
            <TabGroup
              activeTabIndex={activeTabIndex}
              ariaLabelledBy={subtitleID}
            >
              {tabData.map((tab, index) => (
                <Tab
                  key={tab.value}
                  chipValue={tab.quantity || 0}
                  isActive={activeTabIndex === index}
                  onClick={() => {
                    setActiveTabIndex(index);
                    onTabClick && onTabClick(tab.value);
                  }}
                  pillColor={tab.pillColor}
                >
                  {tab.label}
                </Tab>
              ))}
            </TabGroup>
            <div className={styles['filters-and-actions']}>
              {children ||
                (!hideFilter && (
                  <FilterModal
                    type={FilterModalTypes.Dropdown}
                    filters={filters}
                    state={filterState}
                    onModalClose={(data) => data && onFilterModalClose(data)}
                    dataCyRoot={`${dataCyRoot}-filter`}
                  />
                ))}
              {actions}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

TabsAndFilterHeader.propTypes = {
  title: propTypes.oneOfType([propTypes.string, propTypes.element]),
  isMainHeader: propTypes.bool,
  subtitle: propTypes.string,
  tabData: propTypes.arrayOf(propTypes.object).isRequired,
  onTabClick: propTypes.func,
  filters: propTypes.arrayOf(propTypes.object),
  filterState: propTypes.object,
  onFilterModalClose: propTypes.func,
  hideTabsAndFilter: propTypes.bool,
  hideFilter: propTypes.bool,
  dataCyRoot: propTypes.string,
  actions: propTypes.element,
};
