import {
  Breadcrumbs,
  PhosphorIconWeight,
  PhosphorIcon,
  Text,
  TextKind,
  TextElement,
  DropdownRadioMenu,
  PageHeader,
} from 'design-system/components';
import { useHistory } from 'react-router-dom';
import { UrlParam, Color } from 'design-system/data';
import { UploadButton } from './UploadButton';
import { FormInput } from 'views/FDARegistration/shared/components/Form';
import { useStatefulQueryParam } from 'design-system/utils';
import styles from './table-page-header.module.scss';

export const TablePageHeader = ({ isEmpty }) => {
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);

  // const [activeFilter, setActiveFilter] = useStatefulQueryParam(
  //   UrlParam.ConsumerProductType,
  //   params.get(UrlParam.ConsumerProductType) || ''
  // );
  const [activeSort, setActiveSort] = useStatefulQueryParam(
    UrlParam.Direction,
    params.get(UrlParam.Direction) || ''
  );
  const [search, setSearch] = useStatefulQueryParam(
    UrlParam.ProductName,
    params.get(UrlParam.ProductName) || ''
  );

  return (
    <>
      <PageHeader
        title="Your SKUs"
        breadcrumbs={<Breadcrumbs items={breadcrumbsItems} />}
        actions={isEmpty ? null : <UploadButton />}
      />
      {!isEmpty && (
        <div className={styles['options-row']}>
          <FormInput
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search SKU name"
            icon={
              <PhosphorIcon
                iconName="MagnifyingGlass"
                size="1.5rem"
                weight={PhosphorIconWeight.Light}
                color={Color.Neutral600.value}
              />
            }
          />
          <div className={styles['filters-wrapper']}>
            <DropdownRadioMenu
              fromLeft={true}
              options={SORT_OPTIONS}
              reverse
              activeValue={activeSort}
              setActiveValue={setActiveSort}
              triggerElement={
                <div className={styles['trigger-container']}>
                  <PhosphorIcon iconName="ArrowsDownUp" size={20} />
                  <Text
                    kind={TextKind.TextSMSemibold}
                    element={TextElement.P}
                    color={Color.Neutral600}
                    className={styles.nowrap}
                  >
                    {activeSort
                      ? `Sort by: ${SORT_OPTIONS[activeSort]}`
                      : 'Sort by'}
                  </Text>
                </div>
              }
            />
          </div>
        </div>
      )}
    </>
  );
};

const baseLink = `/fda-registration/product-registration`;
const breadcrumbsItems = [
  {
    text: 'SKUs',
    link: baseLink,
  },
];

// TODO: Replace once we update the filtering in the backend
// const FILTER_OPTIONS = {
//   'brand_uploaded': 'Uploaded',
//   'retailer_ingested': 'PIM Data',
// };

const SORT_OPTIONS = {
  'sku_name_asc': 'SKU name (A to Z)',
  'sku_name_desc': 'SKU name (Z to A)',
  'sku_id_asc': 'SKU ID (ascending)',
  'sku_id_desc': 'SKU ID (descending)',
  'upc_number_asc': 'UPC number (ascending)',
  'upc_number_desc': 'UPC number (descending)',
  'upload_desc': 'Upload date (most recent)',
  'upload_asc': 'Upload date (least recent)',
};
