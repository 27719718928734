import React, { useState } from 'react';
import {
  Button,
  InputV2,
  Modal,
  ButtonKind,
  ToastManager,
} from 'design-system/components';
import { createPortal } from 'react-dom';
import styles from './ingredient-request.module.scss';
import { useIngredientRequestForm } from './useIngredientRequest';

const ingredientRequestFormTitle = 'Request a new ingredient';
const ingredientRequestFormSubtitle =
  "Can't find the ingredient you're looking for? Submit a request and our team will review it.";

interface IngredientRequestFormData {
  requestedName: string;
  notes: string;
}

interface IngredientRequestFormProps {
  formState: ReturnType<typeof useIngredientRequestForm>;
}

export const IngredientRequestForm: React.FC<IngredientRequestFormProps> = ({
  formState,
}) => {
  const { formData, errors, handleInputChange, handleSubmit } = formState;

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <InputV2
        name="requestedName"
        type="text"
        value={formData.requestedName}
        required={true}
        label="Ingredient Name:"
        hasError={Boolean(errors.requestedName)}
        errorMessage={errors.requestedName}
        handleInputChange={handleInputChange('requestedName')}
      />
      <InputV2
        name="notes"
        type="text"
        value={formData.notes}
        required={false}
        label="Notes:"
        hasError={false}
        errorMessage=""
        handleInputChange={handleInputChange('notes')}
      />
    </form>
  );
};

const defaultIngredientRequestTriggerText =
  "Can't find what you're looking for?";

interface IngredientRequestDialogTriggerProps {
  triggerText?: string;
  unresolvedName?: string;
  onSubmit?: (data: IngredientRequestFormData) => void;
  ingredientId: number;
}

export const IngredientRequestDialogTrigger: React.FC<
  IngredientRequestDialogTriggerProps
> = ({
  triggerText = defaultIngredientRequestTriggerText,
  unresolvedName = '',
  onSubmit,
  ingredientId,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const formState = useIngredientRequestForm({
    initialName: unresolvedName,
    onSubmit: (data) => {
      onSubmit?.(data);
      setIsModalOpen(false);
    },
    ingredientId,
  });

  return (
    <>
      <Button
        className={styles['trigger-button']}
        kind={ButtonKind.Link}
        onClick={() => setIsModalOpen(true)}
      >
        {triggerText}
      </Button>

      {isModalOpen &&
        createPortal(
          <Modal
            title={ingredientRequestFormTitle}
            subtitle={ingredientRequestFormSubtitle}
            show={isModalOpen}
            onClick={() => setIsModalOpen(false)}
            showActionButtons={true}
            doneCtaLabel="Submit"
            onDone={formState.handleSubmit}
          >
            <div>
              <IngredientRequestForm formState={formState} />
            </div>
          </Modal>,
          document.body
        )}
      <ToastManager data={formState.toastData} />
    </>
  );
};
