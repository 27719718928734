import {
  TableRow,
  TableBodyCell,
  Text,
  TextKind,
  TextElement,
  Pill,
  Link,
  LinkKind,
  DropdownMenu,
  DropdownMenuItem,
  PhosphorIcon,
  Tooltip,
  OpenAxis,
  TableRowMenuTrigger,
} from 'design-system/components';
import { Color } from 'design-system/data';
import { useApp } from 'context/AppContext';
import { bool, number, shape, string } from 'prop-types';
import styles from './your-products-table.module.scss';
import { useYourProductContext } from '../context';

function YourProductsTableRow({ product, hasUPC }) {
  const { deleteProduct, setEditOpen, setEditProductInformation, products } =
    useYourProductContext();
  const { user } = useApp();
  const {
    organization: { in_orgable_id },
  } = user;
  const brandId = in_orgable_id;

  const {
    productName,
    productId,
    upcNumber,
    createdAt,
    isBrandUploadedProduct,
    skuId,
  } = product;

  const productLink = `/brand/${brandId}/product/${productId}/ingredients`;

  const handleClick = () => {
    setEditProductInformation({
      // this is setting with the default (ie, existing values)
      id: productId,
      name: productName,
      skuId: skuId,
      isBrandUploadedProduct: isBrandUploadedProduct,
      upcNumber: upcNumber,
    });
    setEditOpen(true);
  };

  return (
    <>
      <TableRow key={productId}>
        <TableBodyCell>
          {!skuId ? (
            <Text kind={TextKind.TextMD} element={TextElement.P}>
              -
            </Text>
          ) : (
            <Tooltip
              openAxis={OpenAxis.Y}
              triggerElement={
                <Text kind={TextKind.TextMD} element={TextElement.P}>
                  {skuId}
                </Text>
              }
            >
              {skuId}
            </Tooltip>
          )}
        </TableBodyCell>
        {hasUPC && (
          <TableBodyCell>
            <Text kind={TextKind.TextMD} element={TextElement.P}>
              {upcNumber}
            </Text>
          </TableBodyCell>
        )}
        <TableBodyCell>
          <Link kind={LinkKind.Internal} to={productLink}>
            {productName}
          </Link>
        </TableBodyCell>
        <TableBodyCell>
          {isBrandUploadedProduct ? (
            <Pill color="purple">Uploaded</Pill>
          ) : (
            <Pill>PIM data</Pill>
          )}
        </TableBodyCell>
        <TableBodyCell>
          <Text kind={TextKind.TextMD} element={TextElement.P}>
            {createdAt}
          </Text>
        </TableBodyCell>
        <TableBodyCell>
          <DropdownMenu
            enableAutomaticPositioning={products.consumerProducts?.length !== 1}
            isFixed={products.consumerProducts?.length === 1} // For some reason single SKU is not opening above the table
            triggerElement={<TableRowMenuTrigger />}
          >
            {isBrandUploadedProduct ? (
              <>
                <DropdownMenuItem onClick={handleClick}>
                  <span className={styles['dropdown-item']}>
                    <PhosphorIcon iconName="PencilSimple" size={14} />
                    &nbsp;Edit
                  </span>
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() =>
                    deleteProduct(productId, isBrandUploadedProduct)
                  }
                >
                  <span className={styles['dropdown-item']}>
                    <PhosphorIcon
                      color={Color.Red500.value}
                      iconName="Trash"
                      size={14}
                    />
                    &nbsp;Delete SKU
                  </span>
                </DropdownMenuItem>
              </>
            ) : (
              <DropdownMenuItem disabled={true}>
                <span className={styles['dropdown-item']}>No Actions</span>
              </DropdownMenuItem>
            )}
          </DropdownMenu>
        </TableBodyCell>
      </TableRow>
    </>
  );
}

YourProductsTableRow.propTypes = {
  product: shape({
    isBrandUploadedProduct: bool,
    isRetailerIngestedProduct: bool,
    productId: number,
    productName: string,
    retailerId: number,
    retailerImage: string,
    retailerName: string,
  }).isRequired,
  hasUPC: Boolean,
};

export default YourProductsTableRow;
