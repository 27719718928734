import { useMemo } from 'react';
import {
  SecondaryNavDataType,
  SecondaryNavSection,
  SecondaryNavUserInfo,
} from 'design-system/components';

import OrganizationItem from '../components/OrganizationItem';

interface UseSecondaryNavOrgMenuProps {
  clickWrapper: (params: { action: string }) => void;
  userInfo: SecondaryNavUserInfo;
  currentOrg: string;
  orgType: string;
  userRoles?: Array<{
    id: string;
    organization: {
      name: string;
      type: string;
    };
    is_active: boolean;
  }>;
  selectedRoleId?: string;
  onRoleSwitch: (roleId: string) => Promise<void>;
  inviteModalData: SecondaryNavSection;
  isRoleSwitching?: boolean;
}

export const useSecondaryNavOrgMenu = ({
  userInfo,
  currentOrg,
  orgType,
  userRoles,
  selectedRoleId,
  onRoleSwitch,
  inviteModalData,
  isRoleSwitching = false,
}: UseSecondaryNavOrgMenuProps): SecondaryNavDataType => {
  return useMemo(
    () => ({
      main: {
        sections: [
          {
            id: 'current-org',
            title: 'Current Organization',
            items: [
              {
                id: 'org-name',
                content: <OrganizationItem name={currentOrg} type={orgType} />,
                disabled: true,
              },
            ],
          },
          {
            id: 'change-org',
            title: 'Change Organization',
            items:
              userRoles
                ?.filter((role) => !role.is_active)
                .map((role) => ({
                  id: role.id,
                  content: (
                    <OrganizationItem
                      name={role.organization.name}
                      type={role.organization.type}
                    />
                  ),
                  onClick: () => onRoleSwitch(role.id),
                  disabled: selectedRoleId === role.id,
                })) || [],
            loading: isRoleSwitching,
          },
        ],
      },
      footer: {
        sections: [inviteModalData],
        userInfo,
      },
    }),
    [
      userInfo,
      currentOrg,
      orgType,
      userRoles,
      selectedRoleId,
      onRoleSwitch,
      inviteModalData,
      isRoleSwitching,
    ]
  );
};
