import { PATHS } from 'constants/index';

// Lint not picking up that these are used
/* eslint-disable no-unused-vars */
export enum RETAILER_KEYS {
  ULTA = 'ulta',
  TARGET = 'target',
  MACYS = 'macys',
  OTHER = 'other',
}
/* eslint-enable no-unused-vars */

const PRODUCT_MANAGEMENT_DESCRIPTIONS: Record<RETAILER_KEYS, string> = {
  [RETAILER_KEYS.ULTA]: 'Check your products as uploaded by Ulta PIM',
  [RETAILER_KEYS.TARGET]: 'Upload your products to Novi',
  [RETAILER_KEYS.MACYS]: 'Upload your products to Novi',
  [RETAILER_KEYS.OTHER]: 'Upload your products to Novi',
};

export const IMPORTANT_DATES_SUBTITLE: Record<RETAILER_KEYS, string> = {
  [RETAILER_KEYS.ULTA]:
    'Submissions are accepted on a rolling basis until the deadline.',
  [RETAILER_KEYS.TARGET]:
    'Submissions are accepted on rolling basis until these dates.',
  [RETAILER_KEYS.MACYS]:
    "Your timeline for Macy's Conscious Beauty and E-commerce Attributes programs.",
  [RETAILER_KEYS.OTHER]:
    'Submissions are accepted on rolling basis until these dates.',
};

const COMPLETE_PAYMENT_DESCRIPTIONS: Record<RETAILER_KEYS, string> = {
  [RETAILER_KEYS.ULTA]: 'Check the status of your subscription',
  [RETAILER_KEYS.TARGET]: 'This step is waived by Target for the year 2025',
  [RETAILER_KEYS.MACYS]: 'Check the status of your subscription',
  [RETAILER_KEYS.OTHER]: 'Check the status of your subscription',
};

export const HOW_TO_ENROLL_ROWS = [
  {
    title: 'Product management',
    description: (key: RETAILER_KEYS) => PRODUCT_MANAGEMENT_DESCRIPTIONS[key],
    phosphorIcon: 'Tag',
    href: PATHS.retailerBrandProducts,
    show: () => true,
  },
  {
    title: 'Sign contracts',
    description: () => 'Read and complete your affadavits',
    phosphorIcon: 'Signature',
    href: PATHS.retailerBrandDocuments,
    show: (key: RETAILER_KEYS) => key !== RETAILER_KEYS.MACYS,
  },
  {
    title: 'Complete payment',
    description: (key: RETAILER_KEYS) => COMPLETE_PAYMENT_DESCRIPTIONS[key],
    phosphorIcon: 'HandCoins',
    payment: true,
    show: (key: RETAILER_KEYS) => key !== RETAILER_KEYS.ULTA,
  },
  {
    title: 'Complete attestations',
    description: () => 'Review and complete the requirements for badges',
    phosphorIcon: 'ListChecks',
    href: PATHS.retailerBrandRequirementGroups,
    show: () => true,
  },
];
