import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';

import { PATHS } from 'constants/index';
import { useApp } from 'context/AppContext';
import useAsyncCall from 'services/api/useAsyncCall';
import fetcher from 'services/api/fetcher';

interface UseChargebeeCheckoutProps {
  /**
   * Current user's organization ID
   */
  userOrganizationId?: number;
  /**
   * List of accessible retailer brand IDs
   */
  accessibleRetailerConsumerBrandIds?: number[];
}

/**
 * Hook to handle Chargebee checkout flow from URL parameters
 */
const useChargebeeBillingEntrypoint = ({
  userOrganizationId,
  accessibleRetailerConsumerBrandIds,
}: UseChargebeeCheckoutProps): void => {
  const { setSelectedRetailerBrandId, refetchUser } = useApp();
  const history = useHistory();
  const location = useLocation();

  const onEnterDirect = useAsyncCall(
    async (retailerConsumerBrandId: number) => {
      await fetcher(
        `/api/v4/retailer_consumer_brands/${retailerConsumerBrandId}/set_last_selected`,
        { method: 'post' }
      );
      await refetchUser({ soft: true });
      setSelectedRetailerBrandId(retailerConsumerBrandId);

      const newSearchParams = new URLSearchParams(location.search);

      // Remove the Chargebee checkout params
      newSearchParams.delete('chargebee_checkout_org_id');
      newSearchParams.delete('chargebee_checkout_org_name');

      // history replace path but preserve query params
      return history.replace({
        pathname: PATHS.retailerBrandDash,
        search: newSearchParams.toString(),
      });
    }
  );

  useEffect(() => {
    if (!userOrganizationId) return;
    if (!accessibleRetailerConsumerBrandIds?.length) return;

    const searchParams = new URLSearchParams(location.search);
    const chargebeeCheckoutRetailerBrandId = Number(
      searchParams.get('chargebee_checkout_retailer_brand_id')
    );
    const chargebeeCheckoutOrgId = Number(
      searchParams.get('chargebee_checkout_org_id')
    );
    const chargebeeCheckoutOrgName = searchParams.get(
      'chargebee_checkout_org_name'
    );

    if (
      !chargebeeCheckoutRetailerBrandId ||
      !chargebeeCheckoutOrgId ||
      !chargebeeCheckoutOrgName
    ) {
      return;
    }
    if (chargebeeCheckoutOrgId !== userOrganizationId) {
      enqueueSnackbar(
        `Payment cannot be completed in current organization. Switch to ${chargebeeCheckoutOrgName} to proceed.`,
        { variant: 'error', autoHideDuration: 10000 }
      );
      return;
    }
    if (
      !accessibleRetailerConsumerBrandIds.includes(
        chargebeeCheckoutRetailerBrandId
      )
    ) {
      enqueueSnackbar(
        `Chargebee checkout requested for retailer brand ID ${chargebeeCheckoutRetailerBrandId} failed. Please try again.`,
        { variant: 'error', autoHideDuration: 10000 }
      );
      return;
    }

    onEnterDirect.call(chargebeeCheckoutRetailerBrandId);
  }, [
    userOrganizationId,
    accessibleRetailerConsumerBrandIds?.length,
    location.search,
  ]);
};

export default useChargebeeBillingEntrypoint;
