import {
  Breadcrumbs,
  NoContent,
  NoData,
  PageHeader,
} from 'design-system/components';
import ProgramCard from './ProgramCard';
import ProgramBadges from './ProgramBadges';
import ProgramDetails from './ProgramDetails';
import { useFetchProgram } from '../hooks/useFetchProgram';
import { useParams } from 'react-router-dom';
import { PATHS } from 'constants/index';

const ProgramDetailContainer = () => {
  const { program_id } = useParams();
  const { data: program, loading } = useFetchProgram(program_id);

  const breadcrumbItems = [
    {
      text: 'Programs',
      link: PATHS.retailerBrandPrograms,
    },
    {
      text: program?.name || '',
      link: '',
    },
  ];

  if (loading) return <NoContent loading />;
  if (!program)
    return (
      <NoData
        hasErrorOccurred
        noContentMessage="Something went wrong. Please try again or contact support."
      />
    );

  return (
    <>
      <PageHeader
        title={program.name}
        subtitle={program.description}
        breadcrumbs={<Breadcrumbs items={breadcrumbItems} />}
      />
      <ProgramCard
        policyId={`${program.id}`}
        description={program.programBenefits}
        src={program.imagePath}
      />
      <ProgramBadges programBadges={program.programBadges} />
      <ProgramDetails
        programName={program.name}
        submissionDates={program.programDates}
        programCosts={program.programPrices}
      />
    </>
  );
};

export default ProgramDetailContainer;
