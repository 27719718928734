import { Route, Switch } from 'react-router-dom';
import { PATHS } from 'constants/index';
import ProgramDetailContainer from './components/ProgramDetailContainer';
import ProgramListContainer from './components/ProgramListContainer';

const RetailerBrandPrograms = () => {
  return (
    <Switch>
      <Route
        path={PATHS.retailerBrandPrograms}
        component={ProgramListContainer}
        secure
        exact
      />
      <Route
        path={PATHS.retailerBrandProgramDetail}
        component={ProgramDetailContainer}
        secure
        exact
      />
    </Switch>
  );
};

export default RetailerBrandPrograms;
