import PropTypes from 'prop-types';
import * as RadixToast from '@radix-ui/react-toast';

function RUIToast({
  rootProps = {
    asChild: false,
    type: 'foreground',
    defaultOpen: false,
    open: false,
    className: '',
    duration: undefined,
    onOpenChange: () => {},
  },
  titleProps = {
    asChild: false,
  },
  descriptionProps = {
    asChild: false,
  },
  actionProps = {
    asChild: false,
  },
  closeProps = {
    asChild: false,
  },
  viewportProps = {
    asChild: false,
    hotkey: ['F8'],
    label: 'Notifications ({hotkey})',
  },
  children,
}) {
  return (
    <>
      <RadixToast.Provider swipeDirection="down">
        <RadixToast.Root {...rootProps}>
          {titleProps.children && <RadixToast.Title {...titleProps} />}
          {descriptionProps.children && (
            <RadixToast.Description {...descriptionProps} />
          )}
          {actionProps.children && <RadixToast.Action {...actionProps} />}
          {closeProps.children && <RadixToast.Close {...closeProps} />}
          {children}
        </RadixToast.Root>
        <RadixToast.Viewport {...viewportProps} />
      </RadixToast.Provider>
    </>
  );
}

RUIToast.propTypes = {
  rootProps: PropTypes.shape({
    asChild: PropTypes.bool,
    type: PropTypes.oneOf(['foreground', 'background']),
    duration: PropTypes.number,
    defaultOpen: PropTypes.bool,
    open: PropTypes.bool,
    onOpenChange: PropTypes.func,
    onEscapeKeyDown: PropTypes.func,
    onPause: PropTypes.func,
    onResume: PropTypes.func,
    onSwipeStart: PropTypes.func,
    onSwipeMove: PropTypes.func,
    onSwipeEnd: PropTypes.func,
    onSwipeCancel: PropTypes.func,
    forceMount: PropTypes.bool,
    className: PropTypes.string,
  }),
  titleProps: PropTypes.shape({
    asChild: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node,
  }),
  descriptionProps: PropTypes.shape({
    asChild: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node,
  }),
  actionProps: PropTypes.shape({
    asChild: PropTypes.bool,
    altText: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
  }),
  closeProps: PropTypes.shape({
    asChild: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node,
  }),
  viewportProps: PropTypes.shape({
    asChild: PropTypes.bool,
    hotkey: PropTypes.arrayOf(PropTypes.string),
    label: PropTypes.string,
    className: PropTypes.string,
  }),
  children: PropTypes.node,
};

export default RUIToast;
