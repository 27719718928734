import React, { useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Grid,
  GridItem,
  Text,
  TextKind,
  TextElement,
} from 'design-system/components';

import Disclaimer from '../shared/Disclaimer';
import { PATHS } from 'constants/index';
import { useApp } from 'context/AppContext';
import { hasChargebeePaywall } from 'utils/hardcodedNonsense';
import EligibilityProvider from '../../../components/EligibilityStatus/EligibilityContext';
import { DASHBOARD_NEXT_STEPS_DEFAULT_TEXT } from './NextStepsCTA/constants';
import { useYourProductContext } from '../YourProducts/context';
import { useChargebeeCheckout } from 'utils/chargebee';
import useRetailerPolicies from '../RetailerBrandWelcome/useRetailerPolicies';
import { useHubspotSurveyCheck } from 'hooks/useHubSpot';
import NextStepsSection from './SubmissionNextSteps/NextStepsSection';
import PillarCardsSection from './PillarCardsSection';
import UltaDashboardEligibility from 'components/EligibilityStatus/UltaDashboardEligibility';
import FooterRow from './FooterRow';
import styles from './retailerBrandDashboard.module.scss';
import useChargebeeDialogTrigger from './useChargebeeDialogTrigger';

interface Policy {
  submissionFlow: string;
}

interface TableCounts {
  all: number;
}

interface SubscriptionTier {
  product_count_min: number;
  product_count_max: number | null;
  name: string;
}

const RetailerBrandDashboardContainer: React.FC = () => {
  const {
    user,
    retailerBrand,
    subscriptionTiers,
    selectedRetailerBrandId,
    hasRetailerBrandResponseFinished,
  } = useApp();
  const { tableCounts } = useYourProductContext() as {
    tableCounts: TableCounts;
  };
  const orgName = user?.organization?.name;
  const hasOnlyUnsignedContracts =
    retailerBrand?.has_only_unsigned_contracts ?? false;
  const {
    data: retailerPolicies,
    error,
    loading,
    refetchRetailerPolicies,
  } = useRetailerPolicies(retailerBrand?.retailer?.id, retailerBrand?.id);

  useHubspotSurveyCheck(retailerPolicies?.policies);

  const noPoliciesAllowSubmission = retailerPolicies?.policies.some(
    (policy: Policy) => policy.submissionFlow === 'na'
  );

  const selectedTier = useMemo(() => {
    if (subscriptionTiers && tableCounts) {
      const subTier = subscriptionTiers.find((sub: SubscriptionTier) => {
        const skuCount = tableCounts.all;
        return (
          sub.product_count_min <= skuCount &&
          (sub.product_count_max === null || skuCount <= sub.product_count_max)
        );
      });
      return subTier;
    }

    return null;
  }, [subscriptionTiers, tableCounts]);

  const hasUnpaidSubscriptions = !retailerBrand?.unlocked;
  const noUploadedSkus = tableCounts?.all < 1;
  const hasUltaPaywallFlag = user?.hasFF('tmp_ulta_paywall');
  const hasChargebee = hasChargebeePaywall(
    retailerBrand?.retailer?.id,
    hasUltaPaywallFlag
  );

  const contractsText =
    retailerBrand?.retailer?.custom_pillar_agreement_text &&
    retailerBrand?.retailer?.custom_pillar_agreement_text.length > 0
      ? retailerBrand?.retailer?.custom_pillar_agreement_text
      : DASHBOARD_NEXT_STEPS_DEFAULT_TEXT.signContracts;

  const retailerBrandName = retailerBrand?.retailer?.name;

  const isUlta = retailerBrandName?.includes('Ulta Beauty');

  const showNextStepsCTACards =
    isUlta || hasOnlyUnsignedContracts || hasUnpaidSubscriptions;

  const handleChargebeeCheckout = useChargebeeCheckout(retailerBrand?.id);

  const anyPoliciesHaveCompleteSubmissionFlow = retailerPolicies?.policies.some(
    (policy: Policy) => policy.submissionFlow === 'complete'
  );
  const showCompletionCards =
    anyPoliciesHaveCompleteSubmissionFlow && !noUploadedSkus;

  const skuCount = useMemo(() => tableCounts?.all || 0, [tableCounts]);
  const tierName = useMemo(() => selectedTier?.name, [selectedTier]);

  // show next steps IF is in Ulta OR has no unpaid subscriptions OR has no uploaded skus
  const showSubmissionNextSteps =
    !hasChargebee || !hasUnpaidSubscriptions || noUploadedSkus;

  const showPaymentCard = hasUnpaidSubscriptions && !noUploadedSkus;
  const noSubmissionNextSteps = !showSubmissionNextSteps && showCompletionCards;

  useChargebeeDialogTrigger({
    retailerBrandId: retailerBrand?.id,
    onOpenDialog: handleChargebeeCheckout.call,
  });

  if (!selectedRetailerBrandId) {
    return <Redirect to={PATHS.portalSelection} />;
  }

  return (
    <Grid nestedColumns={{ mobile: 4, tablet: 6, desktop: 10 }}>
      <GridItem span={4} responsiveSpan={{ tablet: 4, desktop: 7 }}>
        <div className={styles['four-xl']}>
          <Text kind={TextKind.DisplaySM} element={TextElement.H1}>
            Hi{orgName ? `, ${orgName}` : ''}! Here&apos;s your{' '}
            {retailerBrandName ? `${retailerBrandName} ` : ''}
            dashboard.
          </Text>
        </div>
      </GridItem>
      <GridItem span={4} responsiveSpan={{ tablet: 6, desktop: 10 }}>
        <NextStepsSection
          hasRetailerBrandResponseFinished={hasRetailerBrandResponseFinished}
          showCompletionCards={showCompletionCards}
          showNextStepsCTACards={showNextStepsCTACards}
          showPaymentCard={showPaymentCard}
          handleChargebeeCheckout={handleChargebeeCheckout}
          skuCount={skuCount}
          tierName={tierName}
          hasOnlyUnsignedContracts={hasOnlyUnsignedContracts}
          contractsText={contractsText}
          noPoliciesAllowSubmission={noPoliciesAllowSubmission}
          hasChargebeePaywall={hasChargebee}
          hasUnpaidSubscriptions={hasUnpaidSubscriptions}
          noSubmissionNextSteps={noSubmissionNextSteps}
          showSubmissionNextSteps={showSubmissionNextSteps}
          retailerPolicies={retailerPolicies}
          refetchRetailerPolicies={refetchRetailerPolicies}
          loading={loading}
          retailerBrandName={retailerBrandName}
          retailerBrand={retailerBrand}
          noUploadedSkus={noUploadedSkus}
          programsPageEnabled={user?.hasFF('tmp_program_overview_page')}
        />
      </GridItem>
      {isUlta && (
        <GridItem span={4} responsiveSpan={{ tablet: 6, desktop: 10 }}>
          <EligibilityProvider>
            <UltaDashboardEligibility />
          </EligibilityProvider>
        </GridItem>
      )}
      {!isUlta && (
        <PillarCardsSection
          user={user}
          retailerPolicies={retailerPolicies}
          loading={loading}
          error={error}
        />
      )}
      <FooterRow retailerBrand={retailerBrand} />
      {retailerBrand?.retailer?.disclaimer && (
        <Disclaimer text={retailerBrand.retailer.disclaimer || ''} />
      )}
    </Grid>
  );
};

export default RetailerBrandDashboardContainer;
