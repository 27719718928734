import { RequirementActionsProvider } from 'views/Brands/shared/RequirementActionModalContents/context';
import RetailerRequirementsView from './view';

const RetailerRequirements = () => {
  return (
    <RequirementActionsProvider>
      <RetailerRequirementsView />
    </RequirementActionsProvider>
  );
};

export default RetailerRequirements;
