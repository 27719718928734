// useBrandNav.tsx
// Main hook for brand nav.
// Determines primary and secondary nav data based on active tab and hover state.
import {
  useMemo,
  useCallback,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import { useHistory } from 'react-router-dom';

import {
  DualNavDataReturnData,
  PrimaryNavUserInfo,
  SecondaryNavUserInfo,
  ToastKind,
} from 'design-system/components';

import { PATHS } from 'constants/index';
import { useChargebeeBillingInfo } from 'utils/chargebee';
import { useAnalyticsEvent } from 'services/analytics/AnalyticsContext';
import { useUserInvitation } from 'context/UserInvitationContext';
import { useApp } from 'context/AppContext';

import { hasChargebeePaywall } from 'utils/hardcodedNonsense';
import fetcher from 'services/api/fetcher';

import { useSecondaryNavWorkbench } from './useSecondaryNavWorkbench';
import { useSecondaryNavRetailerBrand } from './useSecondaryNavRetailerBrand';
import { useSecondaryNavPrograms } from './useSecondaryNavPrograms';
import { usePrimaryNavBrand } from './usePrimaryNavBrand';
import { NavigationTab, NavigationTabs } from '../../config/navigationConfig';
import { useSecondaryNavSettings } from '../useSecondaryNavSettings';
import { useSecondaryNavOrgMenu } from '../useSecondaryNavOrgMenu';
import IconMenuContent from '../../components/IconMenuContent';

export interface DualNavDataProps {
  setHideMobileNav: (value: boolean) => void;
  hoverTab: string | null;
  setHoverTab: Dispatch<SetStateAction<NavigationTab | null>>;
}

export interface RetailerData {
  id: string;
  name: string;
  image_path: string;
  support_email: string;
}

type ToastData = {
  title: string;
  kind: string;
  message: string;
};

const useNavData = ({
  setHideMobileNav,
  hoverTab,
  setHoverTab,
}: DualNavDataProps): DualNavDataReturnData => {
  // Hook declarations
  const history = useHistory();
  const { analyticsWrap } = useAnalyticsEvent();
  const { openModal: openInvitationModal } = useUserInvitation();
  const {
    auth0,
    user,
    retailerBrand: lastSelectedRetailerBrand,
    organization,
    refetchUser,
    setSelectedRetailerBrandId,
  } = useApp();

  // State declarations
  const [toastData, setToastData] = useState<ToastData[]>([]);
  const [toastOpen, setToastOpen] = useState(false);

  const [explicitNavOverride, setExplicitNavOverride] = useState<string | null>(
    null
  );
  const [isRoleSwitching, setIsRoleSwitching] = useState(false);
  const [shouldRefreshRetailers, setShouldRefreshRetailers] = useState(false);

  // Constants and computed values
  const hasUltaPaywallFlag = user?.hasFF('tmp_ulta_paywall');
  const computedHasChargebeePaywall = hasChargebeePaywall(
    lastSelectedRetailerBrand?.retailer?.id,
    hasUltaPaywallFlag
  );
  const billingInfoHook = useChargebeeBillingInfo(
    lastSelectedRetailerBrand?.id
  );
  const handleBillingInfo = computedHasChargebeePaywall
    ? billingInfoHook
    : undefined;

  // Callback declarations
  const logout = useCallback(() => {
    auth0?.client.logout({ returnTo: window.location.origin });
  }, [auth0]);

  const isPathActive = (path: string[] | string) => {
    const currentPath = window.location.pathname;
    if (Array.isArray(path)) {
      return path.some(
        (p) => currentPath === p || currentPath.startsWith(`${p}/`)
      );
    }
    // For home path ("/"), do an exact match
    if (path === '/') {
      return currentPath === '/';
    }
    // For other paths, check if it starts with the path
    return currentPath.startsWith(`${path}/`) || currentPath === path;
  };

  const clickWrapper = useCallback(
    ({ label = 'navigation', action = '', callback = () => {} }) => {
      setHideMobileNav(true);
      return analyticsWrap(
        {
          label,
          category: 'left_nav',
          action,
        },
        callback
      )();
    },
    [analyticsWrap, setHideMobileNav]
  );

  const inviteModalData = {
    id: 'invite-modal-section',
    items: [
      {
        id: 'invite-modal',
        content: <IconMenuContent text="Invite your team" icon="UsersThree" />,
        onClick: () =>
          clickWrapper({
            label: 'user_invitation_modal',
            action: 'click_user_invitation_cta',
            callback: openInvitationModal,
          }),
      },
    ],
  };

  const handleBackToPrograms = useCallback(() => {
    setHoverTab(NavigationTabs.PROGRAMS);
    setExplicitNavOverride(NavigationTabs.PROGRAMS);
  }, [setHoverTab]);

  const getActiveTab = useCallback(() => {
    const path = window.location.pathname;
    if (path.startsWith('/project/') || path.startsWith('/brand/')) {
      return NavigationTabs.WORKBENCH;
    }
    if (path.startsWith('/retailer-brand')) {
      return NavigationTabs.PROGRAMS;
    }

    return null;
  }, []);

  // User info objects
  const primaryNavUserInfo: PrimaryNavUserInfo = {
    currentOrg: organization?.name,
    orgType: organization?.type,
    username: user?.nickname,
    userRoles: user?.user_roles,
    onRoleSwitch: async (roleId: string) => {
      setIsRoleSwitching(true);
      try {
        await fetcher('/api/v4/users/switch_active_role', {
          method: 'post',
          body: { 'user_role_id': roleId },
          preventAbort: true,
        });
        await refetchUser();
        setSelectedRetailerBrandId(undefined);
        setShouldRefreshRetailers(true);
      } catch (e: unknown) {
        if (e instanceof Error) {
          setToastData((prevToastData) => [
            ...prevToastData,
            {
              title: 'Error',
              kind: ToastKind.Error,
              message: e.message,
            },
          ]);
          setToastOpen(true);
        }
        setIsRoleSwitching(false);
      } finally {
        setIsRoleSwitching(false);
        setToastData((prevToastData) => [
          ...prevToastData,
          {
            title: 'Success',
            kind: ToastKind.Success,
            message: 'Successfully switched role',
          },
        ]);
        setToastOpen(true);
        history.push(PATHS.base);
      }
    },
    selectedRoleId: undefined,
    onLogout: logout,
  };

  const secondaryNavUserInfo: SecondaryNavUserInfo = useMemo(
    () => ({
      username: user?.nickname || '',
      email: user?.email || '',
      onLogout: logout,
    }),
    [user?.nickname, user?.email, logout]
  );

  // Nav data hooks
  const primaryNavBrandItems = usePrimaryNavBrand({
    clickWrapper,
    isPathActive,
    setExplicitNavOverride,
  });

  const secondaryNavRetailerBrandData = useSecondaryNavRetailerBrand({
    clickWrapper,
    userInfo: secondaryNavUserInfo,
    onBackToPrograms: handleBackToPrograms,
    inviteModalData,
  });

  const secondaryNavWorkbenchData = useSecondaryNavWorkbench({
    clickWrapper,
    userInfo: secondaryNavUserInfo,
    inviteModalData,
  });

  const secondaryNavProgramsData = useSecondaryNavPrograms({
    clickWrapper,
    userInfo: secondaryNavUserInfo,
    inviteModalData,
    shouldRefreshRetailers,
    setShouldRefreshRetailers,
  });

  const secondaryNavSettingsData = useSecondaryNavSettings({
    clickWrapper,
    userInfo: secondaryNavUserInfo,
    handleBillingInfo,
  });

  const secondaryNavOrgMenuData = useSecondaryNavOrgMenu({
    clickWrapper,
    userInfo: secondaryNavUserInfo,
    currentOrg: organization?.name || '',
    orgType: organization?.type || '',
    userRoles: user?.user_roles,
    selectedRoleId: undefined,
    onRoleSwitch: primaryNavUserInfo.onRoleSwitch,
    inviteModalData,
    isRoleSwitching,
  });

  // Active tab and secondary nav data computation
  const activeTab = getActiveTab();
  const secondaryNavData = useMemo(() => {
    if (hoverTab && hoverTab !== activeTab) {
      if (hoverTab === NavigationTabs.PROGRAMS) return secondaryNavProgramsData;
      if (hoverTab === NavigationTabs.WORKBENCH)
        return secondaryNavWorkbenchData;
      if (hoverTab === NavigationTabs.SETTINGS) return secondaryNavSettingsData;
      if (hoverTab === NavigationTabs.AVATAR) return secondaryNavOrgMenuData;
    }
    if (activeTab === NavigationTabs.PROGRAMS) {
      return explicitNavOverride === NavigationTabs.PROGRAMS
        ? secondaryNavProgramsData
        : secondaryNavRetailerBrandData;
    }
    if (activeTab === NavigationTabs.WORKBENCH) {
      return secondaryNavWorkbenchData;
    }
    return secondaryNavRetailerBrandData;
  }, [
    explicitNavOverride,
    hoverTab,
    secondaryNavRetailerBrandData,
    secondaryNavWorkbenchData,
    secondaryNavProgramsData,
    secondaryNavSettingsData,
    activeTab,
    secondaryNavOrgMenuData,
  ]);

  const toastContextData = {
    toastOpen,
    setToastOpen,
    toastData,
    setToastData,
  };

  // Final nav data object
  const navData: DualNavDataReturnData = {
    primaryNavData: primaryNavBrandItems,
    secondaryNavData,
    userInfo: primaryNavUserInfo,
    activeTab,
    toastContextData,
  };

  return navData;
};

export default useNavData;
