export const formatDate = (dateString: string) => {
  if (!dateString) return '';

  const date = new Date(dateString);
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const formatDateV2 = (
  dateString: string,
  includeTime: boolean = false,
  join: string = '.'
): string => {
  if (!dateString) return '';

  const date = new Date(dateString);
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hour = String(date.getUTCHours()).padStart(2, '0');
  const minute = String(date.getUTCMinutes()).padStart(2, '0');
  return `${month}${join}${day}${join}${year}${
    includeTime ? `, ${hour}:${minute}` : ''
  }`;
};

export const formatToShortUTC12hr = (dateString: string) => {
  if (!dateString) return '';

  const date = new Date(dateString);
  const hour = date.getUTCHours();
  const isMorning = hour < 12;
  const calculateHour = () => {
    // Check if the hour is midnight or noon
    if (hour === 0 || hour === 12) {
      return 12; // Midnight or noon should display as 12
    }
    // Calculate hour for AM/PM display
    return isMorning ? hour : hour - 12;
  };
  const formattedHour = String(calculateHour()).padStart(2, '0');
  const minute = String(date.getUTCMinutes()).padStart(2, '0');
  return `${formattedHour}:${minute} ${isMorning ? 'AM' : 'PM'} UTC`;
};
