import { PATHS } from 'constants/index';
import {
  CloudinaryImg,
  Link,
  LinkKind,
  LinkVariant,
  TextKind,
  Text,
  TextElement,
  PhosphorIcon,
} from 'design-system/components';
import { Color } from 'design-system/data';
import styles from './program-card.module.scss';
import withLineBreaks from 'utils/withLineBreaks';

interface ProgramCardProps {
  policyId: string;
  description: string;
  src: string;
}

const ProgramCard = ({ policyId, description, src }: ProgramCardProps) => {
  return (
    <div className={styles.container}>
      <div className={styles['content-container']}>
        <div className={styles.image}>
          <CloudinaryImg
            width={104}
            src={src}
            alt="Program icon"
            fallbackElement={<PhosphorIcon iconName="ShieldCheck" size={48} />}
          />
        </div>
        <div className={styles.content}>
          <Text
            element={TextElement.H3}
            kind={TextKind.Display2XSMedium}
            color={Color.Neutral900}
          >
            Program enrollment benefits
          </Text>
          <Text
            element={TextElement.P}
            kind={TextKind.TextMD}
            color={Color.Neutral700}
          >
            {withLineBreaks(description)}
          </Text>
        </div>
      </div>
      <div className={styles.actions}>
        <Link
          kind={LinkKind.Internal}
          variant={LinkVariant.Secondary}
          to={PATHS.retailerBrandSKULevelStatus.replace(':policy_id', policyId)}
        >
          Review by SKU
        </Link>
        <Link
          kind={LinkKind.Internal}
          variant={LinkVariant.Primary}
          to={PATHS.retailerBrandRequirementGroups}
        >
          Review by pillar
        </Link>
      </div>
    </div>
  );
};

export default ProgramCard;
