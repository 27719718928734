import { useRef, useEffect, Dispatch, SetStateAction, useState } from 'react';
import cn from 'classnames';
import PrimaryNav from './PrimaryNav';
import SecondaryNav from './SecondaryNav';
import { DualNavDataReturnData } from './types';
import styles from './dual-sidenav.module.scss';

export interface DualSidenavProps {
  navData: DualNavDataReturnData;
  setHideMobileNav: (hide: boolean) => void;
  setIsSecondaryNavCollapsed: (collapsed: boolean) => void;
  isSecondaryNavCollapsed: boolean;
  isOverlay?: boolean;
  setPrimaryNavItem: Dispatch<SetStateAction<any | null>>;
  isMobile?: boolean;
}

const DualSidenav = ({
  navData,
  setIsSecondaryNavCollapsed,
  isSecondaryNavCollapsed,
  isOverlay = false,
  setPrimaryNavItem,
  isMobile,
}: DualSidenavProps) => {
  const previousNavDataRef = useRef(navData.secondaryNavData);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
  // Add state for render-time checks
  const [isTransitioningState, setIsTransitioningState] = useState(false);

  const hoverTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const isHoveringRef = useRef(false);
  const activeNavItemRef = useRef<string | null>(null);

  const clearHoverTimeout = () => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
      hoverTimeoutRef.current = null;
    }
  };

  const handleNavItemHover = (item: string | null) => {
    clearHoverTimeout();

    if (item) {
      // Entering a primary nav item
      isHoveringRef.current = true;
      activeNavItemRef.current = item;
      setPrimaryNavItem(item);
      setIsSecondaryNavCollapsed(false);
    } else {
      // Leaving a primary nav item
      isHoveringRef.current = false;

      // Give a small delay to check if we entered secondary nav
      hoverTimeoutRef.current = setTimeout(() => {
        if (!isHoveringRef.current) {
          activeNavItemRef.current = null;
          setPrimaryNavItem(null);
          // Collapse when no active tab and not hovering
          if (!navData.activeTab) {
            setIsSecondaryNavCollapsed(true);
          }
        }
      }, 250);
    }
  };

  const handleSecondaryNavHover = () => {
    clearHoverTimeout();
    isHoveringRef.current = true;
    setIsSecondaryNavCollapsed(false);
    if (activeNavItemRef.current) {
      setPrimaryNavItem(activeNavItemRef.current);
    }
  };

  const handleSecondaryNavLeave = () => {
    isHoveringRef.current = false;
    clearHoverTimeout();

    // Use same timeout pattern as primary nav leave
    hoverTimeoutRef.current = setTimeout(() => {
      if (!isHoveringRef.current) {
        if (isOverlay) {
          setIsSecondaryNavCollapsed(true);
        }
        activeNavItemRef.current = null;
        setPrimaryNavItem(null);
      }
    }, 250);
  };

  useEffect(() => {
    return () => clearHoverTimeout();
  }, []);

  useEffect(() => {
    if (isSecondaryNavCollapsed) {
      // Set both state and ref when collapse starts
      setIsTransitioningState(true);

      // Clear any existing timeout
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        setIsTransitioningState(false);
        previousNavDataRef.current = navData.secondaryNavData;
      }, 300);
    } else {
      setIsTransitioningState(false);
      previousNavDataRef.current = navData.secondaryNavData;
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isSecondaryNavCollapsed, navData.secondaryNavData]);

  // Use state for render-time check
  const currentSecondaryNavData = isTransitioningState
    ? previousNavDataRef.current
    : navData.secondaryNavData;

  const { primaryNavData, userInfo } = navData;

  const overlayClass = isOverlay ? styles['overlay-mode'] : undefined;
  const collapsedClass =
    isSecondaryNavCollapsed && !isMobile ? styles['collapsed'] : undefined;

  return (
    <div className={styles['dual-sidenav-wrapper']}>
      <PrimaryNav
        primaryNavItems={primaryNavData}
        userInfo={userInfo}
        handleNavItemHover={handleNavItemHover}
      />
      <SecondaryNav
        secondaryNavData={currentSecondaryNavData}
        className={cn(overlayClass, collapsedClass)}
        onMouseEnter={handleSecondaryNavHover}
        onMouseLeave={handleSecondaryNavLeave}
      />
    </div>
  );
};

export default DualSidenav;
