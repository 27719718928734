import { useState } from 'react';
import {
  Banner,
  BannerKind,
  BannerSize,
  Icon,
  IconName,
  Text,
  TextElement,
  TextKind,
  LinkKind,
  Link,
} from 'design-system/components';
import { useAnalyticsEvent } from 'services/analytics/AnalyticsContext';
import { Color } from 'design-system/data';

import styles from './sub-nav-banner.module.scss';
import { useApp } from 'context/AppContext';
import { PATHS, ROLES } from 'constants/index';

const SubNavBanner = () => {
  const { analyticsEvent } = useAnalyticsEvent();
  const bannerName = 'amazon_waitlist';
  const [open, setOpen] = useState(
    localStorage.getItem('closeSubNavBanner') !== bannerName
  );
  const onClose = () => {
    setOpen(false);
    localStorage.setItem('closeSubNavBanner', bannerName);
  };

  const { user } = useApp();

  const onClick = (action) => {
    analyticsEvent({
      category: 'global_nav',
      label: 'advertisement_banner',
      action,
    });
  };

  // true would show for all user roles
  const targetedRoles = user?.hasRole(ROLES.brand);

  const pageUrl = window.location.pathname;
  // true would show for all pages
  const targetedPages = pageUrl.includes(PATHS.portalSelection);

  const hasOldPortalPageWithMoCRA = user?.hasFF('old_portal_page_with_mocra');
  const suppressBanner =
    !targetedRoles || !targetedPages || !hasOldPortalPageWithMoCRA;
  if (suppressBanner || !open) {
    return null;
  }

  return (
    <Banner size={BannerSize.Small} kind={BannerKind.Welcome}>
      <div className={styles.content}>
        <Text kind={TextKind.TextMD} element={TextElement.Span}>
          <b>Big news:</b> Novi is partnering with Amazon! Interested in getting
          certified as a Climate Pledge Friendly (CPF) seller in just a few
          clicks? Add your brand to the wait list{' '}
          <Link
            kind={LinkKind.External}
            onClick={() => onClick('amazon_poc_waitlist_banner_click')}
            className={styles.link}
            href="https://nt972w7xpzh.typeform.com/novixamazoncpf"
          >
            <Text kind={TextKind.TextMDBold} element={TextElement.Span}>
              here.
            </Text>
          </Link>{' '}
        </Text>
        <div className={styles.close} onClick={onClose}>
          <Icon name={IconName.Close} color={Color.Neutral400} />
        </div>
      </div>
    </Banner>
  );
};

export default SubNavBanner;
