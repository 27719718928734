import { useState, useEffect } from 'react';
import { arrayOf, shape, number, bool, string } from 'prop-types';
import {
  Table,
  TableHead,
  TableHeadCell,
  TableBody,
  TableRow,
  NoData,
  TableFoot,
  TableBodyCell,
  PaginationV2,
} from 'design-system/components';
import styles from './your-products-table.module.scss';
import YourProductsTableRow from './your-products-table-row';
import { UrlParam } from 'design-system/data';
import { useStatefulQueryParam } from 'design-system/utils';

function YourProductsTable({ productsData, loading, error }) {
  const [page, setPage] = useStatefulQueryParam(UrlParam.Page, 1);
  const [hasUPC, setHasUPC] = useState(false);
  const products = productsData?.consumerProducts;

  useEffect(() => {
    if (
      products &&
      products.filter((product) => !!product.upcNumber).length > 0
    ) {
      setHasUPC(true);
    }
  }, [products]);
  if (!products?.length) {
    return (
      <NoData
        isLoading={loading}
        hasErrorOccurred={!!error}
        noContentMessage="There's nothing much here right now"
      />
    );
  }
  return (
    <div>
      <div className={`${styles.table} ${hasUPC ? styles['has-upc'] : ''}`}>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadCell>SKU ID</TableHeadCell>
              {hasUPC && <TableHeadCell>UPC</TableHeadCell>}
              <TableHeadCell>SKU name</TableHeadCell>
              <TableHeadCell>Source</TableHeadCell>
              <TableHeadCell>Date uploaded</TableHeadCell>
              <TableHeadCell>
                <p className="sr-only">Additional options</p>
              </TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product, i) => (
              <YourProductsTableRow product={product} key={i} hasUPC={hasUPC} />
            ))}
          </TableBody>
          <TableFoot bgWhite>
            <TableRow>
              <TableBodyCell colSpan={5}>
                <PaginationV2
                  onNextPage={() => setPage(page + 1)}
                  onPreviousPage={() => setPage(page - 1)}
                  onPageSelect={setPage}
                  currentPage={productsData?.currentPage}
                  totalItemCount={productsData?.totalEntries}
                  itemsPerPage={productsData?.perPage}
                  showItemCount
                />
              </TableBodyCell>
            </TableRow>
          </TableFoot>
        </Table>
      </div>
    </div>
  );
}

YourProductsTable.propTypes = {
  loading: bool,
  products: arrayOf(
    shape({
      isBrandUploadedProduct: bool,
      isRetailerIngestedProduct: bool,
      productId: number,
      productName: string,
      retailerId: number,
      retailerImage: string,
      retailerName: string,
    })
  ),
};

export default YourProductsTable;
