import { ScreeningStatus } from 'design-system/data';

export const ScreeningModalTypes = {
  Attestation: 'attestation',
  DocumentUpload: 'document_upload',
};

export const AttestationResponsesExtended = {
  yes: 'Yes',
  no: 'No',
  na: 'Not applicable',
};

export const AttestationResponses = {
  yes: 'Yes',
  no: 'No',
};

export const AttestationTypes = {
  yes_no: 'yes_no',
  yes_no_na: 'yes_no_na',
  yes: 'yes',
  note_only: 'note_only',
};

export const AttestationNoteType = {
  Required: 'required',
  Optional: 'optional',
};

export const attestationResponseExtendedOptions = () => {
  let options = [{ display: 'Please select', value: null }];
  Object.entries(AttestationResponsesExtended).map((entry) => {
    return options.push({ display: entry[1], value: entry[0] });
  });
  return options;
};

export const attestationResponseOptions = () => {
  let options = [{ display: 'Please select', value: null }];
  Object.entries(AttestationResponses).map((entry) => {
    return options.push({ display: entry[1], value: entry[0] });
  });
  return options;
};

export const attestationYesOptions = () => {
  return [
    { display: 'Please select', value: null },
    { display: 'Yes', value: 'yes' },
  ];
};

export const RequirementActionContentType = {
  ResolveIngredient: 'resolve ingredient',
  Function: 'ingredient function',
  AddIngredient: 'add-ingredient',
  Attestation: 'attestation',
  Percentage: 'ingredient percent',
  ProductLabeling: 'product labeling',
  ProductTesting: 'product testing',
  DocumentUpload: 'upload documentation',
};

export const tabStatuses = [
  '', // All
  `${ScreeningStatus.NeedsInformation},${ScreeningStatus.Updating}`,
  ScreeningStatus.Pass,
  `${ScreeningStatus.Fail},${ScreeningStatus.Rejected}`,
  ScreeningStatus.Pending,
  ScreeningStatus.NotApplicable,
];

export const tableHeaderData = [
  {
    label: 'SKUs',
    value: 'consumerProductName',
  },
  {
    label: 'Status',
    value: 'screeningStatus',
  },
  {
    label: '',
    value: 'cta',
  },
];

export const getAttestationOptions = (attestationType) => {
  if (attestationType === AttestationTypes.yes_no_na) {
    return attestationResponseExtendedOptions();
  }
  if (attestationType === AttestationTypes.yes_no) {
    return attestationResponseOptions();
  }
  if (attestationType === AttestationTypes.yes) {
    return attestationYesOptions();
  }
  return attestationResponseOptions();
};

export const AttestationActionType = {
  IngredientPercent: 'INGREDIENT_PERCENT',
  IngredientFunction: 'INGREDIENT_FUNCTION',
  Attestation: 'ATTESTATION',
  Documentation: 'DOCUMENT',
  UnresolvedIngredent: 'UNRESOLVED_INGREDIENT',
  AlternateIngredient: 'ALTERNATE_INGREDIENT',
  Composable: 'COMPOSABLE',
  EditProduct: 'EDIT_PRODUCT',
};
