import { useEffect, useMemo, useState } from 'react';
import orderBy from 'lodash/orderBy';

import {
  Table,
  TableHead,
  TableRow,
  TableHeadCell,
  TableBody,
  TableBodyCell,
  Button,
  ButtonKind,
  IconName,
  IconDirection,
  ButtonIconPosition,
  TabGroup,
  Tab,
  Checkbox,
  TabsAndTable,
  LoadingOverlay,
  Breadcrumbs,
  Pill,
  Text,
  TextKind,
  TextElement,
  ToastV2Menu,
  TableFoot,
  PaginationV2,
  PhosphorIcon,
  PhosphorIconWeight,
  PillColor,
  PageHeader,
} from 'design-system/components';
import {
  sortDirection,
  useTableSort,
  useInitialQueryParams,
} from 'design-system/utils';
import { Color } from 'design-system/data';
import { SkuEmptyState } from './components/SkuEmptyState';
import styles from './products.module.scss';

import { useFDAProductsContext } from './Context';
import ActionsRow from '../shared/components/ActionsRow';
import TableRowDropdown from './components/TableRowDropdown';
import ScreeningStatusPill from './components/ScreeningStatusPill';
import { useHistory } from 'react-router-dom';
import { FormInput } from '../shared/components/Form';
import { setUrlSearchParameters } from '../shared/utils';
import { STATUS_MAPPINGS, STATUS_COLORS, STATUS_FILTERS } from './constants';

const isNotSubmitted = (product) =>
  ['draft', 'ready_to_submit'].includes(product.status);
const canToggleCheckbox = (product) =>
  isNotSubmitted(product) && product.complete;

const ProductsView = () => {
  const baseLink = `/fda-registration/product-registration`;
  const breadcrumbsItems = [
    {
      text: 'SKUs',
      link: baseLink,
    },
  ];

  const {
    products,
    statusFilter,
    handleStatusChange,
    setSelectedSkus,
    selectedSkuKeys,
    setPaywallDialogOpen,
    paywallDialogOpen,
    loading,
  } = useFDAProductsContext();
  const history = useHistory();

  const [search, setSearch] = useState(
    useInitialQueryParams('product_name', '')
  );

  const tableState = useTableSort({
    initialSortByValue: 'sku',
    initialSortAsc: false,
  })[0];

  const [currentPage, setCurrentPage] = useState(
    Number(useInitialQueryParams('page', 1))
  );
  const itemsPerPage = useInitialQueryParams('items', 10);

  useEffect(() => {
    setUrlSearchParameters(
      tableState,
      currentPage,
      itemsPerPage,
      history,
      search,
      STATUS_FILTERS[statusFilter] // backend expects the string version
    );
  }, [history, tableState, currentPage, itemsPerPage, search, statusFilter]);

  const tabData = useMemo(() => {
    return [
      {
        id: 1,
        label: 'All SKUs',
        value: 'all_requirements',
        quantity: products?.totalEntries,
        pillColor: PillColor.Purple,
      },
      {
        id: 2,
        label: 'Needs attention',
        value: 'incomplete',
        quantity: products?.incompleteCount,
        pillColor: PillColor.Orange,
      },
      {
        id: 3,
        label: 'Complete',
        value: 'pass',
        quantity: products?.completeCount,
        pillColor: PillColor.Green,
      },
    ];
  }, [products]);

  const toggleAllCheckboxes = () => {
    if (selectedSkuKeys.length === products?.products?.length) {
      setSelectedSkus({});
    } else {
      const skuObjects = {};
      products?.products
        ?.filter(canToggleCheckbox)
        ?.forEach((product) => (skuObjects[product.id] = products.productName));
      setSelectedSkus(skuObjects);
    }
  };

  const deselectAllCheckboxes = () => {
    setSelectedSkus({});
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    handleStatusChange(statusFilter);
  };

  const onCheckboxChange = (sku) => {
    const skuId = sku.id;
    const skuName = sku.productName;
    if (!canToggleCheckbox(sku)) return;

    setSelectedSkus((prev) => {
      if (prev[skuId]) {
        const { [skuId]: removedSku, ...rest } = prev;
        return rest;
      }
      return { ...prev, [skuId]: skuName };
    });
  };

  const currentTabData = products?.products;
  const hasContents = products?.products?.length > 0 || search !== '';

  return (
    <main>
      <LoadingOverlay show={loading} />
      <PageHeader
        title="Your SKUs"
        breadcrumbs={<Breadcrumbs items={breadcrumbsItems} />}
        actions={<ActionsRow />}
      />
      <TabsAndTable
        tabs={
          <TabGroup activeTabIndex={statusFilter}>
            {tabData.map((tab, index) => (
              <Tab
                key={tab.value}
                chipValue={tab.quantity || 0}
                pillColor={tab.pillColor}
                isActive={statusFilter === index}
                onClick={() => {
                  handleStatusChange(index);
                }}
              >
                {tab.label}
              </Tab>
            ))}
          </TabGroup>
        }
        toggle={
          !hasContents ? null : (
            <div className={styles['actions-cell']}>
              <FormInput
                type="text"
                value={search}
                onChange={handleSearchChange}
                placeholder="Search for a SKU..."
                icon={
                  <PhosphorIcon
                    iconName="MagnifyingGlass"
                    size="1.5rem"
                    weight={PhosphorIconWeight.Light}
                    color={Color.Neutral600.value}
                  />
                }
              />
              {/* <Button
                kind={ButtonKind.Tertiary}
                onClick={() =>
                  dispatch({
                    type: TableReducerAction.LabelClick,
                    sortBy: 'product_name',
                  })
                }
                usePhosphorIcon={true}
                iconName="ArrowsDownUp"
              >
                Sort by: A to Z
              </Button> */}
            </div>
          )
        }
        table={
          <>
            {!hasContents ? (
              <div className={styles['table-with-border']}>
                <SkuEmptyState loading={loading} />
              </div>
            ) : (
              <div className={styles.table}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableHeadCell>
                        <Checkbox
                          onClick={toggleAllCheckboxes}
                          isActive={
                            selectedSkuKeys.length > 0 &&
                            products?.products?.filter(canToggleCheckbox)
                              ?.length === selectedSkuKeys.length
                          }
                        />
                      </TableHeadCell>
                      <TableHeadCell>SKU</TableHeadCell>
                      <TableHeadCell>SKU ID</TableHeadCell>
                      <TableHeadCell>Information</TableHeadCell>
                      <TableHeadCell>Registration</TableHeadCell>
                      <TableHeadCell>
                        <p className="sr-only">Additional options</p>
                      </TableHeadCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderBy(
                      currentTabData,
                      tableState.sortBy,
                      sortDirection(tableState)
                    ).map((product, i) => (
                      <TableRow key={product.id}>
                        <TableBodyCell>
                          <Checkbox
                            onClick={() => onCheckboxChange(product)}
                            isActive={selectedSkuKeys.includes(`${product.id}`)}
                            disabled={!canToggleCheckbox(product)}
                          />
                        </TableBodyCell>
                        <TableBodyCell>{product.productName}</TableBodyCell>
                        <TableBodyCell>
                          {product.productIdentifier || '-'}
                        </TableBodyCell>
                        <TableBodyCell>
                          <ScreeningStatusPill
                            withDot
                            status={
                              product.complete ? 'complete' : 'needs_info'
                            }
                          />
                        </TableBodyCell>
                        <TableBodyCell>
                          <Pill color={STATUS_COLORS[product.status]}>
                            {STATUS_MAPPINGS[product.status] || '-'}
                          </Pill>
                        </TableBodyCell>
                        <TableBodyCell>
                          <div className={styles['actions-cell']}>
                            {getButtonForSku(product, history)}
                            <TableRowDropdown
                              disabled={!isNotSubmitted(product)}
                              skuId={product.id}
                            />
                          </div>
                        </TableBodyCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFoot bgWhite>
                    <TableRow>
                      <TableBodyCell colSpan={6}>
                        <PaginationV2
                          onNextPage={() => setCurrentPage(currentPage + 1)}
                          onPreviousPage={() => setCurrentPage(currentPage - 1)}
                          onPageSelect={setCurrentPage}
                          currentPage={products?.currentPage}
                          totalItemCount={products?.totalEntries}
                          itemsPerPage={products?.perPage}
                        />
                      </TableBodyCell>
                    </TableRow>
                  </TableFoot>
                </Table>
              </div>
            )}
          </>
        }
      />
      <ToastV2Menu open={Boolean(selectedSkuKeys.length) && !paywallDialogOpen}>
        <Text
          kind={TextKind.TextSMSemibold}
          element={TextElement.P}
          color={Color.Neutral600}
        >{`${selectedSkuKeys.length} SKU${
          selectedSkuKeys.length !== 1 ? 's' : ''
        } selected`}</Text>
        <Button kind={ButtonKind.Error} onClick={deselectAllCheckboxes}>
          {' '}
          Deselect all{' '}
        </Button>
        <Button
          kind={ButtonKind.Primary}
          usePhosphorIcon
          iconName="ArrowRight"
          iconPosition={ButtonIconPosition.Right}
          onClick={() => setPaywallDialogOpen(true)}
        >
          Continue to payment
        </Button>
      </ToastV2Menu>
    </main>
  );
};

export default ProductsView;

const getButtonForSku = (product, history) => {
  switch (product.status) {
    case 'ready_to_submit':
      return (
        <Button
          kind={ButtonKind.Tertiary}
          iconName={IconName.ArrowV2}
          iconPosition={ButtonIconPosition.Right}
          iconDirection={IconDirection.Right}
          onClick={() => history.push(`product-registration/${product.id}`)}
        >
          Review
        </Button>
      );
    case 'draft':
      return (
        <Button
          kind={ButtonKind.Alternate}
          iconName={IconName.ArrowV2}
          iconPosition={ButtonIconPosition.Right}
          iconDirection={IconDirection.Right}
          iconColor={Color.Purple600}
          onClick={() => history.push(`product-registration/${product.id}`)}
        >
          Complete
        </Button>
      );
    default:
      return (
        <Button
          kind={ButtonKind.Tertiary}
          iconName={IconName.ArrowV2}
          iconPosition={ButtonIconPosition.Right}
          iconDirection={IconDirection.Right}
          onClick={() => history.push(`submissions/${product.listingId}`)}
        >
          View submission
        </Button>
      );
  }
};
