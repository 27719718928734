import { Text, TextKind, TextElement } from 'design-system/components';
import DocumentsPageEligibilityStatusTable from './DocumentsPageEligibilityStatusTable';
import styles from './eligibility-status-table.module.scss';
import { Color } from 'design-system/data';
import {
  useEligibilityContext,
  EligibilityContextType,
} from './EligibilityContext';

function DocumentsPageEligibility() {
  const { containedPolicies } =
    useEligibilityContext() as EligibilityContextType;

  if (containedPolicies?.length < 1) return null;

  return (
    <div className={styles['documents-page-container']}>
      <div className={styles['documents-page-heading']}>
        <Text
          color={Color.Neutral900}
          element={TextElement.H2}
          kind={TextKind.Display2XSMedium}
        >
          Contracts
        </Text>
        <Text
          color={Color.Neutral600}
          element={TextElement.P}
          kind={TextKind.TextSM}
        >
          Check your email for contract access. You can forward directly to
          authorized signers from within each document as needed.
        </Text>
      </div>
      <DocumentsPageEligibilityStatusTable />
    </div>
  );
}

export default DocumentsPageEligibility;
