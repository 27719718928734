import cn from 'classnames';
import {
  NoContent,
  Text,
  TextKind,
  TextElement,
  CloudinaryImg,
  PhosphorIcon,
  LinkKind,
} from 'design-system/components';

import { BrandColors } from 'design-system/data';
import {
  DASHBOARD_NEXT_STEPS_CTA_TEXT,
  DASHBOARD_NEXT_STEPS_DEFAULT_TEXT,
  DASHBOARD_NEXT_STEPS_HEADINGS,
} from '../NextStepsCTA/constants';
import NextStepsCTA from '../NextStepsCTA/NextStepsCTA';
import { PATHS } from 'constants/index';
import PaymentCard from './PaymentCard';
import SubmissionNextSteps from './SubmissionNextSteps';
import styles from '../retailerBrandDashboard.module.scss';
import { PolicyProps } from 'views/Brands/RetailerBrandEligibility/PolicySelectionPage/PolicyCard/types';

type NextStepsSectionTypes = {
  hasRetailerBrandResponseFinished: boolean;
  showCompletionCards: boolean;
  showNextStepsCTACards: boolean;
  showPaymentCard: boolean;
  handleChargebeeCheckout: any;
  skuCount: number;
  tierName: string;
  hasOnlyUnsignedContracts: boolean;
  contractsText: string;
  noPoliciesAllowSubmission: boolean;
  hasChargebeePaywall: boolean;
  hasUnpaidSubscriptions: boolean;
  noSubmissionNextSteps: boolean;
  showSubmissionNextSteps: boolean;
  retailerPolicies: { policies: PolicyProps[] };
  refetchRetailerPolicies: any;
  loading: boolean;
  retailerBrandName: string;
  retailerBrand: any;
  noUploadedSkus: boolean;
  programsPageEnabled?: boolean;
};

const NextStepsSection = ({
  hasRetailerBrandResponseFinished,
  showCompletionCards,
  showNextStepsCTACards,
  handleChargebeeCheckout,
  skuCount,
  tierName,
  hasOnlyUnsignedContracts,
  contractsText,
  noPoliciesAllowSubmission,
  hasChargebeePaywall,
  hasUnpaidSubscriptions,
  showPaymentCard,
  noSubmissionNextSteps,
  showSubmissionNextSteps,
  retailerPolicies,
  refetchRetailerPolicies,
  loading,
  retailerBrandName,
  retailerBrand,
  noUploadedSkus,
  programsPageEnabled,
}: NextStepsSectionTypes) => {
  if (!hasRetailerBrandResponseFinished) {
    return <NoContent loading />;
  }

  const programsNextStep = (
    <NextStepsCTA
      cardHeading={DASHBOARD_NEXT_STEPS_HEADINGS.programs}
      phosphorIconName="ListMagnifyingGlass"
      descriptiveText={`Review details about ${retailerBrand?.retailer?.name}'s program(s), including badging requirements, upcoming deadlines, and program benefits.`}
      redirectPath={PATHS.retailerBrandPrograms}
      linkKind={LinkKind.Internal}
      ctaButtonText={DASHBOARD_NEXT_STEPS_CTA_TEXT.programs}
    />
  );

  return (
    <>
      {/* per policy next steps if multiple */}
      {showCompletionCards && programsPageEnabled && (
        <div className={styles.cards}>{programsNextStep}</div>
      )}
      {!showCompletionCards && (
        <div>
          <div className={cn([styles['section-header'], styles['three-xl']])}>
            <Text
              kind={TextKind.DisplayXSMedium}
              element={TextElement.P}
              color={BrandColors.Gray900}
            >
              Next Steps
            </Text>
          </div>
          {programsPageEnabled && (
            <div className={styles.cards}>{programsNextStep}</div>
          )}
          {showNextStepsCTACards && (
            <div className={styles.cards}>
              {showPaymentCard && (
                <PaymentCard
                  hasChargebee={hasChargebeePaywall}
                  handleChargebeeCheckout={handleChargebeeCheckout}
                  skuCount={skuCount}
                  tierName={tierName}
                />
              )}
              {hasOnlyUnsignedContracts && (
                <NextStepsCTA
                  cardHeading={DASHBOARD_NEXT_STEPS_HEADINGS.signContracts}
                  phosphorIconName="Signature"
                  descriptiveText={contractsText}
                  redirectPath={PATHS.retailerBrandDocuments}
                  linkKind={LinkKind.Internal}
                  ctaButtonText={DASHBOARD_NEXT_STEPS_CTA_TEXT.signContracts}
                />
              )}
              {noPoliciesAllowSubmission &&
                (!hasChargebeePaywall || !hasUnpaidSubscriptions) && (
                  <NextStepsCTA
                    cardHeading={DASHBOARD_NEXT_STEPS_HEADINGS.attestations}
                    phosphorIconName="ListChecks"
                    descriptiveText={
                      DASHBOARD_NEXT_STEPS_DEFAULT_TEXT.attestations
                    }
                    redirectPath={PATHS.retailerBrandRequirementGroups}
                    linkKind={LinkKind.Internal}
                    ctaButtonText={DASHBOARD_NEXT_STEPS_CTA_TEXT.attestations}
                  />
                )}
            </div>
          )}
        </div>
      )}
      {showPaymentCard && noSubmissionNextSteps && (
        <PaymentCard
          hasChargebee={hasChargebeePaywall}
          handleChargebeeCheckout={handleChargebeeCheckout}
          skuCount={skuCount}
          tierName={tierName}
        />
      )}
      {showSubmissionNextSteps && (
        <div className={styles['next-steps-wrapper']}>
          {retailerPolicies?.policies.length > 0 &&
            retailerPolicies.policies.map((policy, i) => {
              if (noUploadedSkus && i > 0) {
                // Only show one card if no skus are uploaded
                return null;
              }
              return (
                <div
                  className={styles['next-steps-section']}
                  style={
                    policy.submissionFlow === 'one_or_more_skus'
                      ? {}
                      : { gap: 0 }
                  }
                  key={policy.id}
                >
                  {showCompletionCards && (
                    <div className={styles['section-header']}>
                      <CloudinaryImg
                        fallbackElement={
                          <PhosphorIcon size={48} iconName="ShieldCheck" />
                        }
                        src={policy.imagePath}
                        height={48}
                        width={48}
                      />
                      <div className={styles['section-header-content']}>
                        <Text
                          kind={TextKind.DisplayXSMedium}
                          element={TextElement.P}
                          color={BrandColors.Gray900}
                        >
                          Next Steps for {policy.name}
                        </Text>
                        {policy.description && (
                          <Text
                            kind={TextKind.TextSM}
                            element={TextElement.P}
                            color={BrandColors.Gray600}
                          >
                            {policy.description}
                          </Text>
                        )}
                      </div>
                    </div>
                  )}
                  <SubmissionNextSteps
                    key={policy.id}
                    policy={policy}
                    refetchPolicies={refetchRetailerPolicies}
                    loading={loading}
                    retailerName={retailerBrandName}
                    retailerId={retailerBrand?.retailer?.id}
                    canSubmit={!hasUnpaidSubscriptions}
                    noSkuData={noUploadedSkus}
                  />
                </div>
              );
            })}
        </div>
      )}
    </>
  );
};

export default NextStepsSection;
