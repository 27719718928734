import { Text, TextKind, TextElement } from 'design-system/components';
import { Color } from 'design-system/data';

import styles from '../connected-dual-sidenav.module.scss';

const OrganizationItem = ({ name, type }: { name: string; type: string }) => {
  return (
    <div className={styles['organization-item']}>
      <Text
        kind={TextKind.TextMDSemibold}
        element={TextElement.P}
        color={Color.Neutral500}
      >
        {name}
      </Text>
      <Text
        kind={TextKind.TextSM}
        element={TextElement.P}
        color={Color.Neutral500}
      >
        {type}
      </Text>
    </div>
  );
};

export default OrganizationItem;
