import { useHistory } from 'react-router-dom';

import {
  CloudinaryImg,
  Grid,
  GridItem,
  Link,
  LinkKind,
  LinkVariant,
  NoContent,
  PhosphorIcon,
  Text,
  TextKind,
  TextElement,
  Button,
  ButtonKind,
  IconDirection,
  IconName,
  ButtonIconPosition,
} from 'design-system/components';
import { Color } from 'design-system/data';

import { useApp } from 'context/AppContext';
import useAsyncCall from 'services/api/useAsyncCall';
import fetcher from 'services/api/fetcher';
import { useAnalyticsEvent } from 'services/analytics/AnalyticsContext';
import { PATHS } from 'constants/index';
import useUserFlow from 'hooks/useUserFlow';

import useRetailerSelection from './useRetailerSelection';
import useChargebeeBillingEntrypoint from './useChargebeeBillingEntrypoint';
import styles from './portalSelection.module.scss';

const PortalSelection = () => {
  const { user, setSelectedRetailerBrandId, refetchUser } = useApp();
  const history = useHistory();
  const { analyticsEvent } = useAnalyticsEvent();

  const { data, loading } = useRetailerSelection();
  const retailers = data?.retailers || [];
  // TODO ENG-4967: Remove when onboarding sephora.
  const flow = useUserFlow();
  const legacySephora = flow.legacy_sephora_flow;
  const sephoraID = 67;
  const disableSephora = (retailerId) => {
    return sephoraID === retailerId && legacySephora;
  };

  const trackPortalSelection = (selectedPortal, retailerId) => {
    analyticsEvent({
      label: 'portal_selection_page',
      category: 'select_portal_button',
      action: `click_enter_portal_button_${selectedPortal}`,
      entityId: { retailer_id: { value: retailerId } },
      selectedValue: selectedPortal,
    });
  };
  // END TODO

  const userOrganizationId = user?.organization.id;
  const accessibleRetailerConsumerBrandIds = data?.retailers
    ?.filter((retailer) => retailer.is_accessible)
    .map((retailer) => retailer.retailer_consumer_brand_id);

  useChargebeeBillingEntrypoint({
    userOrganizationId,
    accessibleRetailerConsumerBrandIds,
  });

  const onEnter = useAsyncCall(async (retailer) => {
    trackPortalSelection(retailer.name, retailer.id);
    const isNoviUser = user?.is_novi_email;
    const retailerConsumerBrandId = retailer.retailer_consumer_brand_id;
    const visit = await fetcher(
      `/api/v4/retailer_consumer_brands/${retailerConsumerBrandId}`
    );
    await fetcher(
      `/api/v4/retailer_consumer_brands/${retailerConsumerBrandId}/set_last_selected`,
      { method: 'post' }
    );
    await refetchUser({ soft: true });
    setSelectedRetailerBrandId(retailerConsumerBrandId);

    if (isNoviUser || !visit.previewed_at) {
      await fetcher(
        `/api/v4/retailer_consumer_brands/${retailerConsumerBrandId}/set_preview`,
        { method: 'post' }
      );
      return history.push(PATHS.retailerBrandWelcome);
    }
    return history.push(PATHS.retailerBrandDash);
  });

  const onMoCRAEnter = useAsyncCall(async () => {
    const retailerConsumerBrandId = '';
    trackPortalSelection('MoCRA', null);
    await fetcher(`/api/v4/retailer_consumer_brands/clear_last_selected`, {
      method: 'post',
    });
    await refetchUser({ soft: true });
    setSelectedRetailerBrandId(retailerConsumerBrandId);
    return history.push(PATHS.fdaRegistrationWelcome);
  });

  return (
    <div className={styles.container}>
      <Grid>
        <GridItem
          span={4}
          responsiveSpan={{ tablet: 6, desktop: 6 }}
          responsiveOffset={{ tablet: 2, desktop: 4 }}
        >
          <div className={styles.welcome}>
            <Text kind={TextKind.DisplayLG} element={TextElement.H1}>
              Welcome back
              {user?.organization?.name ? `, ${user?.organization?.name}` : ''}
            </Text>
            <Text kind={TextKind.TextMD} element={TextElement.P}>
              Select the portal you would like to work in today.
            </Text>
          </div>
        </GridItem>
      </Grid>
      <Grid>
        <GridItem
          span={4}
          responsiveSpan={{ tablet: 4, desktop: 4 }}
          responsiveOffset={{ tablet: 3, desktop: 5 }}
        >
          {/* Retailer section */}
          <div className={styles.retailers}>
            <div className={styles.retailer}>
              <Text kind={TextKind.TextMDBold} element={TextElement.P}>
                FDA Registration (MoCRA)
              </Text>
              <Button
                kind={ButtonKind.Alternate}
                onClick={() => onMoCRAEnter.call()}
                iconDirection={IconDirection.Right}
                iconName={IconName.ArrowV2}
                iconSize={20}
                iconColor={Color.Purple700}
                iconPosition={ButtonIconPosition.Right}
              >
                Enter
              </Button>
            </div>
            {loading && (
              <div className={styles.loading}>
                <NoContent loading />
              </div>
            )}
            {retailers.length > 0 &&
              retailers.map((retailer, i) => {
                const isEnterable =
                  !!retailer.retailer_consumer_brand_id &&
                  !!retailer.is_accessible;
                return (
                  <div className={styles.retailer} key={i}>
                    <div className={styles['retailer-logo']}>
                      <CloudinaryImg
                        src={retailer.image_path || ''}
                        alt={retailer.name}
                        fallbackElement={
                          <Text
                            kind={TextKind.TextMDBold}
                            element={TextElement.P}
                          >
                            {retailer.name}
                          </Text>
                        }
                      />
                    </div>
                    {isEnterable && (
                      <Button
                        key={retailer.id}
                        kind={ButtonKind.Alternate}
                        onClick={() => onEnter.call(retailer)}
                        iconDirection={IconDirection.Right}
                        iconName={IconName.ArrowV2}
                        iconSize={20}
                        iconColor={Color.Purple700}
                        iconPosition={ButtonIconPosition.Right}
                        // TODO ENG-4967: Remove when onboarding sephora. Prevent legacy planet delta sephora users from accessing
                        disabled={disableSephora(retailer.id)}
                      >
                        Enter
                        {/* TODO ENG-4967: Remove when onboarding sephora. */}
                        {disableSephora(retailer.id) && ' (Coming soon!)'}
                      </Button>
                    )}
                    {!isEnterable && (
                      <Link
                        key={retailer.id}
                        variant={LinkVariant.Alternate}
                        kind={LinkKind.External}
                        href={`mailto:support@noviconnect.com?subject=Request to join ${
                          retailer.name ? `${retailer.name} ` : ''
                        }retailer program!`}
                      >
                        <div>
                          Request to join
                          <span className={styles['icon-container']}>
                            <PhosphorIcon iconName="ArrowRight" size={18} />
                          </span>
                        </div>
                      </Link>
                    )}
                  </div>
                );
              })}
          </div>
          <div className={styles['workbench-link']}>
            <Link kind={LinkKind.Internal} to="/project/dashboard">
              <div>
                Go to your formulation workbench
                <span className={styles['icon-container']}>
                  <PhosphorIcon iconName="ArrowRight" size="1.5rem" />
                </span>
              </div>
            </Link>
          </div>
        </GridItem>
      </Grid>
    </div>
  );
};

export default PortalSelection;
