import cn from 'classnames';
import {
  Grid,
  GridItem,
  Link,
  LinkKind,
  LinkVariant,
  Text,
  TextKind,
  TextElement,
  PhosphorIcon,
} from 'design-system/components';
import { Color } from 'design-system/data';
import { useApp } from 'context/AppContext';
import styles from './retailer-brand-dashboard.module.scss';
import ContactSupportCard from './components/ContactSupportCard';
import StepCard from './components/StepCard';

const pricingRows = [
  { label: '1 SKU', price: '$495' },
  { label: '2-10 SKUs', price: '$2495' },
  { label: '11-25 SKUs', price: '$4995' },
  { label: '26-100 SKUs', price: '$7995' },
  { label: '101-500 SKUs', price: '$10,995' },
  { label: '501-1000 SKUs', price: '$19,995' },
];

const RetailerBrandDashboard = () => {
  const { user } = useApp();
  const orgName = user?.organization?.name;

  return (
    <Grid nestedColumns={{ mobile: 4, tablet: 6, desktop: 10 }}>
      <GridItem span={10} responsiveSpan={{ tablet: 10, desktop: 10 }}>
        <div className={styles['four-xl']}>
          <Text kind={TextKind.DisplaySM} element={TextElement.H1}>
            Welcome back{orgName ? `, ${orgName}` : ''}! Here&apos;s your MoCRA
            dashboard.
          </Text>
        </div>
      </GridItem>
      <GridItem span={10} responsiveSpan={{ tablet: 10, desktop: 5 }}>
        <div className={styles.pricing}>
          <div className={styles['title-wrapper']}>
            <Text kind={TextKind.Display2XS} element={TextElement.P}>
              Pricing
            </Text>
            <Text kind={TextKind.TextSM} element={TextElement.P}>
              Novi Connect's MoCRA product listings pricing is calculated by SKU
              count, listed below.
            </Text>
          </div>
          <div className={styles['pricing-rows']}>
            {pricingRows.map((row) => {
              return (
                <div className={styles['pricing-row']}>
                  <Text kind={TextKind.TextSM} element={TextElement.P}>
                    {row.label}
                  </Text>
                  <Text kind={TextKind.TextSMMedium} element={TextElement.P}>
                    {row.price}
                  </Text>
                </div>
              );
            })}
            <div className={styles['pricing-row']}>
              <Text kind={TextKind.TextSM} element={TextElement.P}>
                1001+ SKUs
              </Text>
              <Text kind={TextKind.TextXS} element={TextElement.P}>
                Please contact us at{' '}
                <Text
                  className={styles['contact-text']}
                  kind={TextKind.TextXSBold}
                  element={TextElement.Span}
                >
                  mocra@noviconnect.com
                </Text>
              </Text>
            </div>
          </div>
        </div>
      </GridItem>
      <GridItem span={10} responsiveSpan={{ tablet: 10, desktop: 5 }}>
        <ContactSupportCard />
      </GridItem>
      <GridItem span={10} responsiveSpan={{ tablet: 10, desktop: 10 }}>
        <div className={styles['steps-container']}>
          <Text
            kind={TextKind.DisplayXSMedium}
            element={TextElement.H3}
            className={styles.xl}
          >
            To list your cosmetic products with the FDA, start here
          </Text>
          <StepCard
            title="Check out MoCRA Wizard"
            description="Many regulations under the Modernization of Cosmetics Regulation Act (MoCRA) are new to cosmetic companies, such as facility registration, product listings, U.S. Agent requirements, and adverse event reporting. The MoCRA Wizard will help you determine what requirements impact your business."
            iconName="MagicWand"
            link={
              <Link
                href="https://www.registrarcorp.com/mocra-wizard-noviconnect/"
                kind={LinkKind.External}
                variant={LinkVariant.Primary}
              >
                Enter <PhosphorIcon iconName="ArrowRight" />
              </Link>
            }
          />
          <StepCard
            title="See your MoCRA Product Listing Checklist"
            description="Before starting the listing process, make sure to review the MoCRA Product Listing Checklist and have all items readily available."
            iconName="ClipboardText"
            link={
              <Link
                href="https://noviconnect.notion.site/Product-listing-checklist-559e37b7b379469eaba5e1896edc7f5e"
                kind={LinkKind.External}
                variant={LinkVariant.Primary}
              >
                Enter <PhosphorIcon iconName="ArrowRight" />
              </Link>
            }
          />
          <StepCard
            title="Complete your SKUs"
            description={
              <Text kind={TextKind.TextSM} element={TextElement.P}>
                Update your SKU details that are required for an FDA Cosmetic
                Listings here. Please note that products classified as drugs
                (details) do not qualify for cosmetics listing.{' '}
                <Link
                  style={{ color: Color.Purple700.value }}
                  href="https://noviconnect.notion.site/Products-considered-drugs-by-the-FDA-f7e7a40eed4a40d6bdc286011b574e34"
                  kind={LinkKind.External}
                >
                  Learn more
                </Link>
              </Text>
            }
            iconName="PencilSimple"
            link={
              <Link
                to="/fda-registration/product-registration"
                kind={LinkKind.Internal}
                variant={LinkVariant.Primary}
              >
                Complete <PhosphorIcon iconName="ArrowRight" />
              </Link>
            }
          />
        </div>
      </GridItem>
      <GridItem span={4} responsiveSpan={{ tablet: 6, desktop: 10 }}>
        <Text
          className={styles['padded-heading']}
          kind={TextKind.Display2XSMedium}
          element={TextElement.H3}
        >
          What Novi can do for you
        </Text>
        <div className={styles['dash-footer']}>
          <div className={cn([styles.panel])}>
            <div className={styles['three-xl']}>
              <Text kind={TextKind.Display2XS} element={TextElement.H3}>
                Explore exclusive retailer partnerships
              </Text>
            </div>
            <div className={styles.xl}>
              <Text kind={TextKind.TextSM} element={TextElement.P}>
                Curious about Novi Connect's exclusive retail program partners
                and free tools?
              </Text>
            </div>
            <Link
              kind={LinkKind.External}
              variant={LinkVariant.Primary}
              href="https://knowledgebase.noviconnect.com/mocra-support"
              target="_blank"
            >
              Get in touch <PhosphorIcon iconName="ArrowRight" />
            </Link>
          </div>

          <div className={cn([styles.panel])}>
            <div className={styles['three-xl']}>
              <Text kind={TextKind.Display2XS} element={TextElement.H3}>
                Discover more on Novi
              </Text>
            </div>
            <div className={styles.md}>
              <Link
                kind={LinkKind.Internal}
                variant={LinkVariant.NewDefault}
                to="/brand/INCI-screening"
              >
                Test compliance against other industry standards
              </Link>
            </div>
            <div className={styles.md}>
              <Link
                kind={LinkKind.Internal}
                variant={LinkVariant.NewDefault}
                to="/brand/discover?category_key=ingredient"
              >
                Discover and source more sustainable ingredients
              </Link>
            </div>
          </div>
        </div>
      </GridItem>
    </Grid>
  );
};

export default RetailerBrandDashboard;
