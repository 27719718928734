import { useParams } from 'react-router-dom';

import { useApp } from 'context/AppContext';
import { PATHS } from 'constants/index';
import SKURequirementsPageView from 'views/Brands/RetailerBrandEligibility/SKURequirementsPage/view';
import { RequirementActionsProvider } from 'views/Brands/shared/RequirementActionModalContents/context';
import useBrand from '../SKUs/hooks/useBrand';

const RetailerBrandSubmissionSKUReview = () => {
  const { brand_id, policy_id } = useParams();
  const { user } = useApp();
  const brandResponse: any = useBrand(brand_id);
  const brandName = brandResponse?.data?.name;
  const retailerId = user?.organization?.in_orgable_id;
  const breadcrumbBase = [
    {
      text: 'Submissions',
      link: PATHS.retailerBrandsSubmissions,
    },
    {
      text: brandName || 'Brand',
      link: PATHS.retailerBrandSubmissionSKUs
        .replace(':policy_id', policy_id)
        .replace(':brand_id', brand_id),
    },
  ];

  return (
    <RequirementActionsProvider>
      <SKURequirementsPageView
        brandId={brand_id}
        retailerId={retailerId}
        isRetailer
        breadcrumbBase={breadcrumbBase}
      />
    </RequirementActionsProvider>
  );
};

export default RetailerBrandSubmissionSKUReview;
