import { PLACEHOLDER } from 'design-system/data';
import { DualNavDataReturnData, SecondaryNavDataType } from './types';

const defaultSecondaryNavData: SecondaryNavDataType = {
  header: {
    logo: {
      src: PLACEHOLDER,
      alt: PLACEHOLDER,
      fallback: PLACEHOLDER,
    },
    items: [
      {
        id: 'default-item',
        content: PLACEHOLDER,
      },
    ],
  },
  main: {
    title: PLACEHOLDER,
    items: [
      {
        id: 'default-item',
        content: PLACEHOLDER,
      },
    ],
  },
  footer: {
    sections: [
      {
        id: 'default',
        title: PLACEHOLDER,
        items: [
          {
            id: 'default-item',
            content: PLACEHOLDER,
          },
        ],
      },
    ],
    userInfo: {
      username: PLACEHOLDER,
      email: PLACEHOLDER,
      onLogout: () => {},
    },
  },
};

export const DualSidenavData: DualNavDataReturnData = {
  userInfo: {
    currentOrg: '',
    orgType: '',
    username: '',
    userRoles: [],
    onRoleSwitch: () => Promise.resolve(),
    selectedRoleId: undefined,
    onLogout: () => {},
  },
  primaryNavData: [],
  secondaryNavData: defaultSecondaryNavData,
  activeTab: null,
  toastContextData: {
    toastOpen: false,
    setToastOpen: () => {},
    toastData: [],
  },
};
