import { useMemo } from 'react';
import {
  SecondaryNavDataType,
  SecondaryNavUserInfo,
} from 'design-system/components';
import { PATHS } from 'constants/index';
interface UseSecondaryNavSettingsProps {
  clickWrapper: (params: { action: string }) => void;
  userInfo: SecondaryNavUserInfo;
  handleBillingInfo?: { call: () => void; loading: boolean };
}

export const useSecondaryNavSettings = ({
  clickWrapper,
  userInfo,
  handleBillingInfo,
}: UseSecondaryNavSettingsProps): SecondaryNavDataType => {
  return useMemo(
    () => ({
      main: {
        title: 'Settings',
        items: [
          {
            id: 'brand-profile',
            content: 'Brand Profile',
            href: PATHS.brandProfileSettings,
            onClick: () => clickWrapper({ action: 'click_menu_brand_profile' }),
          },
          {
            id: 'user-preferences',
            content: 'User Preferences',
            href: PATHS.userSettings,
            onClick: () =>
              clickWrapper({ action: 'click_menu_user_preferences' }),
          },
          ...(handleBillingInfo
            ? [
                {
                  id: 'billing-information',
                  content: 'Billing Information',
                  onClick: () => {
                    clickWrapper({ action: 'click_menu_billing_information' });
                    handleBillingInfo.call();
                  },
                  loading: handleBillingInfo.loading,
                },
              ]
            : []),
        ],
      },
      footer: {
        sections: [],
        userInfo,
      },
    }),
    [clickWrapper, handleBillingInfo, userInfo]
  );
};
