import { useState, useMemo, useEffect } from 'react';
import { PATHS } from 'constants/index';
import {
  CloudinaryImg,
  Link,
  LinkKind,
  LinkVariant,
  TextKind,
  Text,
  TextElement,
  PhosphorIcon,
} from 'design-system/components';
import { Color } from 'design-system/data';
import styles from './program-badge-card.module.scss';
import { ProgramBadgeType } from '../types';
import withLineBreaks from 'utils/withLineBreaks';

type ProgramBadgeCardProps = Omit<ProgramBadgeType, 'id'> & {
  openOverride?: boolean;
};

const ProgramBadgeCard = ({
  name,
  description,
  howYouGetIt,
  whatYouGet,
  imagePath,
  policyType,
  policyId,
  openOverride = false,
}: ProgramBadgeCardProps) => {
  const href =
    policyType === 'Retailer::Policy'
      ? PATHS.retailerBrandRequirementGroups
      : PATHS.retailerBrandRequirementGroupsDetail.replace(
          ':requirement_group_id',
          `${policyId}`
        );

  const image = useMemo(() => {
    return (
      <CloudinaryImg
        width={80}
        src={imagePath}
        alt={`Program badge ${name} icon`}
        fallbackElement={<PhosphorIcon iconName="ShieldCheck" size={48} />}
      />
    );
  }, [imagePath, name]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.image}>{image}</div>
        <div className={styles.pill}>
          <Text
            element={TextElement.P}
            kind={TextKind.TextSMMedium}
            color={Color.Neutral700}
          >
            {name}
          </Text>
        </div>
        <Text
          element={TextElement.P}
          kind={TextKind.TextSM}
          color={Color.Neutral500}
        >
          {description}
        </Text>
      </div>
      <div className={styles.content}>
        <ExpandableSection
          title="What you get"
          content={whatYouGet}
          openOverride={openOverride}
        />
        <ExpandableSection
          title="How you get it"
          content={howYouGetIt}
          openOverride={openOverride}
        />
      </div>
      <Link
        className={styles['review-link']}
        to={href}
        kind={LinkKind.Internal}
        variant={LinkVariant.Primary}
      >
        Review
        <PhosphorIcon iconName={'ArrowRight'} />
      </Link>
    </div>
  );
};

export default ProgramBadgeCard;

const ExpandableSection = ({
  title,
  content,
  openOverride,
}: {
  title: string;
  content: string;
  openOverride: boolean;
}) => {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    setExpanded(openOverride);
  }, [openOverride]);

  return (
    <div className={styles['expandable-section-container']}>
      <div
        tabIndex={0}
        className={styles['expandable-section-head']}
        onClick={() => setExpanded(!expanded)}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            setExpanded(!expanded);
          }
        }}
      >
        <Text
          element={TextElement.P}
          kind={TextKind.TextMDMedium}
          color={Color.Neutral700}
        >
          {title}
        </Text>
        <PhosphorIcon iconName={expanded ? 'Minus' : 'Plus'} />
      </div>
      {expanded && (
        <Text
          element={TextElement.P}
          kind={TextKind.TextMD}
          color={Color.Neutral600}
        >
          {withLineBreaks(content)}
        </Text>
      )}
    </div>
  );
};
