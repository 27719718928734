import {
  Button,
  ButtonKind,
  CloudinaryImg,
  Link,
  LinkKind,
  LinkVariant,
  PhosphorIcon,
  Text,
  TextElement,
  TextKind,
} from 'design-system/components';
import { Color } from 'design-system/data';
import {
  HOW_TO_ENROLL_ROWS,
  RETAILER_KEYS,
  IMPORTANT_DATES_SUBTITLE,
} from '../constants';
import { useApp } from 'context/AppContext';
import styles from './program-details.module.scss';
import { normalizeSkusCount } from 'utils/normalizeSkusCount';
import { useChargebeeBillingInfo } from 'utils/chargebee';
import { isNotInUltaPortal } from 'utils/hardcodedNonsense';
import { SubmissionDate, ProgramCost } from '../types';
import { useMemo } from 'react';
import { formatDateV2 } from 'services/api/date';

const ProgramDetails = ({
  programName,
  submissionDates,
  programCosts,
}: {
  programName: string;
  submissionDates: SubmissionDate[];
  programCosts: ProgramCost[];
}) => {
  const { retailerBrand } = useApp();

  const retailerBrandName = retailerBrand?.retailer?.name;
  const isTarget = retailerBrandName?.includes('Target');
  const isMacys = retailerBrandName?.includes('Macy');
  const isSingleDateDisplay = submissionDates.length === 1;
  const billingInfoHook = useChargebeeBillingInfo(retailerBrand?.id);
  const notInUltaPortal = isNotInUltaPortal(retailerBrand?.retailer?.id);

  // This is for when we expand this to other retailers
  const determinedRetailer = useMemo(() => {
    if (isTarget) return RETAILER_KEYS.TARGET;
    if (!notInUltaPortal) return RETAILER_KEYS.ULTA;
    if (isMacys) return RETAILER_KEYS.MACYS;

    return RETAILER_KEYS.OTHER;
  }, [isTarget, notInUltaPortal, isMacys]);

  return (
    <div>
      <Text
        className={styles['section-heading']}
        element={TextElement.H2}
        kind={TextKind.Display2XSMedium}
        color={Color.Neutral900}
      >
        Program details
      </Text>
      <div className={styles.section}>
        <div className={styles['enrollment-container']}>
          <div className={styles.header}>
            <Text
              element={TextElement.H3}
              kind={TextKind.Display2XS}
              color={Color.Neutral900}
              className={styles['enrollment-title']}
            >
              How to enroll
            </Text>
            <Text
              element={TextElement.P}
              kind={TextKind.TextSM}
              color={Color.Neutral500}
            >
              Here’s what you’ll need to enroll in this program
            </Text>
          </div>
          {HOW_TO_ENROLL_ROWS.map((row) => {
            if (!row.show(determinedRetailer)) return null;
            return (
              <div key={row.title} className={styles['enrollment-row']}>
                <div className={styles['icon-box']}>
                  <PhosphorIcon
                    iconName={row.phosphorIcon}
                    size={24}
                    color={Color.Neutral600.value}
                  />
                </div>
                <div className={styles['row-content']}>
                  <Text
                    element={TextElement.P}
                    kind={TextKind.TextMDSemibold}
                    color={Color.Neutral700}
                  >
                    {row.title}
                  </Text>
                  <Text
                    element={TextElement.P}
                    kind={TextKind.TextMD}
                    color={Color.Neutral600}
                  >
                    {row.description(determinedRetailer)}
                  </Text>
                </div>
                {row.payment ? (
                  <Button
                    className={styles.link}
                    kind={ButtonKind.Alternate}
                    onClick={() => billingInfoHook.call()}
                  >
                    <PhosphorIcon iconName="ArrowRight" />
                  </Button>
                ) : (
                  <Link
                    className={styles.link}
                    variant={LinkVariant.Alternate}
                    kind={LinkKind.Internal}
                    to={row.href || '#'}
                  >
                    <PhosphorIcon iconName="ArrowRight" />
                  </Link>
                )}
              </div>
            );
          })}
        </div>
        <div className={styles['dates-and-costs-container']}>
          <div className={styles['dates-and-costs-row']}>
            <div className={styles.header}>
              <Text
                element={TextElement.H3}
                kind={TextKind.Display2XS}
                color={Color.Neutral900}
                className={styles['enrollment-title']}
              >
                Important dates
              </Text>
              <Text
                element={TextElement.P}
                kind={TextKind.TextSM}
                color={Color.Neutral500}
              >
                {IMPORTANT_DATES_SUBTITLE[determinedRetailer]}
              </Text>
            </div>
            {isSingleDateDisplay ? (
              <SingleDateContentDisplay submissionDate={submissionDates[0]} />
            ) : (
              <MultiDateContentDisplay submissionDates={submissionDates} />
            )}
          </div>
          {isSingleDateDisplay && (
            <CostsDisplay
              isTarget={isTarget}
              isMacys={isMacys}
              isSingleDateDisplay={isSingleDateDisplay}
              programName={programName}
              programCosts={programCosts}
            />
          )}
        </div>
        {!isSingleDateDisplay && (
          <CostsDisplay
            isTarget={isTarget}
            isMacys={isMacys}
            programName={programName}
            programCosts={programCosts}
          />
        )}
      </div>
    </div>
  );
};

export default ProgramDetails;

const MultiDateContentDisplay = ({
  submissionDates,
}: {
  submissionDates: SubmissionDate[];
}) => {
  return (
    <div className={styles['multi-date-content-container']}>
      {submissionDates.map((submissionDate) => {
        return (
          <div
            key={submissionDate.name}
            className={styles['multi-date-content']}
          >
            <div className={styles['icon-box']}>
              {submissionDate.phosphorIconName ? (
                <PhosphorIcon
                  iconName={submissionDate.phosphorIconName}
                  size={24}
                />
              ) : (
                <CloudinaryImg
                  src={submissionDate.imagePath}
                  size={24}
                  alt={`Submission date ${submissionDate.name} icon`}
                  fallbackElement={
                    <PhosphorIcon
                      iconName="Calendar"
                      size={24}
                      color={Color.Neutral600.value}
                    />
                  }
                />
              )}
            </div>
            <div>
              <Text
                element={TextElement.P}
                kind={TextKind.TextMDSemibold}
                color={Color.Neutral700}
              >
                {submissionDate.name}
              </Text>
              <Text
                element={TextElement.P}
                kind={TextKind.TextMD}
                color={Color.Neutral600}
              >
                {formatDateV2(submissionDate.dueDate, false, '/')}
              </Text>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const SingleDateContentDisplay = ({
  submissionDate,
}: {
  submissionDate: SubmissionDate;
}) => {
  return (
    <div className={styles['single-date-content-container']}>
      <div className={styles['single-date-content']}>
        <div className={styles['icon-box']}>
          {submissionDate.phosphorIconName ? (
            <PhosphorIcon
              iconName={submissionDate.phosphorIconName}
              size={24}
            />
          ) : (
            <CloudinaryImg
              src={submissionDate.imagePath}
              size={24}
              alt={`Submission date ${submissionDate.name} icon`}
              fallbackElement={
                <PhosphorIcon
                  iconName="Calendar"
                  size={24}
                  color={Color.Neutral600.value}
                />
              }
            />
          )}
        </div>
        <Text
          element={TextElement.P}
          kind={TextKind.TextMDSemibold}
          color={Color.Neutral700}
        >
          {submissionDate.name}
        </Text>
      </div>
      <Text
        element={TextElement.P}
        kind={TextKind.TextMD}
        color={Color.Neutral600}
      >
        {formatDateV2(submissionDate.dueDate, false, '/')}
      </Text>
    </div>
  );
};

const CostsDisplay = ({
  isTarget,
  isSingleDateDisplay,
  programName,
  programCosts,
  isMacys,
}: {
  isTarget: boolean;
  isSingleDateDisplay?: boolean;
  programName: string;
  programCosts: ProgramCost[];
  isMacys: boolean;
}) => {
  return (
    <div
      className={
        isSingleDateDisplay
          ? styles['dates-and-costs-row']
          : styles['program-cost-wrapper']
      }
    >
      <div className={styles.header}>
        <Text
          element={TextElement.H3}
          kind={TextKind.Display2XS}
          color={Color.Neutral900}
          className={styles['enrollment-title']}
        >
          Program costs
        </Text>
        {!isTarget && (
          <Text
            element={TextElement.P}
            kind={TextKind.TextSM}
            color={Color.Neutral500}
          >
            {isMacys
              ? "Macy's Conscious Beauty and E-Commerce Attributes program pricing are calculated by product count."
              : `${programName} program pricing is calculated by product count.`}
          </Text>
        )}
      </div>
      {!isTarget && (
        <div className={styles['program-costs-container']}>
          {programCosts.map((programCost) => {
            return (
              <div key={programCost.id} className={styles['program-cost']}>
                <Text
                  element={TextElement.P}
                  kind={TextKind.TextSM}
                  color={Color.Neutral900}
                >
                  {normalizeSkusCount(
                    programCost.productCountMin,
                    programCost.productCountMax
                  )}
                </Text>
                <Text
                  element={TextElement.P}
                  kind={TextKind.TextSMSemibold}
                  color={Color.Neutral900}
                >
                  ${programCost.price}/year
                </Text>
              </div>
            );
          })}
        </div>
      )}
      {isTarget && (
        <Text
          element={TextElement.P}
          kind={TextKind.TextSM}
          color={Color.Neutral500}
        >
          As part of its commitment to sustainability, Target Zero will cover
          the costs of Novi during Year One for participating brands.
        </Text>
      )}
    </div>
  );
};
