import { useState, useCallback, useMemo, useEffect } from 'react';
import {
  BrandStatusDisplayData,
  SecondaryNavDataType,
  SecondaryNavSection,
  SecondaryNavUserInfo,
} from 'design-system/components';
import { RetailerData } from './useBrandNav';
import SubscriptionSection, {
  SubscriptionSectionProps,
} from '../../components/SubscriptionSection';
import { SubscriptionTier } from 'hooks/useSubscriptionTiers';
import ProgramsBackButton from '../../components/ProgramsBackButton';
import { useApp } from 'context/AppContext';
import { RETAILER_BRAND_PATH } from 'constants/index';
import { hasChargebeePaywall } from 'utils/hardcodedNonsense';
import { useChargebeeCheckout } from 'utils/chargebee';

interface UseSecondaryNavWorkbenchProps {
  clickWrapper: (params: { action: string }) => void;
  userInfo: SecondaryNavUserInfo;
  onBackToPrograms: () => void;
  inviteModalData: SecondaryNavSection;
}

export const useSecondaryNavRetailerBrand = ({
  clickWrapper,
  userInfo,
  onBackToPrograms,
  inviteModalData,
}: UseSecondaryNavWorkbenchProps): SecondaryNavDataType => {
  // Hook declarations
  const {
    user,
    retailerBrand: lastSelectedRetailerBrand,
    selectedRetailerBrandId,
    subscriptionTiers,
  } = useApp();
  const checkoutHook = useChargebeeCheckout(lastSelectedRetailerBrand?.id);

  // State declarations
  const [subscriptionTierDescription, setSubscriptionTierDescription] =
    useState<string>('');

  // Constants and computed values
  const base = RETAILER_BRAND_PATH;
  const lastSelectedRetailer = lastSelectedRetailerBrand?.retailer;
  const { unlocked } = lastSelectedRetailerBrand || {};
  const hasUnpaidSubscriptions = !unlocked;
  const statusData = hasUnpaidSubscriptions
    ? BrandStatusDisplayData.Unpaid
    : BrandStatusDisplayData.Paid;
  const selectedRetailer: RetailerData = lastSelectedRetailer;
  const supportEmail =
    selectedRetailer?.support_email || 'support@noviconnect.com';
  const hasProgramsOverviewPage = user?.hasFF('tmp_program_overview_page');
  const hasUltaPaywallFlag = user?.hasFF('tmp_ulta_paywall');
  const computedHasChargebeePaywall = hasChargebeePaywall(
    lastSelectedRetailerBrand?.retailer?.id,
    hasUltaPaywallFlag
  );
  const handleCheckout = computedHasChargebeePaywall ? checkoutHook : null;

  // Callback declarations
  const generateHref = useCallback((path: string) => `${base}/${path}`, [base]);

  // Memoized values
  const homeUrls = useMemo(() => {
    return [
      {
        text: 'Dashboard',
        href: generateHref('dashboard'),
      },
      {
        text: 'SKUs',
        href: generateHref('skus'),
      },
      {
        text: 'Programs',
        hide: !hasProgramsOverviewPage,
        href: generateHref('programs'),
      },
      {
        text: 'Pillars',
        href: generateHref('requirement_groups'),
      },
      {
        text: 'Eligibility',
        href: generateHref('eligibility'),
      },
      {
        text: 'Submissions',
        href: generateHref('submissions'),
      },
      {
        text: 'Documents',
        href: generateHref('documents'),
      },
    ].filter((homeUrl) => !homeUrl.hide);
  }, [generateHref, hasProgramsOverviewPage]);

  const subscriptionProps: SubscriptionSectionProps = useMemo(
    () => ({
      statusData,
      description: subscriptionTierDescription,
      unpaidSubscriptions: hasUnpaidSubscriptions,
      handleCheckout: handleCheckout || { call: () => {}, loading: false },
      invoiceLink: lastSelectedRetailerBrand?.stripe_invoice_id,
      displayRequestToPay: !unlocked,
      supportEmail,
    }),
    [
      statusData,
      subscriptionTierDescription,
      hasUnpaidSubscriptions,
      handleCheckout,
      lastSelectedRetailerBrand?.stripe_invoice_id,
      unlocked,
      supportEmail,
    ]
  );

  // Effects
  useEffect(() => {
    const subTier = subscriptionTiers?.find(
      (subscriptionTier: SubscriptionTier) =>
        subscriptionTier.id === lastSelectedRetailerBrand?.current_tier_id
    );
    if (subTier) {
      setSubscriptionTierDescription(subTier.description);
    }
  }, [subscriptionTiers, lastSelectedRetailerBrand?.current_tier_id]);

  // Return memoized nav data
  return useMemo(
    () => ({
      header: {
        eyebrow: {
          node: <ProgramsBackButton onBackToPrograms={onBackToPrograms} />,
        },
        logo: {
          id: 'logo',
          src: selectedRetailer?.image_path,
          alt: selectedRetailer?.name,
          fallback: selectedRetailer?.name,
        },
        items: homeUrls.map((url, index) => ({
          id: `home-${index}`,
          content: url.text,
          href: url.href,
          onClick: () =>
            clickWrapper({ action: `click_menu_${url.text.toLowerCase()}` }),
        })),
      },
      footer: {
        sections: [
          ...(selectedRetailerBrandId
            ? [
                {
                  id: 'subscription',
                  node: <SubscriptionSection {...subscriptionProps} />,
                },
              ]
            : []),
          inviteModalData,
        ],
        userInfo,
      },
    }),
    [
      clickWrapper,
      homeUrls,
      selectedRetailer,
      selectedRetailerBrandId,
      subscriptionProps,
      userInfo,
      onBackToPrograms,
      inviteModalData,
    ]
  );
};
