import React, {
  useState,
  useEffect,
  useRef,
  KeyboardEvent,
  ChangeEvent,
  MouseEvent,
} from 'react';
import styles from './pill-textarea.module.scss';
import { getTextWidthFromInput } from './utils';

interface PillTextAreaProps {
  id: string;
  label: string;
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
  required?: boolean;
}

export const PillTextArea: React.FC<PillTextAreaProps> = ({
  id,
  label,
  placeholder,
  value: _value,
  onChange: _onChange,
  required = false,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const pillRefs = useRef<(HTMLLIElement | null)[]>([]);

  const value = _value ?? '';
  const pills = value.split(',').filter((pill) => pill.trim() !== '');
  const inputValue = value.endsWith(',') ? '' : pills.pop() || '';

  const [inputWidth, setInputWidth] = useState<number>(0);

  const onChange = (newValue: string) => {
    _onChange(newValue);
  };
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    onChange(pills.length > 0 ? `${pills.join(',')},${newValue}` : newValue);
  };

  const focusInput = () => {
    inputRef.current?.focus();
  };

  const focusPill = (index: number) => {
    if (index >= 0 && index < pills.length) {
      pillRefs.current[index]?.focus();
    }
  };

  const removePill = (index: number) => {
    const newPills = [...pills.slice(0, index), ...pills.slice(index + 1)];
    onChange(newPills.join(',') + (newPills.length > 0 ? ',' : ''));

    if (index === newPills.length) {
      focusInput();
    } else if (index === 0 && newPills.length > 0) {
      focusPill(index);
    } else if (index > 0) {
      focusPill(Math.min(newPills.length, index));
    } else {
      focusInput();
    }
  };

  const addPill = () => {
    // Add a comma to the end of the input if there's none
    onChange(value.endsWith(',') ? value : `${value},`);
  };

  const navigateLeft = (currentIndex: number | null) => {
    if (currentIndex === null && pills.length > 0) {
      focusPill(pills.length - 1);
    } else if (currentIndex !== null && currentIndex > 0) {
      focusPill(currentIndex - 1);
    }
  };

  const navigateRight = (currentIndex: number | null) => {
    if (currentIndex === null) {
      return;
    } else if (currentIndex < pills.length - 1) {
      focusPill(currentIndex + 1);
    } else {
      focusInput();
    }
  };

  const handleInputKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || event.key === ',') {
      event.preventDefault();
      addPill();
    } else if (
      event.key === 'Backspace' &&
      inputValue === '' &&
      pills.length > 0
    ) {
      event.preventDefault();
      removePill(pills.length - 1);
    } else if (event.key === 'ArrowLeft' && inputValue === '') {
      event.preventDefault();
      navigateLeft(null);
    }
  };

  const handlePillKeyDown = (
    event: KeyboardEvent<HTMLLIElement>,
    index: number
  ) => {
    if (event.key === 'Backspace' || event.key === 'Delete') {
      event.preventDefault();
      removePill(index);
    } else if (event.key === 'ArrowRight') {
      event.preventDefault();
      navigateRight(index);
    } else if (event.key === 'ArrowLeft') {
      event.preventDefault();
      navigateLeft(index);
    }
  };

  const handleContainerClick = (event: MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      focusInput();
    }
  };

  // Calculate the width of the input field dynamically.
  useEffect(() => {
    if (inputRef.current) {
      const currentInputValue =
        inputValue !== ''
          ? inputValue
          : value === '' && placeholder !== undefined
          ? placeholder
          : '';
      const width =
        currentInputValue === ''
          ? 20 // Minimum width in pixels
          : getTextWidthFromInput(inputRef.current, currentInputValue);
      setInputWidth(width);
    }
  }, [inputRef, value, inputValue, placeholder]);

  return (
    <div className={styles['pill-text-area']} onClick={handleContainerClick}>
      <label htmlFor={id} className={styles['visually-hidden']}>
        {label}
      </label>
      <ul className={styles['pills-container']} aria-label={`${label} pills`}>
        {pills.map((pill, index) => (
          <li
            key={index}
            ref={(el) => (pillRefs.current[index] = el)}
            tabIndex={0}
            className={styles.pill}
            onKeyDown={(e) => handlePillKeyDown(e, index)}
          >
            {pill}
            <span
              role="button"
              onClick={() => removePill(index)}
              aria-label={`Remove ${pill}`}
              className={styles['pill-delete']}
            >
              ×
            </span>
          </li>
        ))}
        <li className={styles['input-container']}>
          <input
            id={id}
            ref={inputRef}
            className={styles.input}
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleInputKeyDown}
            placeholder={value === '' ? placeholder : undefined}
            aria-describedby={`${id}-instructions`}
            required={required}
            style={{ width: `${inputWidth + 10}px` }}
          />
        </li>
      </ul>
      <p id={`${id}-instructions`} className={styles['visually-hidden']}>
        Enter text and press Enter or comma to add a new pill. Use arrow keys to
        navigate between pills. Press Backspace to remove a pill.
      </p>
    </div>
  );
};

export default PillTextArea;
