import { RequirementActionsProvider } from 'views/Brands/shared/RequirementActionModalContents/context';
import PolicyRequirementsView from './view';

const PolicyRequirements = () => {
  return (
    <RequirementActionsProvider>
      <PolicyRequirementsView />
    </RequirementActionsProvider>
  );
};

export default PolicyRequirements;
