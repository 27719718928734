import {
  Text,
  TextElement,
  TextKind,
  Pill,
  PillColorNames,
} from 'design-system/components';
import styles from './pageHeader.module.scss';
import { Color } from 'design-system/data';
import { ReactNode } from 'react';

interface PageHeaderProps {
  breadcrumbs: ReactNode; // TODO: Just make this an array and add breadcrumb component wrapper here
  title: string;
  subtitle?: string | ReactNode;
  actions?: ReactNode;
  pillText?: string;
  pillColor?: PillColorNames;
}

const PageHeader = ({
  breadcrumbs,
  title,
  subtitle,
  actions,
  pillText,
  pillColor,
}: PageHeaderProps) => {
  return (
    <div className={styles.root}>
      {breadcrumbs}
      <div className={styles.content}>
        <div>
          <div className={styles.title}>
            <Text kind={TextKind.DisplaySMMedium} element={TextElement.H1}>
              {title}
            </Text>
            {pillText && <Pill color={pillColor}>{pillText}</Pill>}
          </div>
          <Text
            kind={TextKind.TextMD}
            element={TextElement.P}
            color={Color.Neutral600}
            className={styles.subtitle}
          >
            {subtitle}
          </Text>
        </div>
        {actions}
      </div>
    </div>
  );
};

export default PageHeader;
