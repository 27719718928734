// Home for hard-coded nonsense. Devs should not add anything here unless there is a very clear trade-off being made.
// Anything here should have a known and noted shelf life and be cleaned up as soon as possible.

// Expected shelf-life: February 2025 when Ulta is to migrate to chargebee payment systems.
// Can delete when 'tmp_ulta_paywall' flag is removed / Ulta is migrated to chargebee.
export const hasChargebeePaywall = (
  retailerId: number,
  hasUltaPaywallFlag: boolean
) => {
  const isInUltaPortal = retailerId === 232;
  return !isInUltaPortal || hasUltaPaywallFlag;
};

export const isNotInUltaPortal = (retailerId: number) => {
  const isInUltaPortal = retailerId === 232;
  return !isInUltaPortal;
};
