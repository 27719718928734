import { RequirementActionsProvider } from 'views/Brands/shared/RequirementActionModalContents/context';
import RequirementSummaryView from './view';

function RetailerBrandRequirementSummary() {
  return (
    <RequirementActionsProvider>
      <RequirementSummaryView />
    </RequirementActionsProvider>
  );
}

export default RetailerBrandRequirementSummary;
