export const NavigationTabs = {
  HOME: 'Novi Home',
  PROGRAMS: 'Your Programs',
  WORKBENCH: 'Novi Tools',
  SETTINGS: 'Settings',
  AVATAR: 'Avatar',
} as const;

export type NavigationTab =
  (typeof NavigationTabs)[keyof typeof NavigationTabs];

// TODO: Eventual refactor may be able to leverage a more sophistacated config.
// interface TabConfig {
//   id: NavigationTab;
//   paths: string[];
//   defaultView: 'programs' | 'retailerBrand' | 'workbench';
//   allowOverride?: boolean;
// }

// export const TAB_CONFIG: TabConfig[] = [
//   {
//     id: NavigationTabs.WORKBENCH,
//     paths: ['/project/', '/brand/'],
//     defaultView: 'workbench',
//     allowOverride: false,
//   },
//   {
//     id: NavigationTabs.PROGRAMS,
//     paths: ['/retailer-brand'],
//     defaultView: 'retailerBrand',
//     allowOverride: true, // Allows Programs view override
//   },
//   {
//     id: NavigationTabs.HOME,
//     paths: ['/', '/portal/selection'],
//     defaultView: 'programs',
//     allowOverride: false,
//   },
// ];
