import {
  createContext,
  useContext,
  useMemo,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { useHistory } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';

import {
  Button,
  ButtonKind,
  InputV2,
  Text,
  TextKind,
  TextElement,
} from 'design-system/components';
import { convertSnakeToCamelCase } from 'design-system/utils/case';
import { UrlParam } from 'design-system/data';

import { PATHS } from 'constants/index';
import { useApp } from 'context/AppContext';
import { useLoading } from 'context/Loading';
import fetcher from 'services/api/fetcher';
import useQueryParams from 'hooks/useQueryParams';
import Dialog from 'components/Dialog';
import { usePaywallContext } from './PaywallContext';
import styles from './edit-dialog.module.scss';
import { validateUpc } from 'utils/validateUpc';

const YourProductsContext = createContext(null);

export const useYourProductContext = () => {
  const ctx = useContext(YourProductsContext);
  if (!ctx) {
    throw new Error('Must be used with a YourProductsContext');
  }
  return ctx;
};

function YourProductsProvider({ children }) {
  const {
    user,
    useApi,
    selectedRetailerId: retailerId,
    hasRetailerBrandResponseFinished,
    retailerBrand,
  } = useApp();
  const history = useHistory();

  const brandID = user.organization.in_orgable_id;
  const params = useQueryParams(Object.values(UrlParam));
  const [tableCounts, setTableCounts] = useState({});
  const [loading, setLoading] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [errors, setErrors] = useState({
    product_name: '',
    sku_id: '',
    upc_number: '',
  });

  const [editProductInformation, setEditProductInformation] = useState({
    id: '',
    isBrandUploadedProduct: false,
    name: '',
    skuId: '',
    upcNumber: '',
  });

  const clearEditProductInformation = () => {
    setEditProductInformation({
      id: '',
      isBrandUploadedProduct: false,
      name: '',
      skuId: '',
      upcNumber: '',
    });
  };

  const buildPayloadFromForm = (formData) => {
    let payload = {};
    payload['product_name'] = formData.get('product_name');
    payload['sku_id'] = formData.get('sku_id');
    payload['upc_number'] = formData.get('upc_number');
    return payload;
  };

  const hasErrors = Object.values(errors).some((errorMsg) => errorMsg);

  const validateUpcNumber = (value) => {
    if (!value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        upc_number: '',
      }));

      return;
    }

    const errorMessage = validateUpc(value)
      ? ''
      : 'Please enter a valid UPC or none';

    setErrors((prevErrors) => ({
      ...prevErrors,
      upc_number: errorMessage,
    }));
  };

  const validateSku = (value) => {
    let errorMessage = '';
    if (value.length > 60) {
      errorMessage = 'SKU number cannot exceed 60 characters';
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      sku_id: errorMessage,
    }));
  };

  const validateProductName = (value) => {
    let errorMessage = '';
    if (!value.length) {
      errorMessage = 'The product name field is required';
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      product_name: errorMessage,
    }));
  };

  const { bulk_upload_included } = retailerBrand || {};
  const { openPaywall } = usePaywallContext();

  const enabled = useMemo(() => {
    if (!hasRetailerBrandResponseFinished) return false;
    return Boolean(brandID && retailerId);
  }, [brandID, retailerId, hasRetailerBrandResponseFinished]);

  // migrate to only use endpoint with retailerId
  const consumerProductsEndpoint = `api/v4/retailers/${retailerId}/brands/${brandID}/consumer_products`;

  const products = useApi(consumerProductsEndpoint, {
    enabled,
    param: {
      ...params._asValues(),
      with_policy_results: false,
    },
  });

  useEffect(() => {
    if (products.data?.consumer_product_type_counts) {
      setTableCounts({
        all: products.data.consumer_product_type_counts.total,
        brand_uploaded:
          products.data.consumer_product_type_counts.brand_uploaded,
        retailer_ingested:
          products.data.consumer_product_type_counts.retailer_ingested,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products.data?.consumer_product_type_counts?.total]); // Lets only reset these when the counts change, not every change of products (switching tabs)

  // PDCT-921: Remove paywall for now. May be added back in future in with Chargebee integration.
  // const shouldOpenPaywall = useMemo(() => {
  //   if (
  //     user.hasFF('upload_skus') &&
  //     enrollment_type === 'brand subscription' &&
  //     (!unlocked ||
  //       (max_product_count && tableCounts?.all >= max_product_count))
  //   ) {
  //     return true;
  //   }
  //   return false;
  // }, [enrollment_type, unlocked, max_product_count, user, tableCounts?.all]);
  const shouldOpenPaywall = false;

  const deleteProduct = useCallback(
    async (id, isBrandUploadedProduct) => {
      if (!isBrandUploadedProduct) return;

      try {
        setLoading(true);
        const url = `/api/product_design/v1/consumer_products/${id}`;
        await fetcher(url, { method: 'delete' });
        enqueueSnackbar('SKU deleted', { variant: 'success' });
        await products.refetch({ soft: true });
      } catch (error) {
        enqueueSnackbar(`Failed to delete SKU: ${error}`, {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [products]
  );

  useLoading(loading);

  const handleCancel = () => {
    setEditOpen(false);
    setErrors({ product_name: '', sku_id: '', upc_number: '' });
  };

  const handleEditSubmit = async (event) => {
    if (!editProductInformation.isBrandUploadedProduct) return;
    try {
      setLoading(true);
      event.preventDefault();
      const formData = new FormData(event.target);
      const payload = buildPayloadFromForm(formData);

      const productUrl = `/api/product_design/v1/consumer_products/${editProductInformation.id}`;

      await fetcher(productUrl, {
        method: 'put',
        body: payload,
      });

      setEditOpen(false);
      clearEditProductInformation();
      products.refetch();
      enqueueSnackbar('SKU successfully updated', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(`Failed to update product: ${error}`, {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const context = useMemo(
    () => ({
      openAddProductDialog: () =>
        shouldOpenPaywall
          ? openPaywall()
          : history.push(PATHS.retailerBrandProductCreate),
      products: convertSnakeToCamelCase(products.data),
      loading: products.loading,
      error: products.error,
      tableCounts,
      deleteProduct,
      setEditOpen,
      setEditProductInformation,
      shouldOpenPaywall,
      bulk_upload_included,
    }),
    [
      products,
      tableCounts,
      deleteProduct,
      shouldOpenPaywall,
      bulk_upload_included,
      openPaywall,
      history,
    ]
  );

  return (
    <YourProductsContext.Provider value={context}>
      <Dialog open={editOpen} onClose={() => setEditOpen(false)}>
        <form onSubmit={(event) => handleEditSubmit(event)}>
          <Text kind={TextKind.DisplayLGMedium} element={TextElement.H2}>
            Edit "{editProductInformation.name}"
          </Text>
          <div className={styles['input-wrapper']}>
            <InputV2
              name="product_name"
              type="text"
              defaultValue={editProductInformation.name}
              label="Product Name:"
              hasError={Boolean(errors.product_name)}
              errorMessage={errors['product_name']}
              handleInputChange={(event) =>
                validateProductName(event.target.value)
              }
              endIconName={errors.product_name ? 'WarningCircle' : null}
            />
          </div>
          <div className={styles['input-wrapper']}>
            <InputV2
              name="sku_id"
              type="text"
              defaultValue={editProductInformation.skuId ?? ''}
              label="SKU ID:"
              hasError={Boolean(errors.sku_id)}
              errorMessage={errors['sku_id']}
              handleInputChange={(event) => validateSku(event.target.value)}
              endIconName={errors.sku_id ? 'WarningCircle' : null}
            />
          </div>
          <div className={styles['input-wrapper']}>
            <InputV2
              name="upc_number"
              type="text"
              defaultValue={editProductInformation.upcNumber ?? ''}
              label="UPC Number:"
              hasError={Boolean(errors.upc_number)}
              errorMessage={errors['upc_number']}
              handleInputChange={(event) =>
                validateUpcNumber(event.target.value)
              }
              endIconName={errors.upc_number ? 'WarningCircle' : ''}
            />
          </div>

          <div className={styles['buttons-wrapper']}>
            <Button
              kind={ButtonKind.Secondary}
              onClick={() => handleCancel()}
              type="button"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              kind={ButtonKind.Primary}
              disabled={hasErrors}
            >
              Submit
            </Button>
          </div>
        </form>
      </Dialog>
      {children}
    </YourProductsContext.Provider>
  );
}

export default YourProductsProvider;
