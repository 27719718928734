import propTypes from 'prop-types';
import styles from './pill.module.scss';
import { PillColor } from './constants';

function Pill(props) {
  let classNames = styles.root;
  if (props.color && props.color !== PillColor.Default) {
    classNames += ` ${styles[props.color]}`;
  }
  if (props.onClick) {
    classNames += ` ${styles.clickable}`;
  }
  if (props.dataActive) {
    classNames += ` ${styles.active}`;
  }
  if (props.isRound) {
    classNames += ` ${styles.round}`;
  }
  return (
    <div
      className={classNames}
      data-cy={props.dataCy}
      onClick={props.onClick}
      data-active={props.dataActive}
      tabIndex={props.onClick ? 0 : -1}
      onKeyDown={(e) => {
        if (e.key === 'Enter' && props.onClick) {
          props.onClick();
        }
      }}
    >
      {props.children}
    </div>
  );
}

Pill.propTypes = {
  children: propTypes.oneOfType([
    propTypes.string,
    propTypes.number,
    propTypes.node,
  ]).isRequired,
  color: propTypes.oneOf(Object.values(PillColor)),
  isRound: propTypes.bool,
  dataCy: propTypes.string,
  dataActive: propTypes.bool,
  onClick: propTypes.func,
};

export default Pill;
