import {
  Link,
  LinkKind,
  LinkSize,
  LinkVariant,
  Text,
  TextElement,
  TextKind,
} from 'design-system/components';
import styles from './portal-selection.module.scss';
import novixamazon from './novixamazon.svg';
import { Color } from 'design-system/data';

const amazonTyeform = 'https://nt972w7xpzh.typeform.com/novixamazoncpf';
const amazonMicrosite = 'https://www.noviconnect.com/amazon-cpf';

const RecommendedSection = () => {
  return (
    <div className={styles.cards}>
      <div className={styles.section}>
        <div className={styles['recommended-card']}>
          <div className={styles.images}>
            <img src={novixamazon} alt="novi x amazon" />
          </div>
          <div className={styles.copy}>
            <Text kind={TextKind.TextMDMedium} element={TextElement.P}>
              Certify your ASINs for Amazon Climate Pledge Friendly in 6 weeks
              or less
            </Text>
            <Text
              kind={TextKind.TextSM}
              element={TextElement.P}
              color={Color.Neutral700}
            >
              Amazon and Novi have partnered with 50+ independent certification
              bodies, including governmental agencies and non-profits, to help
              conscious consumers identify and purchase Amazon products that
              share their values. Climate Pledge Friendly products on Amazon see
              a 12% sales lift on average in the first year of joining the
              program.
            </Text>
          </div>
          <div className={styles.ctas}>
            <Link
              kind={LinkKind.External}
              variant={LinkVariant.Secondary}
              href={amazonMicrosite}
              size={LinkSize.Medium}
            >
              Learn more
            </Link>
            <Link
              kind={LinkKind.External}
              variant={LinkVariant.Primary}
              href={amazonTyeform}
              size={LinkSize.Medium}
            >
              Get certified
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecommendedSection;
