import { useMemo } from 'react';
import {
  SecondaryNavDataType,
  SecondaryNavUserInfo,
  SecondaryNavSection,
} from 'design-system/components';
import { PATHS } from 'constants/index';
import IconMenuContent from '../../components/IconMenuContent';
interface UseSecondaryNavWorkbenchProps {
  clickWrapper: (params: { action: string }) => void;
  userInfo: SecondaryNavUserInfo;
  inviteModalData: SecondaryNavSection;
}

export const useSecondaryNavWorkbench = ({
  clickWrapper,
  userInfo,
  inviteModalData,
}: UseSecondaryNavWorkbenchProps): SecondaryNavDataType => {
  return useMemo(
    () => ({
      main: {
        title: 'Novi Tools',
        items: [
          {
            id: 'ingredient-finder',
            content: <IconMenuContent text="Material finder" icon="TestTube" />,
            href: PATHS.product_marketplace,
            onClick: () =>
              clickWrapper({ action: 'click_menu_ingredient_finder' }),
          },
          {
            id: 'ingredient-screener',
            content: (
              <IconMenuContent text="Ingredient screener" icon="Microscope" />
            ),
            href: PATHS.ingredient_search,
            onClick: () =>
              clickWrapper({ action: 'click_menu_ingredient_screener' }),
          },
          {
            id: 'workbench',
            content: (
              <IconMenuContent
                text="Formulation verification"
                icon="Binoculars"
              />
            ),
            href: PATHS.projectDashboard,
            onClick: () => clickWrapper({ action: 'click_menu_workbench' }),
          },
        ],
      },
      footer: {
        sections: [inviteModalData],
        userInfo,
      },
    }),
    [clickWrapper, userInfo, inviteModalData]
  );
};
