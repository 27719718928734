import { useEffect } from 'react';
import styled from 'styled-components';
import { Redirect, useLocation } from 'react-router-dom';

import { Color, Shadow } from 'design-system/data';
import { useApp } from 'context/AppContext';
import { PATHS, ROLES } from 'constants/index';
import {
  AnalyticsEventProvider,
  useAnalyticsEvent,
} from 'services/analytics/AnalyticsContext';
import MainDashboardCMs from './MainDashboardCMs';
import { linkToAnonUserByUserInfo } from 'services/analytics/AnalyticsVendor';

export default function Home() {
  const { user, auth0 } = useApp();
  const { analyticsEvent } = useAnalyticsEvent();
  const loc = useLocation();
  linkToAnonUserByUserInfo(user);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const campaign = urlSearchParams.get('utm_campaign');
    const source = urlSearchParams.get('utm_source');
    const medium = urlSearchParams.get('utm_medium');

    if (campaign || source || medium) {
      analyticsEvent({
        category: 'signup',
        action: `land_on_homepage`,
        label: 'homepage',
      });
    }
  }, [analyticsEvent]);

  // if there is a pending redirect leftover from when the user tried to access
  // the site before they were authenticated, then send the user to that
  // address unless it's the current page
  if (
    auth0.hasRedirect() &&
    auth0.getRedirect() !== loc.pathname + loc.search + loc.hash
  ) {
    return <Redirect to={auth0.consumeRedirect()} />;
  }

  if (user?.hasRole(ROLES.formulator)) {
    return (
      <Redirect to={`${PATHS.product_marketplace}?category_key=ingredient`} />
    );
  }

  // otherwise fallback to the default home routes
  if (user?.hasRole(ROLES.brand)) {
    return <Redirect to={PATHS.portalSelection} />;
  }

  if (user?.hasRole(ROLES.contractManufacturer)) {
    return (
      <AnalyticsEventProvider label="home" category="main_page">
        <MainDashboardCMs />
      </AnalyticsEventProvider>
    );
  }

  if (user?.hasRole(ROLES.supplier)) {
    return <Redirect to={PATHS.supplierListings} />;
  }

  if (user?.hasRole(ROLES.retailer)) {
    return <Redirect to={PATHS.retailerDashboard} />;
  }

  return (
    <Notice>
      <p>
        You have not been assigned a role, something has gone wrong. Please
        contact{' '}
        <a href="mailto:support@noviconnect.com">support@noviconnect.com</a> for
        assistance
      </p>
    </Notice>
  );
}

const Notice = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  color: ${Color.Black.value};
  font-family: var(--sans-serif);

  p {
    padding: 2em;
    border-radius: 6px;
    background-color: white;
    border-color: 1px solid var(--brand-color);
    margin: 2em auto auto auto;
    box-shadow: ${Shadow.ElevationMedium};
    max-width: 500px;
    text-align: center;
  }
`;
